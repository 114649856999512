import React, { useEffect, useState } from "react";
import { useSearch } from "source/hooks/search/useSearch";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useSearchResults } from "source/hooks/search/useSearchResults";
import { useSelector } from "react-redux";
import { getReportFilter } from "source/redux/advanced";

// TODO: fix this button
export const SearchAgain = () => {
  const reportFilter = useSelector(getReportFilter);
  const { handleSearch } = useSearch();
  const { router } = useGetRouter();
  const { loading } = useSearchResults();

  const [newState, setNewState] = useState({ ...reportFilter });
  const [oldState, setOldState] = useState({ ...reportFilter });

  const jsonNew = JSON.stringify(newState);
  const jsonOld = JSON.stringify(oldState);

  useEffect(() => {
    setNewState({ ...reportFilter });
  }, [JSON.stringify(reportFilter)]);

  useEffect(() => {
    setOldState({ ...reportFilter });
  }, [router.query.query]);

  if (!router.query?.query || loading || jsonNew === jsonOld) return null;

  return (
    <div className="flex w-fit items-center whitespace-nowrap text-sm text-secondary">
      <span className="pl-1 text-quaternary">Filters changed</span>
      <span
        className="m-2 cursor-pointer bg-lightPurple/10 px-3 py-2.5 text-bluePurple hover:bg-lightPurple/20 active:bg-lightPurple/30"
        onClick={() => {
          setOldState({ ...reportFilter });
          handleSearch();
        }}
      >
        Update search
      </span>
    </div>
  );
};
