import React from "react";

type Props = {
  commands: string[];
  prefix?: string;
  styleOverride?: React.CSSProperties;
  children?: React.ReactNode;
};

// Renders the command styling to be used for tooltip hotkeys
export const CommandText = ({
  prefix,
  commands,
  styleOverride,
  children,
}: Props) => (
  <span
    className="flex cursor-pointer flex-wrap items-center justify-center"
    style={{ ...styleOverride }}
  >
    {prefix && (
      <div style={{ paddingRight: "8px", fontSize: 12 }}>{prefix}</div>
    )}
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {children ? (
        <span className="mx-0.5 flex h-6 w-7 items-center justify-center bg-hoverIcon/30 px-1 py-0.5 text-secondary">
          {children}
        </span>
      ) : null}
      {commands.map((command, i) => (
        <span
          key={i}
          className="m-0.5 flex h-6 items-center justify-center bg-hoverIcon/30 px-1 py-1 text-sm text-background"
        >
          {command}
        </span>
      ))}
    </div>
  </span>
);
