import React from "react";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { IDocumentMime } from "source/Types";
import { Report } from "../../matrix/types/reports.types";
import { classNames } from "core";
import { transformIngestFiles } from "source/utils/matrix/fastBuild";
import { getAllFileEntries } from "source/utils/common/files";
import { getFastBuildAllowedMimesSet } from "source/utils/documents";

export type UploadFilesFunction = {
  (files: File[], report: Report): Promise<void>;
  (files: File[]): Promise<void>;
};

interface Props {
  children?: React.ReactNode;
  style?: string;
  setIsDragging?: (isDragging: boolean) => void;
  handleOnDrop?: (e) => void;
  uploadFiles: UploadFilesFunction;
  supportedMimes?: IDocumentMime[];
  validate?: boolean;
  disabled?: boolean;
}

/**
 * @deprecated Use react-dropzone instead
 */
export const FileDndProvider = ({
  children,
  style,
  setIsDragging,
  handleOnDrop,
  uploadFiles,
  supportedMimes,
  validate,
  disabled,
}: Props) => {
  const repo = useCurrentRepo(true);
  const onDrop = async (e) => {
    if (
      !(
        e.dataTransfer?.items?.length &&
        e.dataTransfer?.items[0].webkitGetAsEntry()
      )
    )
      return;
    e.preventDefault();
    e.stopPropagation();
    if (handleOnDrop) handleOnDrop(e);
    if (setIsDragging) setIsDragging(false);

    // need to put this here so it is after the stopPropogation call
    if (disabled) return;

    const filesFromDataTransferItems: File[] = (await getAllFileEntries(
      e.dataTransfer.items
    )) as File[];

    const transformedFiles = transformIngestFiles(filesFromDataTransferItems);

    const files = supportedMimes
      ? transformedFiles.filter((file) =>
          getFastBuildAllowedMimesSet().has(file.type as IDocumentMime)
        )
      : transformedFiles;

    if (!files.length) return;
    await uploadFiles(files);
  };

  const onDragOver = (e) => {
    const dt = e?.dataTransfer;
    if (
      dt &&
      dt.types &&
      (dt.types.indexOf
        ? dt.types.indexOf("Files") != -1
        : dt.types.contains("Files"))
    ) {
      if (setIsDragging) setIsDragging(true);
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const onDragLeave = (e) => {
    if (setIsDragging) setIsDragging(false);
  };
  const onDragEnter = (e) => {
    const dt = e?.dataTransfer;
    if (
      dt &&
      dt.types &&
      (dt.types.indexOf
        ? dt.types.indexOf("Files") != -1
        : dt.types.contains("Files"))
    ) {
      if (setIsDragging) setIsDragging(true);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  if (!repo && !validate) return <>{children}</>;

  return (
    <div
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragEnter={onDragEnter}
      className={classNames(
        style ? style : "",
        disabled ? "pointer-events-none" : ""
      )}
    >
      {children}
    </div>
  );
};
