import React from "react";
import { areEqual } from "source/utils/helpers";
import { classNames } from "core";

interface Props {
  children?: any;
  fullSize?: boolean;
  style?: any;
  containerClassName?: string;
  innerContainerClassName?: string;
}

export const TriadBack = React.memo(
  ({
    children,
    style,
    fullSize,
    containerClassName,
    innerContainerClassName,
  }: Props) => {
    return (
      <div
        style={{
          ...(fullSize ? { height: "100%" } : { padding: "0px 0px" }),
        }}
        className={classNames(
          "flex flex-row justify-start",
          containerClassName
        )}
      >
        <div
          className={classNames(
            "fixed bottom-0 left-0 right-0 top-0 -z-50 h-[100vh] w-full bg-white",
            innerContainerClassName
          )}
        />
        <div className="sticky top-0 flex w-full flex-1 flex-col" style={style}>
          {children}
        </div>
      </div>
    );
  },
  areEqual
);
