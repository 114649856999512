import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getSynonymsPopoverVisible,
  setSynonymsPopoverVisible,
  getDocModalVisible,
} from "source/redux/ui";
import { useSelector } from "react-redux";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { getActiveSynonyms } from "source/redux/search";
import { Popover } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type TextChipProps = {
  children: React.ReactNode;
  location: Location;
};

const TextChip = ({ children }: TextChipProps) => (
  <div className="flex">
    <div className="flex select-none flex-row items-center bg-gray05 px-1.5 py-1 text-lightBlack">
      <p>{children}</p>
    </div>
  </div>
);

type Location = "docModal" | "search";

type Props = {
  location: Location;
};

export const SynonymsApplied = ({ location }: Props) => {
  const docModalVisible = useSelector(getDocModalVisible);
  const visible = useSelector(getSynonymsPopoverVisible);
  const activeSynonyms = useSelector(getActiveSynonyms);
  const [synonymSelected, setSynonymSelected] = useState(0);
  const dispatch = useDispatch();
  const chipsRefs = useRef<(HTMLDivElement | null)[]>([]);

  const openSynonymsModal = (i) => {
    setSynonymSelected(i);
    dispatch(setSynonymsPopoverVisible(true));
  };

  const closeSynonymsModal = () => {
    dispatch(setSynonymsPopoverVisible(false));
  };

  if (!activeSynonyms?.synonyms_applied?.length) return null;

  const appliedSynonyms = activeSynonyms?.synonyms_applied?.length ? (
    <div className="flex flex-row items-center gap-2">
      {activeSynonyms.synonyms_applied
        .filter((synonym) => synonym.synonyms.length)
        .map((synonym, i) => (
          <div
            className={`select-none ${
              docModalVisible
                ? "mb-2 mt-1 cursor-pointer text-hebbiaBlue hover:text-hebbiaBlueDark"
                : "cursor-pointer bg-blue1 text-hebbiaBlue hover:bg-blue2"
            }`}
            onClick={() => openSynonymsModal(i)}
            ref={(ref) => {
              chipsRefs.current[i] = ref;
            }}
            key={i}
          >
            <div className={`${location === "search" ? "px-2" : ""}`}>
              <span className="mr-2 align-middle text-lg">
                <SwapHorizIcon fontSize="inherit" />
              </span>
              {synonym.phrase}
              <ArrowDropDownIcon fontSize="small" />
            </div>
          </div>
        ))}
    </div>
  ) : null;

  return (
    <>
      {appliedSynonyms && appliedSynonyms}

      <Popover
        open={visible && (location === "docModal" || !docModalVisible)}
        BackdropProps={{ style: { opacity: 0.75 } }}
        onClose={() => closeSynonymsModal()}
        sx={{
          ".MuiPopover-paper": {
            height: "154",
            width: "243",
          },
        }}
        anchorEl={chipsRefs.current[synonymSelected]}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: location === "docModal" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: location === "docModal" ? "left" : "right",
        }}
      >
        <div className="min-w-sm flex max-w-sm flex-col items-start gap-y-1 py-3 pl-4 pr-6">
          <div className="flex w-full flex-row items-center justify-between pb-3 text-lg">
            <span className="text-base font-medium">Synonyms applied</span>
          </div>
          <div className="flex flex-wrap gap-2 text-sm">
            {activeSynonyms?.synonyms_applied?.[synonymSelected]?.synonyms.map(
              (synonymWord, i) => (
                <TextChip key={i} location={location}>
                  {synonymWord}
                </TextChip>
              )
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};
