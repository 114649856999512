import React from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import $ from "jquery";
import { MAIN_SEARCH_INPUT_ID } from "source/constants";
import { Tooltip } from "source/components/shared/Tooltip";
import { useState } from "react";
import { getSidebarWidth, setOverrideEscHotkey } from "source/redux/ui";
import { useDispatch } from "react-redux";
import { EntitySearchResult } from "source/Types";
import { useSelector } from "react-redux";

export type SidebarToggleHeaderProps = {
  /**
   * Header text.
   */
  title: string;
  tooltipText?: string;
  tooltipActiveText?: string;
  tooltipActive?: boolean;
  tooltipOnClick?: (isActive: boolean) => void;
  notifyMessage?: string;

  styleOverride?: any;
};

/**
 * Renders the header row of the collapsible sidebar section.
 */
export const SidebarToggleHeader = ({
  title,
  tooltipText,
  tooltipActiveText,
  tooltipActive,
  tooltipOnClick,
  notifyMessage,
  styleOverride,
}: SidebarToggleHeaderProps) => {
  const sidebarWidth = useSelector(getSidebarWidth);
  return (
    <>
      <div
        className="float-left truncate p-1 font-medium text-secondary"
        style={{
          ...(styleOverride || {}),
          fontSize: 11,
          maxWidth: sidebarWidth - 60,
        }}
      >
        {tooltipText && tooltipActiveText && tooltipOnClick ? (
          <Tooltip title={tooltipActive ? tooltipActiveText : tooltipText}>
            <span
              className="cursor-pointer rounded-md p-1 text-xs text-secondary hover:bg-hoverRow active:bg-hoverRowActive"
              onClick={(e) => {
                e.stopPropagation();
                tooltipOnClick(tooltipActive ?? false);
              }}
            >
              {title}
            </span>
          </Tooltip>
        ) : (
          title
        )}
        {notifyMessage && <span>{` (${notifyMessage})`}</span>}
      </div>
    </>
  );
};

/**
 * Used to manage search over a collection of items in the sidebar.
 */
export type SectionSearchHookReturnValue = {
  state: SectionSearchState;
  searchCallbacks: SectionSearchFns;
};

export type SectionSearchState = {
  /**
   * The search term applied to the section.
   */
  value: string;

  /**
   * Object containing repos and documents.
   */
  result: EntitySearchResult | Record<string, never>;

  /**
   * Whether we're waiting on the search to return.
   */
  isLoading: boolean;
};

export type SectionSearchFns = {
  /** Fired when the search input changes with the new string as parameter. */
  onValueChange?: (newVal: string) => void;

  /** Fired when the search is cleared. */
  onClear?: () => void;

  /** Fired when user presses enter with the input string as parameter.  */
  onSearch?: (searchTerm: string) => void;
};

export type SearchToolInsideRowProps = SectionSearchFns & {
  /**
   * Description message to display on hover of search Icon and as placeholder in the input field.
   */
  title?: string;

  /**
   * Fired when the search Icon is clicked.
   */
  onOpen?: () => void;

  /** ID of HTML element to tab to from input field, defaults to main search input. */
  tabId?: string;

  /** optionally set initial input value */
  initialInputValue?: string;

  visible?: boolean;
};

/**
 * Interactive search UI component rendered inside a sidebar section header row.
 */
export const SearchToolInsideRow = ({
  title,
  onValueChange,
  onClear: onClose,
  onOpen,
  onSearch,
  tabId = MAIN_SEARCH_INPUT_ID,
  visible = true,
  initialInputValue,
}: SearchToolInsideRowProps) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialInputValue ?? "");
  const [inputVisible, setInputVisible] = useState(!!initialInputValue);

  const handleClear = () => {
    setInputVisible(false);
    setValue("");

    if (onClose) onClose();
  };

  const handleKeyDown = (e) => {
    // clear if user hits ecsape
    if (e.key === "Escape") {
      handleClear();
    } else if (e.key === "Enter") {
      onSearch && onSearch(value);
    }
    // on tab go to main search
    else if ((e.which || e.keyCode) === 9) {
      e.preventDefault();
      tabId && $(`#tabId`).trigger("focus");
    }
  };

  const handleSearchIconClicked = () => {
    if (!inputVisible) {
      setInputVisible(true);
      onOpen && onOpen();
      // hacky: override Searchbar's Esc hotkey
      dispatch(setOverrideEscHotkey(true));
    } else {
      handleClear();
    }
  };

  return visible ? (
    <div className="flex flex-row" onClick={handleSearchIconClicked}>
      {!inputVisible && (
        <Tooltip title={title || ""}>
          <SearchSharpIcon
            className="ml-0.5 cursor-pointer p-0.5 hover:bg-shadow/30"
            fontSize="small"
          />
        </Tooltip>
      )}
      {!!inputVisible && (
        <input
          className="max-w-[100px] border-b border-solid border-secondary bg-backgroundLight pl-1 text-xs placeholder-light outline-none"
          type="text"
          onChange={(e) => {
            setValue(e.target.value);
            if (onValueChange) onValueChange(e.target.value);
          }}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => handleKeyDown(e)}
          value={value}
          placeholder={title || ""}
          autoFocus={true}
        />
      )}
      {(inputVisible || !!value.length) && (
        <Tooltip title={"Hide"}>
          <CloseSharpIcon
            className="ml-1 cursor-pointer hover:bg-shadow/30"
            fontSize="small"
          />
        </Tooltip>
      )}
    </div>
  ) : null;
};
