import React from "react";
import { useDispatch } from "react-redux";
import { useAutoToggleSidebar } from "source/hooks/useAutoToggleSidebar";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { setUserToggledSidebar, setSidebarVisible } from "source/redux/sidebar";
import { ToggleSidebarClosedButton } from "../ToggleSidebarClosedButton";
import { AdvancedFiltersCard } from "./components/AdvancedFilters";

type TopLeftProps = {
  showCollapseIcon: boolean;
};

export const TopLeft = ({ showCollapseIcon }: TopLeftProps) => {
  const dispatch = useDispatch();
  const currentRepo = useCurrentRepo();
  // Handle auto-closing of the sidebar
  useAutoToggleSidebar(false);

  return (
    <div className="flex items-start bg-backgroundLight">
      {!currentRepo ? (
        <div className="absolute right-0">
          <ToggleSidebarClosedButton
            visible={showCollapseIcon}
            handleClick={() => {
              dispatch(setUserToggledSidebar(true));
              dispatch(setSidebarVisible(false));
            }}
          />
        </div>
      ) : null}
      <AdvancedFiltersCard />
    </div>
  );
};
