import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "source/components/shared/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import api from "source/api";
import { useSearchParams } from "source/hooks/search/useSearchParams";
import { notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";
import {
  getDownloadExactMatchSearchInfo,
  setExactMatchSearchDownload,
} from "source/redux/downloadExactMatchSearch";

export const DownloadExactMatchesCard = () => {
  const dispatch = useDispatch();
  const { activeDownload } = useSelector(getDownloadExactMatchSearchInfo);
  // Used to prevent user from immediately clicking button again before request to
  // donwload matches hits the backend.
  const [requestInProgress, setRequestInProgress] = useState(false);
  const params = useSearchParams();

  const handleGenerateSearchCSV = async () => {
    // If already a download in progress, dont start another
    if (activeDownload) return;
    setRequestInProgress(true);

    const { data, status } = await api.search.downloadExactMatches(params);
    if (status !== 200 || !data.download || data.download.status === "FAILED") {
      notification.error({
        message: "Error downloading all search results",
        placement: "bottomRight",
        duration: 5,
        icon: <ErrorIcon />,
        className: "shadow-[0_7px_24px_-7px_rgb(var(--shadow))]",
        key: uuidv4(),
        closeIcon: <></>,
      });
      setRequestInProgress(false);
      return;
    }

    dispatch(setExactMatchSearchDownload(data.download));
    setRequestInProgress(false);
  };

  return (
    <Tooltip title="Download exact matches into CSV">
      <div
        className={`mr-4 flex items-center font-normal text-secondary ${
          !activeDownload &&
          !requestInProgress &&
          "cursor-pointer text-bluePurple"
        }`}
        onClick={handleGenerateSearchCSV}
      >
        <DownloadIcon className="mr-1" fontSize="small" />
        Download All Results
      </div>
    </Tooltip>
  );
};
