import React, { useEffect, useRef, useState } from "react";
import {
  useChat,
  generateChatWsURL,
  tabTypeToChatType,
} from "source/hooks/useChat";
import { getCurrentPage } from "source/utils/helpers";
import { useGetRouter } from "source/hooks/useGetRouter";
import $ from "jquery";
import { GenerateCard } from "./GenerateCard";
import { useSelector } from "react-redux";
import {
  getChatLoading,
  getChatPerformance,
  getChatPreloadedMessage,
  setChatPreloadedMessage,
} from "source/redux/chat";
import { useDispatch } from "react-redux";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useIsOnScreen } from "source/hooks/responsive/useIsOnScreen";
import {
  PosthogAction,
  PosthogObject,
  useLogPosthog,
} from "source/hooks/tracking/usePosthogTracking";
import { CommonChatInputBox } from "source/components/shared/SearchInput/components/CommonChatInputBox";

export const ChatBottomBar = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [val, setVal] = useState("");
  const { router } = useGetRouter();
  const page = getCurrentPage(router.pathname);
  const loading = useSelector(getChatLoading);
  const preloadedMessage = useSelector(getChatPreloadedMessage);
  const { handleSearch, closeAndReconnect } = useChat(page);
  const messageElem = $("#chat-messages-end").get(0);
  const isVisible = useIsOnScreen(messageElem);
  const { logPosthog } = useLogPosthog();
  const performanceType = useSelector(getChatPerformance);

  const handleEnter = async () => {
    if (loading) return;
    logPosthog(page as PosthogObject, PosthogAction.MESSAGE_SENT);
    handleSearch(val);
    setVal("");
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (preloadedMessage) {
      handleSearch(preloadedMessage);
      setVal("");
      inputRef.current?.focus();
      // Reset preloaded message
      dispatch(setChatPreloadedMessage(null));
    }
  }, [preloadedMessage, handleSearch, dispatch]);

  useEffect(() => {
    $("#chat-search-input")
      .on("blur", () => {
        $("#chat-outer-input").removeClass("border-b-2 border-b-[#0576FF]");
      })
      .on("focus", () => {
        $("#chat-outer-input").addClass("border-b-2 border-b-[#0576FF]");
      });
  }, []);

  const resetChat = () => {
    const wsURL = generateChatWsURL(tabTypeToChatType(page), performanceType);
    closeAndReconnect(wsURL);
  };

  return (
    <div className="sticky bottom-0 left-0 z-50 flex w-full flex-col">
      <div className="z-50 mx-auto mb-2 flex h-12 w-3/4 max-w-[48rem] flex-row items-center justify-center">
        {loading && <GenerateCard handleClose={resetChat} />}
        {!isVisible && <ScrollToBottomButton scrollRefElem={messageElem} />}
      </div>
      <div className="z-50 flex w-full flex-col items-center justify-center border-t border-light/50 bg-card">
        <div className="mx-auto mb-3 mt-6 w-3/4 max-w-[48rem] rounded-none border border-[#E6E6E6] bg-card text-primary">
          <CommonChatInputBox
            searchFn={handleEnter}
            placeholderText={"Send a message"}
            inputQuery={val}
            showSearchInput={true}
            onChange={(e) => setVal(e.target.value)}
            inputId="chat-search-input"
            multiline={true}
            inputRef={inputRef}
          />
        </div>
        <div className="mb-3 text-xs text-secondary">
          Secure enterprise chat with GPT-4o. No data saved.
        </div>
      </div>
    </div>
  );
};

type ScrollToBottomButtonProps = {
  scrollRefElem: HTMLElement | undefined;
};
const ScrollToBottomButton = ({ scrollRefElem }: ScrollToBottomButtonProps) => (
  <div
    className="absolute right-6 ml-auto cursor-pointer border border-light/50 bg-[#F9FAFC] p-1"
    onClick={() => scrollRefElem?.scrollIntoView({ behavior: "smooth" })}
  >
    <ArrowDownwardIcon />
  </div>
);
