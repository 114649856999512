import React, { ReactNode, useEffect, useState } from "react";
import { ToggleIcon } from "source/components/shared/ToggleIcon";
import {
  SearchToolInsideRow,
  SearchToolInsideRowProps,
  SidebarToggleHeader,
  SidebarToggleHeaderProps,
} from "./SidebarToggleUtils";
import { useDispatch } from "react-redux";
import { setToggleSectionState } from "source/redux/sidebar";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";

export type SidebarToggleProps = {
  /** Configure the section header text. */
  headerProps: SidebarToggleHeaderProps;

  /**
   * Configure the section header's search Icon and add state-changing search functionality.
   * If undefined, does not render the search tool.
   */
  searchToolProps?: SearchToolInsideRowProps;

  /**
   * Badge elements to show on the right side of the section header.
   */
  badges?: ReactNode;

  /**
   * Show <hr/> when expanded. Default true.
   */
  showBottomBarWhenExpanded?: boolean;

  /**
   * Whether or not the toggle section is initially open. Defaults false
   */
  initiallyOpen?: boolean;

  /**
   * Force open the toggle section when there are active rows, etc. Default false.
   */
  numHotRows?: number;

  /**
   * Pagination handler is called when the toggle section is closed and there are hot rows.
   * Pagination only applies to coldRows
   */
  paginationHandler?: (pagination: number) => void;

  /**
   * Element(s) to render inside the collapsible toggle area.
   */
  children: ReactNode;
};

/**
 * Renders a collapsible sidebar section as specified in `sectionType`.
 */
export const SidebarToggle = ({
  headerProps,
  searchToolProps,
  badges,
  showBottomBarWhenExpanded = true,
  children,
  initiallyOpen = false,
  numHotRows = 0,
  paginationHandler,
}: SidebarToggleProps) => {
  const dispatch = useDispatch();
  const currentRepo = useCurrentRepo();
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [isHover, setIsHover] = useState(false);

  // Force state update and re-render when redux openToggleSection is updated
  useEffect(() => {
    // the intra-repo experience needs more work, so for now,
    // don't auto-close any intra-repo sections
    if (paginationHandler && numHotRows) setIsOpen(true);
    // When operating in a repo search, respect initiallyOpen, otherwise, let
    // everything open as False unless we see hotRows
    if (numHotRows === 0 && currentRepo) setIsOpen(initiallyOpen);
    if (numHotRows === 0 && !currentRepo) setIsOpen(false);
  }, [numHotRows, initiallyOpen]);

  const toggleIsOpen = () => {
    if (currentRepo) setIsOpen(!isOpen);
    // a toggle sections with hot row can never hide the hot rows
    if (paginationHandler && numHotRows && isOpen) {
      paginationHandler(0); // show 0 cold rows
      return;
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="hover:bg-shadow/30">
        <div
          className="flex w-full cursor-pointer justify-between p-2"
          onClick={toggleIsOpen}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="font-large ml-1 flex w-full items-center uppercase">
            <div className="flex w-full">
              <ToggleIcon isOpen={isOpen} isHover={isHover} />
              <SidebarToggleHeader {...headerProps} />
              {!!searchToolProps && (
                <div
                  id="search-tool-parent"
                  className="ml-auto"
                  // don't toggle collapse on click of search tool
                  onClick={(e?: React.MouseEvent) => e?.stopPropagation()}
                >
                  <SearchToolInsideRow
                    onOpen={() => {
                      setIsOpen(true);
                      dispatch(
                        setToggleSectionState({
                          sectionTitle: headerProps.title,
                          isExpanded: true,
                        })
                      );
                    }}
                    {...searchToolProps}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="float-right flex"
            onClick={(e?: React.MouseEvent) => e?.stopPropagation()}
          >
            {badges}
          </div>
        </div>
      </div>
      {isOpen && children}
      {(!isOpen || showBottomBarWhenExpanded) && <hr></hr>}
    </>
  );
};
