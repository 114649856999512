import React from "react";
import { classNames } from "core";

type Props = {
  title: string;
  stickyHeader?: boolean;
};

export const GigabarHeader = ({ title, stickyHeader }: Props) => (
  <div
    className={classNames(
      stickyHeader ? "sticky top-0 z-30" : "",
      "border-bottom mt-[1px] flex justify-between bg-backgroundLight py-4.5 pl-2.5 pr-3"
    )}
  >
    <span className="text-base font-semibold text-black">{title}</span>
  </div>
);
