import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPinsDict } from "source/redux/pin";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "./ContextMenu";
import { DocumentType } from "source/Types";
import { SidebarRow } from "./SidebarRow";
import { usePins } from "source/hooks/usePins";
import { setCurrentSelectionId } from "source/redux/ui";
import { MenuIcon } from "source/components/shared/Icons/MenuIcon";
import LinkSharpIcon from "@mui/icons-material/LinkSharp";
import { useSelection } from "source/hooks/useSelection";

type DocPinsProps = {
  doc: DocumentType;
  depth?: number;
};

/**
 * Renders pin rows for the given doc.
 */
export const DocPins = ({ doc, depth }: DocPinsProps) => {
  const dispatch = useDispatch();
  const { currentPin, handleUnpin } = usePins();
  const { copySelectionLink } = useSelection(doc);
  const allPins = useSelector(getPinsDict);

  const docPins = Object.values(allPins).filter(
    (pin) => pin.doc_id === doc.id && !pin.query && !pin.is_link
  );

  if (!docPins.length) return null;
  return (
    <>
      {docPins.map((pin) => {
        <ContextMenu key={pin.id}>
          <ContextMenuTrigger>
            <SidebarRow
              key={pin.id}
              id={pin.id}
              title={pin?.highlight_context?.text ?? ""}
              icon={
                <PushPinIcon
                  className="ml-1 cursor-pointer pt-0.5"
                  fontSize="medium"
                  style={{
                    ...(pin.id === currentPin?.id && {
                      color: "#883FFF",
                      WebkitTextFillColor: "#883FFF",
                    }),
                  }}
                />
              }
              onClick={() => {
                dispatch(
                  setCurrentSelectionId(
                    pin.id === currentPin?.id ? undefined : pin.id
                  )
                );
              }}
              depth={(depth || 0) + 1}
              hover={<MenuIcon />}
            />
          </ContextMenuTrigger>
          <ContextMenuContent>
            <ContextMenuItem
              title="Copy Link"
              icon={<LinkSharpIcon />}
              onSelect={() => copySelectionLink(pin.id)}
            />
            <ContextMenuItem
              title="Delete"
              icon={<DeleteSharpIcon />}
              onSelect={(e) => {
                handleUnpin(pin, e);
              }}
            />
          </ContextMenuContent>
        </ContextMenu>;
      })}
    </>
  );
};
