import { useSelector } from "react-redux";
import { getCurrentOrg } from "source/redux/organization";
import { getUser } from "source/redux/user";
import { OrgRoleType } from "source/Types";

export const useOrgRole = (): OrgRoleType | undefined => {
  const user = useSelector(getUser);
  const currentOrg = useSelector(getCurrentOrg);

  // If user is hebbia admin, inherit admin
  if (user?.platform_role === "admin") return "admin";

  // If no user, no members, or no org, return undefined
  if (!currentOrg || !user || !currentOrg.members) return undefined;

  // Find member in org
  const member = currentOrg.members.find((member) => member.id === user.id);

  if (!member) return undefined;

  // Return Member Role
  return member.org_role;
};
