import React from "react";
import StopOutlinedIcon from "@mui/icons-material/StopOutlined";

type GenerateCardProps = {
  handleClose: () => void;
};

export const GenerateCard = ({ handleClose }: GenerateCardProps) => (
  <div
    className="flex cursor-pointer select-none items-center border border-light/50 bg-card p-2 text-secondary hover:bg-hoverRow active:bg-hoverRowActive"
    onClick={handleClose}
  >
    <StopOutlinedIcon />
    <span>Stop generating</span>
  </div>
);
