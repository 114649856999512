import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import api from "source/api";
import { useUploadSourceSuccess } from "source/hooks/useUploadSourceSuccess";
import { openPrettyAlert } from "source/utils/helpers";
import { insertActiveBuild } from "source/redux/activeBuilds";
import { upsertUploads } from "source/redux/upload";
import { DocumentType, RepoType } from "source/Types";

interface Props {
  repo?: RepoType;
  parentDoc?: DocumentType;
}

const UPLOAD_BATCH_SIZE = 10;

const handleFailed = (msg: string) => {
  openPrettyAlert({
    title: msg,
    text: "Please try again later. Contact support@hebbia.ai if the problem persists.",
    icon: "error",
    url: window.location.href,
  });
};

export const useUploadFiles = ({ repo, parentDoc }: Props) => {
  const dispatch = useDispatch();
  const { handleSuccess } = useUploadSourceSuccess();

  const uploadFiles = async (files: File[]) => {
    if (!repo) return;
    if (!files.length) handleFailed("No files uploaded.");

    const uploadKey = v4();
    dispatch(
      insertActiveBuild({
        action: "upload",
        uploadId: uploadKey,
        repoId: repo.id,
        status: "processing",
      })
    );

    const counts = { numDocs: files.length, numSucceeded: 0, numFailed: 0 };
    dispatch(upsertUploads([{ id: uploadKey, repoId: repo.id, ...counts }]));

    // upload documents to server in batch
    const failedFiles: File[] = [];
    const docs: DocumentType[] = [];
    for (let i = 0; i < files.length; i += UPLOAD_BATCH_SIZE) {
      const fileSlice: File[] = files.slice(
        i,
        Math.min(files.length, i + UPLOAD_BATCH_SIZE)
      );

      // TODO: put these upload routes in react query
      try {
        const { data } = await api.repos.uploadLocal(
          repo.id,
          fileSlice,
          parentDoc?.id
        );
        docs.push(...data.docs);
        counts.numSucceeded += fileSlice.length;
      } catch (error) {
        failedFiles.push(...fileSlice);
        counts.numFailed += fileSlice.length;
      }
      dispatch(upsertUploads([{ id: uploadKey, repoId: repo.id, ...counts }]));
    }

    if (docs) handleSuccess(docs, parentDoc);
    else handleFailed("Upload failed.");
    return;
  };

  return { uploadFiles };
};
