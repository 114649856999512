import React from "react";
import styled from "styled-components";
import { Switch } from "antd";
import { Colors } from "source/constants/colors";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 60px;
  overflow: auto;
  color: var(--text-primary);
`;

export const Input = styled.input`
  padding: 8px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  // border-radius: 7px;
  border: 0px solid var(--text-light);
  background: var(--input);
  margin-top: 8px;
  margin-bottom: 5px;
  ::placeholder {
    color: var(--text-secondary);
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  padding: 0;
`;

export const Description = styled.div`
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  padding: 0;
  color: var(--text-secondary);
`;

export const Button = styled.button`
  padding: 8px 12px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  // border-radius: 7px;
  background-color: ${Colors.darkBlue};
  color: white;
  border: none;
  cursor: pointer;
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid var(--background-hover);
  margin: 24px 0px;
`;

export const ToggleBlock = ({
  title,
  description,
  value,
  setValue,
  disabled,
  styleOverride,
}: {
  title: string;
  description: string;
  value: boolean;
  setValue: (value: boolean) => void;
  disabled?: boolean;
  styleOverride?: React.CSSProperties;
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 8,
      ...styleOverride,
    }}
  >
    <div>
      <Description style={{ color: "var(--text-primary)" }}>
        {title}
      </Description>
      <Description>{description}</Description>
    </div>
    <Switch
      disabled={disabled}
      checked={value}
      onChange={setValue}
      size="small"
    />
  </div>
);

export const UpdateBlock = ({ onReset }: { onReset: () => void }) => (
  <div
    style={{
      padding: 20,
      paddingLeft: 80,
      position: "absolute",
      bottom: 0,
      borderTop: "1px solid var(--background-hover)",
      background: "var(--card)",
      left: 200,
      right: 0,
    }}
  >
    <Button type="submit">Update</Button>
    <Button
      style={{
        background: "transparent",
        border: "1px solid var(--text-light)",
        color: "var(--text-secondary)",
        marginLeft: 8,
      }}
      onClick={onReset}
    >
      Cancel
    </Button>
  </div>
);
