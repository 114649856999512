import { useMutation, useQuery } from "@tanstack/react-query";
import { AlertType } from "source/Types";
import api from "..";
import { AlertUpdateType } from "./alerts";
import { queryClient } from "pages/_app";

const alertsKeys = {
  all: [{ scope: "alerts" }] as const,
};

const alertsFetcher = async () => api.alerts.getAlerts();

export const useQueryAlerts = (): AlertType[] => {
  const alertsQuery = useQuery({
    queryKey: alertsKeys.all,
    queryFn: alertsFetcher,
  });

  return alertsQuery.data?.alerts ?? [];
};

export const useCreateAlertMutation = () =>
  useMutation({
    mutationFn: (newAlert: AlertType) => api.alerts.addAlert(newAlert),
    onMutate: async (newAlert: AlertType) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      queryClient.cancelQueries({ queryKey: alertsKeys.all });
    },
    // Always refetch after error or success:
    onSettled: (resp, error, newAlert: AlertType) => {
      queryClient.invalidateQueries({ queryKey: alertsKeys.all });
    },
  });

type UpdateAlertPayload = {
  alertId: string;
  alertUpdate: AlertUpdateType;
};

export const useUpdateAlertMutation = () =>
  useMutation({
    mutationFn: ({ alertId, alertUpdate }: UpdateAlertPayload) =>
      api.alerts.updateAlert(alertId, alertUpdate),
    // When mutate is called:
    onMutate: async ({ alertId, alertUpdate }: UpdateAlertPayload) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to docs (so we don't fetch the newly deleted doc)
      await queryClient.cancelQueries({ queryKey: alertsKeys.all });

      // Snapshot the previous values
      const previousAlerts: AlertType[] | undefined = queryClient.getQueryData(
        alertsKeys.all
      );

      // Return a context object with the snapshotted value
      return { previousAlerts };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, data, context) => {
      if (context?.previousAlerts)
        queryClient.setQueryData(alertsKeys.all, context.previousAlerts);
    },
    // Always refetch after error or success:
    onSettled: (resp, error, variables) => {
      // Refetch everything related to alerts
      queryClient.invalidateQueries({ queryKey: alertsKeys.all });
    },
  });

export const useDeleteAlertMutation = () =>
  useMutation({
    mutationFn: (alertId: string) => api.alerts.deleteAlert(alertId),
    // When mutate is called:
    onMutate: async (alertId: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to docs (so we don't fetch the newly deleted doc)
      await queryClient.cancelQueries({ queryKey: alertsKeys.all });

      // Snapshot the previous values
      const previousAlerts: AlertType[] | undefined = queryClient.getQueryData(
        alertsKeys.all
      );

      // Return a context object with the snapshotted value
      return { previousAlerts };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, data, context) => {
      if (context?.previousAlerts)
        queryClient.setQueryData(alertsKeys.all, context.previousAlerts);
    },
    // Always refetch after error or success:
    onSettled: (resp, error, variables) => {
      // Refetch everything related to alerts
      queryClient.invalidateQueries({ queryKey: alertsKeys.all });
    },
  });
