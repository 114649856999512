import tinycolor from "tinycolor2";
import { Colors } from "source/constants/colors";
import { RepoType } from "source/Types";

export const getRepoColor = (repo?: Partial<RepoType>) => Colors.darkBlue;

export const getDarkerRepoColor = (repo?: Partial<RepoType>) => {
  const { h } = tinycolor(repo?.color || Colors.blue).toHsl();
  return `hsl(${h}, 77%, 65%)`;
};

export const getLighterRepoColor = (repo?: Partial<RepoType>) => {
  const { h } = tinycolor(repo?.color || Colors.blue).toHsl();
  return `hsl(${h}, 77%, 70%)`;
};
