import React from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { MaterialSymbolsIcon } from "core";

type Props = {
  handleClick: () => void;
};

export const CollapsedStatusBanner = ({ handleClick }: Props) => (
  <div className="pointer-events-auto flex h-[56px] w-[100px] cursor-default items-center justify-center gap-2 rounded-xl border border-gray-200 bg-[#E5E6FF] p-2 shadow-md">
    <span className="h-5">
      <MaterialSymbolsIcon
        filled
        size="xl"
        icon="info"
        variant="rounded"
        className="text-indigo-500"
      />
    </span>
    <span className="cursor-pointer place-self-center" onClick={handleClick}>
      <KeyboardDoubleArrowLeftIcon
        fontSize="medium"
        className="text-lightBlack hover:text-lightBlack/70"
      />
    </span>
  </div>
);
