import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserToggledSidebar, setSidebarVisible } from "source/redux/sidebar";

export const useAutoToggleSidebar = (monitorOpening: boolean) => {
  const dispatch = useDispatch();
  const userClosedSidebar = useSelector(getUserToggledSidebar);

  useEffect(() => {
    // Defining event listener function
    const displayWindowSize = () => {
      // Get width and height of the window
      const w = document.documentElement.clientWidth;
      // If the user manually closed sidebar, don't do anything below
      if (userClosedSidebar) return;

      if (w >= 768 && monitorOpening) {
        dispatch(setSidebarVisible(true));
      } else if (w < 768 && !monitorOpening) {
        dispatch(setSidebarVisible(false));
      }
    };
    window.addEventListener("resize", displayWindowSize);

    return () => {
      window.removeEventListener("resize", displayWindowSize);
    };
  }, [userClosedSidebar, monitorOpening, dispatch]);
};
