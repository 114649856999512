import React, { useMemo } from "react";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { DocumentSection } from "../components/DocumentSection";
import { useSelector } from "react-redux";
import { SectionSearchHookReturnValue } from "./SidebarToggleUtils";
import { SidebarToggle } from "./SidebarToggle";
import { useDocumentSectionSearch } from "source/hooks/search/useSectionSearch";
import { getTargetDocs } from "source/redux/advanced";
import { EntityTitleSearchSection } from "./EntityTitleSearchSection";

/**
 * Renders the document section inside a collapsible sidebar toggle area.
 */
export const DocumentToggle = () => {
  const repo = useCurrentRepo(true);

  const documentSearch: SectionSearchHookReturnValue | null =
    useDocumentSectionSearch({ repo });
  const initialSearchValue = useMemo(() => documentSearch?.state.value, []);
  const numDocTargets = useSelector(getTargetDocs).length;

  if (!repo || !documentSearch) return <></>;

  return (
    <SidebarToggle
      headerProps={{
        title: "Documents",
        notifyMessage: numDocTargets > 0 ? `${numDocTargets}` : undefined,
        styleOverride: undefined,
      }}
      searchToolProps={{
        title: "Find documents",
        initialInputValue: initialSearchValue,
        visible: true,
        ...documentSearch.searchCallbacks,
      }}
      showBottomBarWhenExpanded={false}
      initiallyOpen={true}
      numHotRows={numDocTargets}
    >
      {documentSearch.state.value.length > 0 ? (
        <EntityTitleSearchSection
          repos={[]}
          docs={documentSearch.state.result.docs}
          companyRepos={[]}
          pitchbookRepos={[]}
          publicRepos={[]}
          showHeaders={false}
          isLoading={documentSearch.state.isLoading}
        />
      ) : (
        <DocumentSection repo={repo} />
      )}
    </SidebarToggle>
  );
};
