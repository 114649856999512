import React from "react";
import { Tooltip } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  rowVisible: boolean;
  handleClick: () => void;
};

export const ArrowIcon = React.memo(({ rowVisible, handleClick }: Props) => (
  <Tooltip
    title={rowVisible ? "Hide metadata" : "View metadata"}
    enterDelay={1000}
  >
    <div
      onClick={handleClick}
      className="absolute right-0 cursor-pointer border-l border-secondary/30 pl-1"
    >
      {rowVisible ? (
        <ExpandLessIcon className="text-secondary" />
      ) : (
        <ExpandMoreIcon className="text-secondary" />
      )}
    </div>
  </Tooltip>
));
