import { Dispatch } from "react";
import { DocumentType } from "source/Types";
import api from "source/api";
import {
  setLoading,
  setFinished,
  setSuccess,
  setFailure,
} from "source/redux/tables";
import { WorkBook, WorkSheet } from "xlsx";

export const loadXLSX = async () => {
  const XLSX = await import("xlsx");
  return XLSX;
};

// Download document table(s)
export const exportTable = async (
  doc: DocumentType,
  doc_urls: string[],
  img_urls: string[] | undefined,
  dispatch: Dispatch<any>
) => {
  dispatch(setSuccess());
  dispatch(setLoading());
  try {
    const { data } = await api.docs.downloadTables(doc.id, doc_urls, img_urls);
    const blob = new Blob([data], { type: ".xlsx" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fname = removeFileType(doc.title);
    link.download = `${fname}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    dispatch(setFailure());
  } finally {
    dispatch(setFinished());
  }
};

const removeFileType = (fname: string) => {
  return fname.substring(0, fname.lastIndexOf(".")) || fname;
};

export const getCellCountForWorkbook = (
  workbook: WorkBook,
  XLSX: typeof import("xlsx")
) => {
  let totalCellCount = 0;
  workbook.SheetNames.forEach((sheetName) => {
    const worksheet = workbook.Sheets[sheetName];

    totalCellCount += getCellCountForSheet(worksheet, XLSX);
  });

  return totalCellCount;
};

export const getCellCountForSheet = (
  sheet: WorkSheet | undefined,
  XLSX: typeof import("xlsx")
) => {
  // Get the range of the sheet that LibreOffice will use
  const ref = sheet?.["!autofilter"]?.ref ?? sheet?.["!ref"];

  // Sanity check, some sheets don't have content
  if (!ref) {
    return 0;
  }

  const range = XLSX.utils.decode_range(ref);
  return (range.e.r - range.s.r + 1) * (range.e.c - range.s.c + 1);
};
