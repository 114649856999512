import { MutableRefObject, useEffect, useRef } from "react";
import { useSelector, EqualityFn } from "react-redux";

/**
 * The useRefSelector is like a useSelector, but does not fall victim to the general
 * React caching that occurs when a useSelector is attempted to be used in a .then()
 * or .catch() of a REST api call. The typing of this hook is taken from useSelector.
 * @param {Function} selector the selector function
 * @param {Function=} equalityFn the function that will be used to determine equality
 *
 * @returns the ref that stores the selected state.
 */
export const useRefSelector: <TState = unknown, Selected = unknown>(
  selector: (state: TState) => Selected,
  equalityFn?: EqualityFn<Selected> | undefined
) => MutableRefObject<Selected> = (selector, equalityFn) => {
  const selected = useSelector(selector, equalityFn);
  const selectedRef = useRef(selected);

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  return selectedRef;
};
