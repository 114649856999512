import { SectionSearchHookReturnValue } from "source/components/sidebar/multitoggle/SidebarToggleUtils";
import { RepoType } from "source/Types";
import { useGetRouter } from "../useGetRouter";
import { useGlobalEntitySearch } from "../useGlobalEntitySearch";

type UseDocumentSectionSearchProps = {
  repo?: RepoType;
};

export const useDocumentSectionSearch = ({
  repo,
}: UseDocumentSectionSearchProps): SectionSearchHookReturnValue | null => {
  const { router } = useGetRouter();

  const {
    onChange: onFilterSearchChange,
    value: titleSubstring,
    result,
    isLoading,
  } = useGlobalEntitySearch({
    target: {
      ...router.query,
      doc_ids: undefined,
      repo_ids: repo ? [repo.id] : [],
    },
    debounce: true,
    searchStateKey: "Documents",
  });

  if (!repo) return null;

  return {
    searchCallbacks: {
      onValueChange: (newVal: string) => {
        onFilterSearchChange(newVal);
      },
      onClear: () => {
        onFilterSearchChange("");
      },
      onSearch: (searchTerm) => {
        onFilterSearchChange(searchTerm);
      },
    },
    state: {
      value: titleSubstring,
      result,
      isLoading,
    },
  };
};

type useCompanySectionSearchProps = {
  repo?: RepoType;
};

export const useCompanySectionSearch = ({
  repo,
}: useCompanySectionSearchProps): SectionSearchHookReturnValue | null => {
  const { router } = useGetRouter();

  const {
    onChange: onFilterSearchChange,
    value: titleSubstring,
    result,
    isLoading,
  } = useGlobalEntitySearch({
    target: {
      ...router.query,
      doc_ids: undefined,
      repo_ids: repo ? [repo.id] : [],
    },
    debounce: true,
    searchStateKey: "Companies",
  });

  return {
    searchCallbacks: {
      onValueChange: (newVal: string) => {
        onFilterSearchChange(newVal);
      },
      onClear: () => {
        onFilterSearchChange("");
      },
      onSearch: (searchTerm) => {
        onFilterSearchChange(searchTerm);
      },
    },
    state: {
      value: titleSubstring,
      result,
      isLoading,
    },
  };
};
