import { TabType } from "source/components/tab-bar/tabs/Tabs";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { getUser } from "source/redux/user";
import { CHATDOCS_SLACK_WEBHOOK_URL } from "source/constants";
import { WEBSOCKET_URL } from "source/envConstants";
import {
  addMessages,
  getChatMessages,
  setAllMessages,
  setChatLoading,
  upsertMessage,
  addDocCountToMessage,
  setChatSessionId,
  setAllChatLoadingFalse,
  upsertTask,
  setAllTasks,
  addCitations,
  setAllCitations,
  addTaskIdToMessage,
  upsertOrHijackLast,
  EMPTY_ID,
  getSessionProps,
} from "source/redux/chat";
import {
  BackendReportFilter,
  ChatPerformanceType,
  Message,
} from "source/Types";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams } from "./search/useSearchParams";
import {
  getReportFilter,
  getTargetAppliedFilters,
  getTargetCompanies,
  getTargetDocs,
  getTargetNonPredefinedFilters,
  getTargetRepos,
  setTargetAppliedFilters,
  setTargetDisabledFilters,
  setTargetSuggestedFilters,
  upsertTargetAppliedFilters,
} from "source/redux/advanced";
import { GOALIE_USERGROUP, sendSlackAlert } from "source/utils/slack";
import LogRocket from "logrocket";
import {
  addAppliedFiltersToBackendReportFilter,
  convertToBackendReportFilter,
} from "source/components/matrix/utils";
import { getCurrentOrg } from "source/redux/organization";
import { useWebsocketSession } from "./useWebsocketSession";
import { SearchResponseType } from "source/redux/search";
import {
  ChatMessageNoId,
  ChatPlusMessage,
  ChatPlusWebsocketEvents,
  ChatWebsocketEvents,
  ChatWebsocketInitializationPayload,
  ChatWebsocketPayload,
  ChatWebsocketResponse,
} from "source/types/useChat.types";
import { useRefSelector } from "./useRefSelector";

type ChatType = "chat" | "search/chatplus";

export const tabTypeToChatType = (tabType: TabType): ChatType => {
  switch (tabType) {
    case "Chat":
      return "chat";
    case "ChatDocs":
      return "search/chatplus";
    default:
      return "chat";
  }
};

let logRocketURL = "";
// URL to view logrocket session
LogRocket.getSessionURL((sessionURL) => {
  logRocketURL = sessionURL;
});

export const generateChatWsURL = (
  chatType: ChatType,
  performance: ChatPerformanceType
) => `${WEBSOCKET_URL}/${chatType}/ws?mode=${performance}`;

export const useChat = (tabType: TabType) => {
  const dispatch = useDispatch();
  const params = useSearchParams();
  const chatType = tabTypeToChatType(tabType);
  const companyIds = useSelector(getTargetCompanies);
  const targetFilters = useSelector(getTargetNonPredefinedFilters);
  const targetDocs = useSelector(getTargetDocs);
  const targetRepos = useSelector(getTargetRepos);
  const appliedFilters = useSelector(getTargetAppliedFilters);
  const currentUser = useSelector(getUser);
  const reportFilter = useSelector(getReportFilter);
  const backendFilter = convertToBackendReportFilter(reportFilter);
  const currentOrg = useSelector(getCurrentOrg);
  const messagesRef = useRefSelector(getChatMessages);

  const sessionProps = useSelector(getSessionProps);
  const sessionIdRef = useRef<string>(sessionProps.sessionId);
  const performanceTypeRef = useRef<ChatPerformanceType>(
    sessionProps.chatPerformance
  );

  const onOpen = () => {
    const initializationPayload: ChatWebsocketInitializationPayload = {
      session_id: sessionIdRef.current,
      search_params: params,
    };
    // on ws open, send prev session id to re-initialize the chat
    sendMessage(initializationPayload);
  };

  const onMessage = (data: string) => {
    const res = JSON.parse(data) as ChatWebsocketResponse;
    const derefedMessages = messagesRef.current;

    // normal chat events:
    if (res.event === ChatWebsocketEvents.MESSAGE_TOKEN) {
      // Currently, for normal chat we send all messages to frontend on each token
      dispatch(setAllMessages(res.payload));
    }
    if (res.event === ChatWebsocketEvents.MESSAGE_FINAL) {
      dispatch(setChatLoading(false));
      dispatch(setAllMessages(res.payload));
    }
    // error handling
    if (res.event === ChatWebsocketEvents.ERROR) {
      // const payload = res.payload as MessageResponsePayload;
      dispatch(setAllMessages(res.payload));
      dispatch(setAllCitations([]));
      dispatch(setAllTasks([]));
      dispatch(setChatLoading(false));
    }
    // chat plus events:
    if (res.event === ChatPlusWebsocketEvents.SESSION_ID) {
      // new session initialized on backend, set session id in redux
      dispatch(setChatSessionId(res.payload));
      sessionIdRef.current = res.payload;
    }
    if (res.event === ChatPlusWebsocketEvents.CHAT_PLUS_CP_MESSAGE) {
      // Chat plus should send a properly formatted message and only the new message
      if (res.payload.error) {
        sendSlackAlert(
          `ChatDocs error for user ${currentUser?.email} in session ${
            sessionProps.sessionId
          } and message ${res.payload.id}: ${res.payload.error}${
            logRocketURL ? `\n\nLogRocket: ${logRocketURL}` : ""
          }\n\n${GOALIE_USERGROUP}`,
          CHATDOCS_SLACK_WEBHOOK_URL
        );
      }
      dispatch(upsertOrHijackLast(res.payload));
    }
    // if receive task, upsert task id to last message (since can only have 1 assistant message rn to change ?? )
    if (res.event === ChatPlusWebsocketEvents.TASK) {
      const lastMessage = derefedMessages[derefedMessages.length - 1];
      if (lastMessage?.id) {
        dispatch(
          addTaskIdToMessage({
            messageId: lastMessage?.id,
            taskId: res.payload.id,
          })
        );
      }

      dispatch(upsertTask(res.payload));
      // if there is a filter tool with outputs
      // we want to update the applied filters
      if (
        res.payload["tool_type"] === "filter" &&
        res.payload["output"] &&
        res.payload["output"].length
      ) {
        dispatch(upsertTargetAppliedFilters(res.payload["output"]));
      }
      // If search response present, do the necessaries
      if (res.payload?.output) {
        const data = res.payload.output as SearchResponseType;
        if (!data.results) return;
      }
    }
    if (res.event === ChatPlusWebsocketEvents.FILTER_TOOL_SEARCH_PARAMS) {
      const lastMessage = derefedMessages[derefedMessages.length - 1];
      try {
        api.filters.countReposDocs(res.payload).then(({ data }) => {
          // Increment message doc counts
          const total = Object.values(data.counts).reduce(
            (total, count) => total + count,
            0
          );

          if (lastMessage?.id) {
            dispatch(
              addDocCountToMessage({
                messageId: lastMessage?.id,
                docCount: total,
              })
            );
          }
        });
      } catch {
        console.error("Error getting doc counts");
      }
    }
    if (res.event === ChatPlusWebsocketEvents.CHAT_CITATION_RESULT_IDS) {
      const citations = res.payload.resultIds.map((resultId: string) => ({
        resultId,
        taskId: res.payload.taskId,
        messageId: res.payload.messageId,
      }));
      dispatch(addCitations(citations));
    }
    if (res.event === ChatPlusWebsocketEvents.DONE) {
      dispatch(setChatLoading(false));
    }
  };

  const handleSearch = async (value: string) => {
    dispatch(setChatLoading(true));

    const messageToSend: Message = {
      role: "user",
      content: value,
      id: uuidv4(),
    };
    if (chatType === "chat") {
      const messagesToSend: Message[] = [...messagesRef.current, messageToSend];
      // this is so beat, but we need to filter out these ids for rn as old chat backend doesnt expect them
      // remove id key from messagesToSend objects
      const messagesToSendNoIds: ChatMessageNoId = messagesToSend.map(
        (message) => {
          const { id, ...rest } = message;
          return rest;
        }
      );
      dispatch(
        addMessages([
          messageToSend, // Add assistant response as nothing for loading state
          { role: "assistant", content: "", id: uuidv4() },
        ])
      );

      sendMessage(messagesToSendNoIds);
    } else {
      dispatch(addMessages([messageToSend]));

      // Set relevant stuff back to nothing if query has changed and we didnt select one of the applied
      // recommended are suggested filters minus disabled filters
      // TODO: FARAZ doesn't handle case where query is the same
      let finalParams = { ...params };

      if (
        !targetFilters.length &&
        !companyIds.length &&
        !targetDocs.length &&
        !targetRepos.length
      ) {
        dispatch(setTargetAppliedFilters([]));
        dispatch(setTargetSuggestedFilters([]));
        dispatch(setTargetDisabledFilters([]));
        finalParams = { ...params, filters: [] };
      }

      try {
        dispatch(
          addMessages([
            {
              role: "assistant",
              content: "",
              id: EMPTY_ID,
              noDocs: false,
              // error: "No docs found",
            },
          ])
        );

        if (
          typeof backendFilter.values !== "boolean" &&
          typeof backendFilter.values !== "number" &&
          typeof backendFilter.values !== "string" &&
          (backendFilter.values as BackendReportFilter[]).length !== 0
        ) {
          // Add applied filters to backend filter so these are included for doc count
          const combinedFilter = addAppliedFiltersToBackendReportFilter(
            backendFilter,
            appliedFilters
          );
          const counts = await api.advancedfilters.getTripletDocCount(
            combinedFilter,
            currentOrg?.id
          );

          if (counts.doc_count === 0) {
            dispatch(
              upsertMessage({
                role: "assistant",
                content: "",
                id: EMPTY_ID,
                noDocs: true,
                error: "No docs found",
              })
            );
            dispatch(setAllChatLoadingFalse());
            // TODO send slack
            return;
          }
        }
      } catch (err) {
        console.error("Error fetching doc counts");
      }
      const payload: ChatPlusMessage = {
        content: value,
        search_params: finalParams,
      };
      sendMessage(payload);

      // we never want to keep cached disabled filters
      // and associated applied filters in the next query
      dispatch(setTargetDisabledFilters([]));
    }
  };

  const {
    sendMessage,
    connect,
    close: closeWebsocket,
  } = useWebsocketSession<ChatWebsocketPayload>({
    onOpen,
    onMessage,
    autoReconnect: false,
  });

  const close = () => {
    closeWebsocket();
    dispatch(setAllChatLoadingFalse());
  };

  const closeAndReconnect = (baseURL: string) => {
    closeWebsocket();
    dispatch(setAllChatLoadingFalse());
    connect(baseURL);
  };

  // Anytime that the session object changes, start a new WS connection.
  useEffect(() => {
    const { sessionId, chatPerformance } = sessionProps;
    if (sessionIdRef.current !== sessionId) {
      sessionIdRef.current = sessionId;
    }
    if (performanceTypeRef.current !== chatPerformance) {
      performanceTypeRef.current = chatPerformance;
    }

    // Start a new WS connection
    closeAndReconnect(generateChatWsURL(chatType, chatPerformance));

    return close;
  }, [sessionProps]);

  return {
    messages: messagesRef.current,
    handleSearch,
    close,
    closeAndReconnect,
  };
};
