import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { getCurrentOrg } from "source/redux/organization";
import {
  clearAllSearchStates,
  getSectionSearchStates,
  getSidebarRowsOpen,
  setSectionSearchResult,
  setSectionSearchValue,
  setSidebarRowsClosed,
} from "source/redux/sidebar";
import { DocumentType, FilterType, RepoType, SearchParams } from "source/Types";
import { useCurrentRepo } from "./repos/useCurrentRepo";
import { useSidebarRepos } from "./useSidebarRepos";
import { ALL_TICKERS_REPO_ID, PITCHBOOK_REPO_ID } from "source/constants";

type FilterSearchResultElement = {
  key: string;
  value: string;
};

export type GlobalEntitySearch = {
  result: GlobalEntitySearchResult;
  isLoading: boolean;
  value: string;
  onChange: (value: string) => void;
};

export type GlobalEntitySearchResult = {
  pitchbookRepos: RepoType[];
  docs: DocumentType[];
  ancestors: any;
  companyRepos: RepoType[];
  publicRepos: RepoType[];
  docFilters: FilterType[];
  repoFilters: FilterType[];
  repos: RepoType[];
};

type GlobalEntitySearchArgs = {
  target: Partial<SearchParams>;
  debounce: boolean;
  // for saving search state to redux
  searchStateKey: string;
  searchDocFilters?: boolean;
  searchRepoFilters?: boolean;
  searchAllRepos?: boolean;
  skipDocs?: boolean;
};

/**
 * Hook to manage title search over Hebbia target entities (e.g., documents, repos, filters).
 * Each time this hook is called, a unique search is spawned.
 *
 * `searchStateKey` is a globally unique string key used to store search state in redux.
 */
export const useGlobalEntitySearch = (
  args: GlobalEntitySearchArgs
): GlobalEntitySearch => {
  const {
    debounce,
    searchStateKey,
    target,
    skipDocs,
    searchRepoFilters,
    searchDocFilters,
    searchAllRepos,
  } = args;
  const sectionSearchStates = useSelector(getSectionSearchStates);
  const searchState = sectionSearchStates[searchStateKey];
  const value = searchState?.value ?? "";
  const [isDocLoading, setDocLoading] = useState(false);
  const [isRepoFiltersLoading, setRepoFiltersLoading] = useState(false);
  const [isDocFiltersLoading, setDocFiltersLoading] = useState(false);
  const [isPrivateRepoLoading, setPrivateRepoLoading] = useState(false);
  const [isPublicRepoLoading, setPublicRepoLoading] = useState(false);
  const [isPitchbookRepoLoading, setPitchbookRepoLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(value);
  const org = useSelector(getCurrentOrg);
  const rowsOpenMap = useSelector(getSidebarRowsOpen);
  const dispatch = useDispatch();
  const currentRepo = useCurrentRepo(true);
  const refMostRecentFetchTime = useRef(0);
  const sidebarRepos = useSidebarRepos();
  const privateRepos = sidebarRepos.filter((repo) => repo.private);

  const privateRepoResult = {
    repos: searchState?.result?.repos ?? [],
  };
  const publicRepoResult = {
    companyRepos: searchState?.result?.companyRepos ?? [],
    publicRepos: searchState?.result?.publicRepos ?? [],
  };
  const pitchbookResult = {
    pitchbookRepos: searchState?.result?.pitchbookRepos ?? [],
  };
  const docResult = {
    docs: searchState?.result?.docs ?? [],
    ancestors: searchState?.result?.ancestors ?? [],
  };

  const filterResult = {
    repoFilters: searchState?.result?.repoFilters ?? [],
    docFilters: searchState?.result?.docFilters ?? [],
  };

  const setRepoResult = (repoResult: any) => {
    dispatch(
      setSectionSearchResult({
        sectionTitle: searchStateKey,
        resultUpdate: repoResult,
      })
    );
  };

  const setDocResult = (docResult: any) => {
    dispatch(
      setSectionSearchResult({
        sectionTitle: searchStateKey,
        resultUpdate: docResult,
      })
    );
  };

  const clearSectionResults = () => {
    dispatch(clearAllSearchStates());
  };

  useEffect(() => {
    let cancelled = false;
    if (!searchValue.length) {
      dispatch(
        setSectionSearchResult({
          sectionTitle: searchStateKey,
          resultUpdate: {
            docs: [],
            ancestors: [],
            repos: [],
            filters: [],
            companyRepos: [],
            publicRepos: [],
            pitchbookRepos: [],
          },
        })
      );
      setPrivateRepoLoading(false);
      setPitchbookRepoLoading(false);
      setDocFiltersLoading(false);
      setRepoFiltersLoading(false);
      setPublicRepoLoading(false);
      setDocLoading(false);
      return;
    }

    const thisEffectTime = Date.now();
    refMostRecentFetchTime.current = thisEffectTime;
    const debouncedFetchData = _.debounce(() => {
      // only send most recent fetch
      if (refMostRecentFetchTime.current !== thisEffectTime) return;
      // If at home screen, search private repos by string matching against Redux (faster than API)
      if (!currentRepo || searchAllRepos) {
        setRepoResult({
          repos: (searchAllRepos
            ? privateRepos
            : privateRepos.filter((r) => target.repo_ids?.includes(r.id))
          ).filter((r) =>
            r.name.toLowerCase().includes(searchValue.toLowerCase())
          ),
        });
        if (!cancelled) setPrivateRepoLoading(false);
      } else {
        setPrivateRepoLoading(false);
      }

      // If at home screen or company docs, search public repo
      if (!currentRepo || searchAllRepos) {
        // Search for public repos
        api.filters
          .filterPublicRepos({ title: searchValue })
          .then(({ company_repos }) => {
            if (!cancelled)
              setRepoResult({
                companyRepos: company_repos,
              });
          })
          .finally(() => {
            if (!cancelled) setPublicRepoLoading(false);
          });
      } else {
        setPublicRepoLoading(false);
      }

      if (searchDocFilters) {
        api.filters
          .searchDocFilters({
            repo_ids: target.repo_ids ?? [],
            query: searchValue,
          })
          .then(({ data }) => {
            if (!cancelled) {
              // sort returned docs first
              const docFilterList = data.filters;
              const filters = Object.entries<string[]>(docFilterList).flatMap(
                ([k, values]) => values.map((v) => ({ key: k, value: v }))
              );
              setDocResult({
                docFilters: filters,
              });
            }
          })
          .finally(() => setDocFiltersLoading(false));
      } else {
        setDocFiltersLoading(false);
      }
      if (searchRepoFilters) {
        api.filters
          .searchRepoFilters({
            repo_ids: searchAllRepos
              ? [PITCHBOOK_REPO_ID, ALL_TICKERS_REPO_ID]
              : (target.repo_ids ?? []),
            query: searchValue,
          })
          .then(({ data }) => {
            if (!cancelled) {
              // sort returned docs first
              const docFilterList = data.filters;
              const filters = Object.values<FilterSearchResultElement[]>(
                docFilterList
              ).flatMap((val) => val.map((elem) => elem));
              setDocResult({
                repoFilters: filters,
              });
            }
          })
          .finally(() => setRepoFiltersLoading(false));
      } else {
        setRepoFiltersLoading(false);
      }
      if (!skipDocs) {
        (target.filters
          ? api.filters.filterDocs({
              ...target,
              filters:
                typeof target.filters === "string"
                  ? JSON.parse(target.filters)
                  : target.filters,
              title: searchValue,
            })
          : api.filters.filterSubstringDocs({
              ...target,
              title: searchValue,
            })
        )
          .then(({ docs, ancestors }) => {
            if (!cancelled) {
              // sort returned docs first
              const sortedDocs = docs.sort(
                (docA: DocumentType, docB: DocumentType) => {
                  if (!!docA.num_children && !docB.num_children)
                    // docA is a folder, sort it ahead of docB
                    return -1;
                  else if (!docA.num_children && !!docB.num_children) return 1;
                  else return docA.title.localeCompare(docB.title);
                }
              );
              // collapse all of the docs that are folders
              const folderRowIds = sortedDocs
                .filter((doc) => !!doc.num_children)
                .map((doc) => `d${doc.id}`);
              const sidebarRowsToCloseRowIds: string[] = Object.keys(
                rowsOpenMap
              ).filter((id) => folderRowIds.includes(id));
              dispatch(setSidebarRowsClosed(sidebarRowsToCloseRowIds));

              setDocResult({
                docs: sortedDocs,
                ancestors: ancestors,
              });
            }
          })
          .finally(() => {
            if (!cancelled) setDocLoading(false);
          });
      } else {
        setDocLoading(false);
      }
    }, 250);
    debouncedFetchData();

    return () => {
      // this return callback prevents any redux state updates except
      // those due to the most recent fetch. the debounce/throttle logic
      // above, on the other hand, ensures we only call fetches every N millis.
      cancelled = true;
    };
  }, [searchValue, org?.id]);

  const debounceSetValue = useCallback(_.debounce(setSearchValue, 350), []);

  const onChange = (value: string) => {
    setPrivateRepoLoading(true);
    setDocFiltersLoading(true);
    setRepoFiltersLoading(true);
    setPublicRepoLoading(true);
    setPitchbookRepoLoading(true);
    setDocLoading(true);
    dispatch(
      setSectionSearchValue({
        sectionTitle: searchStateKey,
        searchValue: value,
      })
    );

    if (debounce) {
      debounceSetValue(value);
    } else {
      setSearchValue(value);
    }
  };

  useEffect(
    () => () => {
      clearSectionResults();
    },
    []
  );

  const result = {
    ...publicRepoResult,
    ...privateRepoResult,
    ...docResult,
    ...filterResult,
    ...pitchbookResult,
  };
  const isLoading =
    isPrivateRepoLoading ||
    isPitchbookRepoLoading ||
    isPublicRepoLoading ||
    isDocFiltersLoading ||
    isRepoFiltersLoading ||
    isDocLoading;

  return {
    result,
    isLoading,
    value,
    onChange,
  };
};
