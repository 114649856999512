import AddIcon from "@mui/icons-material/Add";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { AnyAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { ToggleIconSidebar } from "source/components/shared/ToggleIcon";
import { Tooltip } from "source/components/shared/Tooltip";
import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALWAYS_SHOW_TOGGLE_MIMES } from "source/constants";
import { isFolder, isShortDoc } from "source/utils/documents";
import { useBuildStatus } from "source/hooks/build/useBuildStatus";
import { useCanEditRepo } from "source/hooks/permissions/useCanEditRepo";
import { useFetchPaginatedChildDocs } from "source/hooks/repos/useFetchPaginatedChildDocs";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { useSidebarRow } from "source/hooks/useSidebarRow";
import { updateActivitiesByDocId } from "source/redux/activityFeed";
import { upsertSidebarRowsOpen } from "source/redux/sidebar";
import {
  DocumentType,
  RepoType,
  ReportFilter,
  ReportFilterOperation,
} from "source/Types";
import { DocPins } from "./DocPins";
import { DocRowIcon } from "./DocRowIcon";
import { LoadingRow } from "./LoadingRow";
import { SeeMore } from "./SeeMore";
import { SidebarInputKeywordBoldingProps } from "./SidebarInput";
import { SidebarRow } from "./SidebarRow";
import { SingleDocRow } from "./SingleDocRow";
import {
  getSearchAppliedFilters,
  upsertSearchDisabledFilters,
} from "source/redux/search";
import { FiltersConfig } from "source/constants/filters";
import { queryClient } from "pages/_app";
import {
  setDoc,
  useMoveDocMutation,
  useUpdateDocMutation,
} from "source/api/docs/useMutateDocs";
import { docsKeys } from "source/api/docs/docsKeyFactory";
import { docFetcher } from "source/api/docs/useQueryDocs";
import {
  addReportFilter,
  getFilterSearchKey,
  getReportFilterKey,
  getTargetDisabledFilters,
  getTargetFilterExists,
  removeReportFilter,
} from "source/redux/advanced";
import { DOCUMENT_SECTION_TITLE } from "source/components/gigabar/components/FileSection";
import { getObjectUrl } from "source/api/authenticatedAxios";

type Props = {
  doc: DocumentType;
  depth?: number;
  showSeeMore?: boolean;
  defaultOpen?: boolean;
  useDisplayTitle?: boolean;
  repo?: RepoType;
  style?: any;
  keywordBolding?: SidebarInputKeywordBoldingProps;
  idPrefix?: string;
};

/**
 * DocRowWithChildren is an abstraction around one or many document rows. The number of rows depends
 * on whether the document has children, is collapsed or expanded, etc.
 *
 * In contrast, SingleDocRow corresponds to exactly one visual row on the sidebar and is used
 * across global and intra-repo views.
 *
 * NOTE: this component also decides whether to render all rows or just the selected targets.
 */
export const DocRowAndChildren = ({
  repo,
  doc,
  depth,
  showSeeMore,
  defaultOpen = false,
  useDisplayTitle = true,
  style,
  keywordBolding,
  idPrefix,
}: Props) => {
  const id = `${idPrefix}${doc.id}`;
  const dispatch = useDispatch();
  const canEdit = useCanEditRepo(repo || null);
  const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
  const rowKey = getReportFilterKey(sectionKey, doc.id);
  const isActive = useSelector(getTargetFilterExists(sectionKey, rowKey));
  const searchKey = getFilterSearchKey("document");

  const { mutate: docUpdateMutation } = useUpdateDocMutation();
  const { mutate: moveDocMutation } = useMoveDocMutation();

  const {
    visibleChildren,
    allChildren,
    isLoading,
    fetchNextPage,
    hasNextPage: canLoadMoreChildren,
  } = useFetchPaginatedChildDocs(doc, repo);

  const appliedFilters = useSelector(getSearchAppliedFilters);
  const appliedDocIds =
    appliedFilters
      ?.filter((filter) => filter.key === FiltersConfig.CUSTOM_FILTER_KEY_DOC)
      .map((filter) => filter.value) ?? [];

  const disabledFilters = useSelector(getTargetDisabledFilters);
  const nonDisabledAppliedDocIds = appliedDocIds.filter(
    (id) => !disabledFilters?.find((filter) => filter.value === id)
  );

  const canDocAddChildren = !!canEdit && doc.mime === "hebbia/folder";
  const canDocMove =
    !!canEdit &&
    !ALWAYS_SHOW_TOGGLE_MIMES.includes(doc.mime) && // don't let foldery docs move (yet)
    ["local", "hebbia/folder"].includes(doc.type); // integrations can't be moved
  const canDocToggle =
    doc.num_children > 0 || ALWAYS_SHOW_TOGGLE_MIMES.includes(doc.mime);
  const { isBuilding, isBuildingStatic } = useBuildStatus(repo);
  const { goToDoc, goToRepoAddDocs } = useGlobalNavigator();

  const sidebarRow = useSidebarRow({
    id: id,
    defaultOpen: defaultOpen,
  });
  const { isOpen, toggle } = sidebarRow;

  useEffect(() => {
    if (defaultOpen && !isOpen) toggle();
  }, []);

  const handleOpenSource = async () => {
    if (!doc.source) return;
    if (doc.type === "sec")
      (window as any).open(doc.data?.url, "_blank").focus();
    else {
      const url = await getObjectUrl(doc.source);
      (window as any).open(url, "_blank").focus();
    }
  };

  const handleSeeMore = (e: React.MouseEvent) => {
    e.stopPropagation();
    fetchNextPage();
  };

  const handleMove = async (item, monitor) => {
    if (!item.docId) return;
    if (item.docId === doc.id) return; // no adding doc to itself
    if (item.parentId === doc.id) return; // already a child

    moveDocMutation({
      docId: item.docId,
      targetParentId: doc.id,
      repoId: doc.repo_id,
    });
    message.info("Moving doc...");
    goToDoc(item.docId);
  };

  const handleRename = async (newTitle: string) => {
    if (!newTitle.length) return;
    docUpdateMutation({
      docId: doc.id,
      doc: { title: newTitle },
      repoId: repo?.id,
    });
    if (!repo?.id) return;
    updateActivitiesByDocId(dispatch, repo.id, doc.id, newTitle);
  };

  const handleCheckBoxClick = () => {
    // if doc_id is in appliedFilterDocIds add it to disabled
    if (isActive) {
      if (nonDisabledAppliedDocIds.includes(doc.id)) {
        // add doc id to disabled
        const disableTheseFilters = appliedFilters?.filter(
          (filter) => filter.value === doc.id
        );
        if (disableTheseFilters)
          dispatch(upsertSearchDisabledFilters(disableTheseFilters));
      } else {
        dispatch(removeReportFilter({ sectionKey, rowKey }));
      }
    } else {
      // Create new report filter
      const reportFilter: ReportFilter = {
        // This key is used to identify the filter at search time
        key: searchKey,
        values: doc.id,
        operation: ReportFilterOperation.IS,
      };
      dispatch(addReportFilter({ sectionKey, rowKey, reportFilter }));
      handleRevealDoc(doc.id, dispatch, doc.repo_id);
    }
  };

  const handleAddChildDocs = (e) => {
    e.stopPropagation();
    goToRepoAddDocs(doc.repo_id, doc.id);
  };

  const noChildrenCopy = (isBuilding?: boolean) => {
    if (isBuilding || isBuildingStatic) return "Still indexing...";

    if (doc.type === "sec") return "No SEC filings since January 2021";
    if (doc.type === "earnings") return "No earnings calls since January 2021";

    return "No documents";
  };

  return (
    <SingleDocRow
      id={id}
      title={
        useDisplayTitle && doc.display_title ? doc.display_title : doc.title
      }
      depth={depth}
      doc={doc}
      keywordBolding={keywordBolding}
      repo={repo}
      badge={
        <>
          {isShortDoc(doc) && (
            <Tooltip title="Website blocked, likely missing content.">
              <SyncProblemIcon
                fontSize="small"
                className="ml-1 cursor-pointer"
              />
            </Tooltip>
          )}
          {canEdit && doc.mime === "hebbia/folder" && (
            <div
              className={`mr-1 flex items-center text-lg text-secondary ${
                isActive
                  ? "hover:bg-lightPurple/20 active:bg-lightPurple/20"
                  : "hover:bg-hoverRowIcon active:bg-hoverRowIconActive"
              }`}
              onClick={handleAddChildDocs}
            >
              <AddIcon fontSize="small" />
            </div>
          )}
          {canDocToggle && (
            <ToggleIconSidebar
              useSidebarRow={sidebarRow}
              style={{ paddingRight: 4, marginRight: 1 }}
            />
          )}
        </>
      }
      icon={<DocRowIcon doc={doc} />}
      isActive={isActive}
      onDocRowClick={(e) => {
        // Open doc modal with doc if not a folder
        if (!isFolder(doc.mime)) {
          goToDoc(doc.id, doc.repo_id);
          setDoc(doc);
        }
      }}
      onDocCheckboxClick={handleCheckBoxClick}
      onIconClick={handleOpenSource}
      handleDoneEditing={handleRename}
      style={style}
      isDndTarget={canDocAddChildren}
      dndItem={
        canDocMove ? { docId: doc.id, parentId: doc.parent_id } : undefined
      }
      onDrop={handleMove}
    >
      {!canDocToggle && isActive && !visibleChildren.length && (
        <DocPins doc={doc} depth={depth} />
      )}
      {canDocToggle && isOpen && (
        <>
          {isLoading && !visibleChildren.length && (
            <LoadingRow depth={(depth || 0) + 1} />
          )}
          {visibleChildren.length > 0 && (
            <>
              {(showSeeMore ? visibleChildren : allChildren).map(
                (child, idx) => (
                  <DocRowAndChildren
                    repo={repo}
                    doc={child}
                    key={child.id + idx}
                    keywordBolding={keywordBolding}
                    useDisplayTitle={useDisplayTitle}
                    depth={(depth || 0) + 1}
                    showSeeMore={showSeeMore}
                    style={style}
                    idPrefix={`${idPrefix}`}
                  />
                )
              )}
              {showSeeMore && canLoadMoreChildren && (
                <SeeMore depth={(depth || 0) + 1} onClick={handleSeeMore} />
              )}
            </>
          )}
          {!visibleChildren.length && !isLoading && (
            <SidebarRow
              id={`d${doc.id}-no-children`}
              title={noChildrenCopy(isBuilding)}
              style={{
                color: "var(--text-tertiary)",
                WebkitTextFillColor: "var(--text-tertiary)",
                ...style,
              }}
              depth={(depth ?? 0) + 1}
            />
          )}
        </>
      )}
    </SingleDocRow>
  );
};

/**
 * Manages the two key components of revealing a doc in the sidebar:
 * 1. fetching its data + its ancestors' data from the backend and adding that to redux state
 * 2. making sure the sidebar UI state is such that the document is revealed.
 *    in this case, that involves making sure the ancestors are open
 *
 * NOTE: what this DOES NOT manage (yet) is paginating to reveal to document within its
 *        parent folder. This is currently handled in DocRowAndChildren
 */
const handleRevealDoc = async (
  docId: string,
  dispatch: Dispatch<AnyAction>,
  repoId?: string
) => {
  // This will imperatively fetch the query either from the cache if it is there, otherwise from the fetcher
  // and then will place the data in the cache (notice this matches the single docFetcher)
  const data = await queryClient.fetchQuery({
    queryKey: docsKeys.doc(docId),
    queryFn: docFetcher,
  });

  if (data?.ancestors.length) {
    const payload = {};
    data.ancestors.forEach((ancestor) => {
      payload[`d${ancestor.id}`] = true;
    });
    dispatch(upsertSidebarRowsOpen(payload));
  }
};
