import React from "react";
import { FileSection } from "./components/FileSection";
import { CompanySection } from "./components/CompanySection";
import { FiltersSections } from "./components/FiltersSections";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { TOP_BAR_HEIGHT } from "source/constants";
import styled from "styled-components";
import { getCurrentOrg } from "source/redux/organization";
import { useSelector } from "react-redux";
import { GigabarHeader } from "./components/GigabarHeader";
import { RepoExplorer } from "../sidebar/components/RepoExplorer";
import { TopLeft } from "../sidebar/components/TopLeft";

type Props = {
  reposCanOpen?: boolean;
  hideOrgSwitcher?: boolean;
  sortComponent?: React.ReactNode;
  additionalComponents?: React.ReactNode;
  stickyHeader?: boolean;
};

export const Gigabar = ({
  reposCanOpen,
  hideOrgSwitcher,
  sortComponent,
  additionalComponents,
  stickyHeader,
}: Props) => {
  const currentRepo = useCurrentRepo();
  const currentOrg = useSelector(getCurrentOrg);

  if (currentRepo) {
    return (
      <div className="h-screen w-full bg-backgroundLight">
        <Topbar>
          <TopLeft showCollapseIcon={false} />
        </Topbar>
        <div
          style={{
            overflowY: "auto",
            height: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
            overflowX: "hidden",
            overscrollBehavior: "contain",
            position: "relative",
            marginBottom: "-16px",
          }}
        >
          <RepoExplorer repo={currentRepo} />
        </div>
      </div>
    );
  }

  return (
    <div className="group/sidebar sticky top-0 h-full overscroll-contain">
      {sortComponent}
      <GigabarHeader title="Filter" stickyHeader={stickyHeader} />
      <FiltersSections />
      {(!currentOrg?.config || currentOrg.config.enable_public_sources) && (
        <CompanySection reposCanOpen={reposCanOpen} />
      )}
      <FileSection
        reposCanOpen={reposCanOpen}
        hideOrgSwitcher={hideOrgSwitcher}
      />
      {additionalComponents}
    </div>
  );
};
const Topbar = styled.div`
  // height: ${TOP_BAR_HEIGHT}px;
  // box-shadow: 0px 10px 37px -17px rgba(var(--shadow), 0.7);
  position: sticky;
  top: 0;
  z-index: 30;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
