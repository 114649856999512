import React from "react";
import styled from "styled-components";
import { TOP_BAR_HEIGHT } from "source/constants";
import { wordToTitleCase } from "source/utils/common/strings";
import { DocumentType } from "source/Types";
import { ArrowIcon } from "./ArrowIcon";
import moment from "moment";

type Props = {
  backgroundColor: string;
  handleClick: () => void;
  doc?: DocumentType;
};

export const MetadataRow = React.memo(
  ({ doc, backgroundColor, handleClick }: Props) => {
    const metadata = doc?.meta;
    const createdAt = moment(doc?.timestamp).format("MMMM Do, YYYY");
    return (
      <>
        {!!metadata && (
          <Row
            style={{
              backgroundColor,
              top: TOP_BAR_HEIGHT,
            }}
          >
            <Meta>
              <MetaField>
                <span style={{ color: "var(--text-primary)" }}>
                  Created At:
                </span>{" "}
                {createdAt}
              </MetaField>
              {Object.entries(metadata)
                .filter(
                  ([key, val], i) => !key.includes("hebbia") // internal metadata keys are prefixed with "hebbia"
                )
                .map(([key, val], i) => (
                  <>
                    <MetaField>
                      <span style={{ color: "var(--text-primary)" }}>
                        {wordToTitleCase(key.replace(/[:_]/g, " "))}:
                      </span>{" "}
                      {val}
                    </MetaField>
                  </>
                ))}
            </Meta>
            <ArrowIcon handleClick={handleClick} rowVisible={true} />
          </Row>
        )}
      </>
    );
  }
);

const Row = styled.div`
  display: flex;
  width: 100%;
  z-index: 500;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  height: 30px;
  margin-bottom: 5px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  position: sticky;
`;

const Meta = styled.div`
  overflow: scroll;
  white-space: nowrap;
  padding-top: 10px;
  margin-right: 50px;
  margin-left: 10px;
`;

const MetaField = styled.span`
  font-size: 0.7rem;
  color: var(--text-secondary);
  padding: 0px 4px;
`;
