import { TooltipHotKey } from "source/components/shared/Buttons/TooltipHotKey";
import React, { MouseEvent, useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

type Props = {
  opacity: number;
  handleDelete: (e: MouseEvent) => void;
  tooltipPrefix?: string;
  text?: string;
};

export const TrashCan = ({
  opacity,
  handleDelete,
  tooltipPrefix,
  text,
}: Props) => {
  const [hovering, setHovering] = useState(false);

  return (
    <TooltipHotKey
      prefix={tooltipPrefix ? tooltipPrefix : "Hide result"}
      commands={["D"]}
    >
      <div
        className={`flex cursor-pointer select-none flex-nowrap items-center justify-center text-lg text-tertiary opacity-${opacity} ${
          hovering ? "text-[#b4272c]" : "text-tertiary"
        }`}
        onMouseOver={() => setHovering(true)}
        onMouseOut={() => setHovering(false)}
        onClick={handleDelete}
      >
        <DeleteOutlinedIcon
          fontSize="inherit"
          className="hover:text-lightBlack"
        />
        {text ? <span className="p-1 text-xs">{text}</span> : null}
      </div>
    </TooltipHotKey>
  );
};
