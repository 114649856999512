import React from "react";
import TooltipRoot from "@mui/material/Tooltip";
import { CommandText } from "./CommandText";

type Props = {
  commands: string[];
  children: JSX.Element;
  prefix?: string;
  icon?: JSX.Element;
  // delay for tooltip
  enterDelay?: number;
  // delay for tooltip displaying on next tooltip
  enterNextDelay?: number;
  styleOverride?: React.CSSProperties;
};

/** Renders a tooltip around the children elements,
including an Icon and commands if specified */
export const TooltipHotKey = ({
  commands,
  prefix,
  icon,
  enterDelay = 500,
  enterNextDelay = 300,
  children,
}: Props) => (
  <TooltipRoot
    enterDelay={enterDelay}
    enterNextDelay={enterNextDelay}
    title={
      <CommandText prefix={prefix} commands={commands}>
        {icon}
      </CommandText>
    }
    sx={{ width: 320 }}
  >
    {children}
  </TooltipRoot>
);
