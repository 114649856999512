import React from "react";
import { SidebarRow } from "./SidebarRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type LoadMoreProps = {
  onClick: () => void;
  text?: string;
};

export const LoadMoreRow = ({ onClick, text }: LoadMoreProps) => (
  <SidebarRow
    id="loadmore"
    title={"Load More"}
    style={{
      color: "var(--text-tertiary)",
      WebkitTextFillColor: "var(--text-tertiary)",
    }}
    onClick={onClick}
    icon={<ExpandMoreIcon className="mr-1" fontSize="small" />}
  />
);
