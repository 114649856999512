import React from "react";
import { notification } from "antd";
import { encode } from "querystring";
import { useDispatch } from "react-redux";
import api from "source/api";
import { BASE_URL } from "source/constants";
import { DocumentType, TableType } from "source/Types";
import { exportTable, loadXLSX } from "source/utils/xlsx";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import LinkIcon from "@mui/icons-material/Link";
import { setDoc } from "source/api/docs/useMutateDocs";
import { api as authenticatedApi } from "source/api/authenticatedAxios";

/** Helper fucntions to handle the different selection types.
 * A selection can either be a table or a pin. */
export const useSelection = (doc: DocumentType | undefined) => {
  const dispatch = useDispatch();

  const handleTableRename = (newTitle: string, tableId: string) => {
    if (!doc) return;
    // Update doc tables
    const doc_tables = doc.tables_data?.map((table) => {
      const table_copy = { ...table };
      if (table.id === tableId) table_copy.table_name = newTitle;
      return table_copy;
    });
    if (!doc_tables) return;

    api.docs.updateTablesStatic(doc.id, {
      repo_id: doc.repo_id,
      tables_data: doc_tables,
    });
    const new_doc = {
      ...doc,
      tables_data: doc_tables,
    };
    setDoc(new_doc);
  };

  const handleDeleteSelection = (tableId: string) => {
    if (!doc) return;
    const doc_tables = doc.tables_data?.filter((table) => table.id !== tableId);
    if (!doc_tables) return;

    api.docs.updateTablesStatic(doc.id, {
      repo_id: doc.repo_id,
      tables_data: doc_tables,
    });
    const new_doc = {
      ...doc,
      tables_data: doc_tables,
    };
    setDoc(new_doc);
  };

  const copySelectionLink = (selection: string) => {
    if (!doc) return;
    // Copy link to clipboard
    const query = {
      selection,
    };

    const url =
      BASE_URL +
      `/repos/${doc.repo_id}?doc_ids=%5B%22${doc.id}%22%5D` +
      encode(query);
    navigator.clipboard.writeText(url);
    notification.success({
      message: "Copied link to clipboard",
      placement: "bottomRight",
      duration: 2,
      icon: <LinkIcon className="text-textBlue" />,
      className: "border drop-shadow-lg flex items-center p-4",
      closeIcon: <></>,
    });
  };

  // Download a single table
  const handleDownloadSelection = async (tableId: string) => {
    if (!doc) return;
    const table: TableType | undefined = doc.tables_data?.find(
      (table) => table.id === tableId
    );
    if (!table) return;
    const doc_urls = [table.url];
    let img_urls: string[] | undefined = undefined;
    if (table.img_url) {
      img_urls = [table.img_url];
    }
    exportTable(doc, doc_urls, img_urls, dispatch);
  };

  // Download all tables in a single doc
  const handleDownloadTables = async (tables: TableType[]) => {
    if (!doc) return;
    const doc_urls: string[] = [];
    const img_urls: string[] = [];
    for (const table of tables) {
      doc_urls.push(table.url);
      if (table.img_url) {
        img_urls.push(table.img_url);
      }
    }
    exportTable(doc, doc_urls, img_urls, dispatch);
  };

  const handleCopyTable = async (table: TableType) => {
    const { data } = await authenticatedApi.get(table.url);
    try {
      const XLSX = await loadXLSX();
      const wb = XLSX.read(data, { type: "string" });
      const firstSheet = wb.SheetNames[0];
      if (!firstSheet) throw new Error("No sheets");
      const ws = wb.Sheets[firstSheet];
      if (!ws) throw new Error("No sheet object");
      const html = XLSX.utils.sheet_to_html(ws);
      const blob = new Blob([html], { type: "text/html" });
      navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
    } catch {
      navigator.clipboard.write(data);
    }

    notification.success({
      message: "Copied table to clipboard",
      placement: "bottomRight",
      duration: 2,
      icon: <ContentCopySharpIcon className="text-textBlue" />,
      className: "border drop-shadow-lg flex items-center p-4",
      closeIcon: <></>,
    });
  };

  return {
    handleTableRename,
    handleDeleteSelection,
    copySelectionLink,
    handleDownloadSelection,
    handleDownloadTables,
    handleCopyTable,
  };
};
