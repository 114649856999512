import React from "react";
import { Tooltip } from "@mui/material";
import { useWindowHeight } from "@react-hook/window-size";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { MIN_SIDEBAR_WIDTH } from "source/constants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { AlertType, FilterType } from "source/Types";
import { useQueryAlerts } from "source/api/alerts/useQueryAlerts";
import { TrashCan } from "source/components/shared/EmbedCard/components/TrashCan";
import {
  getAlertEditingProperties,
  setAlertEditingProps,
  setCurrentAlert,
} from "source/redux/alerts";

export const AlertsSidebar = () => {
  const dispatch = useDispatch();
  const height = useWindowHeight();
  const alerts = useQueryAlerts();
  const alertEditingProps = useSelector(getAlertEditingProperties);
  const [creatingAlert, setCreatingAlert] = useState(false);
  // A null index is used to select the unfinalized / currently editing alert
  const [selectedIndex, setSelectedIndex] = useState(alerts.length ? 0 : null);

  const dispatchAlertEditingProps = (alertIdx: number | null) => {
    if (selectedIndex === null) {
      dispatch(
        setAlertEditingProps({
          currentAlert: null,
          query: null,
          filterString: null,
        })
      );
      return;
    }

    const currAlert = alerts[selectedIndex];
    dispatch(
      setAlertEditingProps({
        currentAlert: currAlert ?? null,
        query: currAlert?.query ?? "",
        filterString: getFilterString(currAlert?.filters),
      })
    );
  };

  // On initial load, populate the alert editing props with the first alert if exists,
  // else a new alert.
  useEffect(() => {
    dispatchAlertEditingProps(selectedIndex);
  }, []);

  // Anytime the selected index changes, update the alert editing props
  useEffect(() => {
    dispatchAlertEditingProps(selectedIndex);
  }, [selectedIndex]);

  // TODO: this func!!
  const getFilterString = (filters?: FilterType[]): string => {
    if (!filters || filters.length === 0) {
      return "All results";
    }

    const filterStrings = filters.map((filter) => filter.key);
    return filterStrings.join(", ");
  };

  return (
    <ResizableBox
      width={MIN_SIDEBAR_WIDTH}
      height={height}
      minConstraints={[MIN_SIDEBAR_WIDTH, 0]}
      maxConstraints={[500, Infinity]}
      axis="x"
      handle={
        <Tooltip
          title="Drag to resize"
          followCursor={true}
          enterNextDelay={500}
        >
          <div className="group absolute -right-[7px] top-0 z-[400] flex h-full w-4 cursor-col-resize justify-center">
            <div className="invisible h-full w-0.5 bg-lightGray opacity-0 transition-all group-hover:visible group-hover:opacity-100"></div>
          </div>
        </Tooltip>
      }
      style={{
        color: "var(--text-secondary)",
        position: "sticky",
        top: 0,
        zIndex: 400,
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        userSelect: "none",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div className="shadow-sidebarShadow w-full bg-backgroundLight bg-inherit px-4">
        {/* Takes same formatting as AlertsTopBar so that they're both aligned */}
        <div className="sticky left-0 top-0 flex h-16 items-center text-lg font-semibold text-primary">
          My Alerts ({alerts.length})
        </div>
        <div
          className="mb-2 flex w-full flex-row items-center gap-1 border px-2 py-4 hover:bg-gray1 hover:text-primary active:bg-gray2"
          onClick={() => {
            setCreatingAlert(true);
            setSelectedIndex(-1);
          }}
        >
          <span>
            <AddOutlinedIcon />
          </span>
          <span>New alert</span>
        </div>
        <>
          {creatingAlert && (
            <div
              className={`my-2 flex w-full flex-row justify-between p-4 hover:bg-blue3 ${
                selectedIndex === -1 && "bg-gray1"
              }`}
              onClick={() => setSelectedIndex(-1)}
            >
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-primary">
                  {(alertEditingProps.query ?? "").length > 0
                    ? alertEditingProps.query
                    : "New alert"}
                </div>
                <div className="font-light">
                  Filters: {alertEditingProps.filterString ?? "All results"}
                </div>
              </div>
              <div className="h-full self-center">
                <TrashCan
                  opacity={1}
                  handleDelete={() => {
                    // TODO: implement
                    console.log("delete!");
                  }}
                />
              </div>
            </div>
          )}
          {alerts.map((alert: AlertType, i: number) => (
            <div
              className={`my-2 flex w-full flex-row justify-between p-4 hover:bg-blue3 ${
                selectedIndex === i && "bg-gray1"
              }`}
              onClick={() => {
                dispatch(setCurrentAlert(alert));
                setCreatingAlert(false);
                setSelectedIndex(i);
              }}
            >
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-primary">
                  {alert.query ? alert.query : "New document alert"}
                </div>
                <div className="font-light">
                  Filters: {getFilterString(alert.filters)}
                </div>
              </div>
              <div className="h-full self-center">
                <TrashCan
                  opacity={1}
                  handleDelete={() => {
                    console.log("delete!");
                  }}
                />
              </div>
            </div>
          ))}
        </>
      </div>
    </ResizableBox>
  );
};
