import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentSelectionId, setDocModalVisible } from "source/redux/ui";
import { RepoType } from "source/Types";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useAutoOpenFolders } from "source/hooks/repos/useAutoOpenFolders";
import { DocumentToggle } from "../multitoggle/DocumentToggle";

type Props = {
  repo: RepoType;
};

export const RepoExplorer = ({ repo }: Props) => {
  // const { filterGroups, showFilterKey } = useNeedsRepoFilters([repo.id]);
  const dispatch = useDispatch();
  const { router } = useGetRouter();
  const { handleAutoOpening } = useAutoOpenFolders();

  useEffect(() => {
    // Parse doc_ids from query string
    const { doc_ids: docIdsStr } = router.query;
    const doc_ids: string[] =
      typeof docIdsStr === "string" && docIdsStr.length > 0
        ? JSON.parse(docIdsStr)
        : [];

    // Auto-open and fetch docs in the sidebar
    doc_ids.forEach((docId) => handleAutoOpening(docId, repo.id));

    // Parse selection if there is one
    const { selection } = router.query;

    // if only one doc is selected and no query and not on /add open the doc modal
    if (
      doc_ids.length === 1 &&
      !router.query.query &&
      !router.pathname.includes("/add")
    ) {
      const docId = doc_ids[0];
      dispatch(setDocModalVisible({ docId, repoId: repo.id }));
      if (selection) dispatch(setCurrentSelectionId(selection));
    }
  }, []);

  return (
    <>
      {/* {
        // filters section
        showCustomFilters
          ? Object.keys(filterGroups).map((key) =>
              showFilterKey(key) ? (
                <FilterGroupToggle key={key} filterKey={key} />
              ) : null
            )
          : null
      } */}
      <DocumentToggle />
    </>
  );
};
