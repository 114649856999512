import {
  BackendReportFilter,
  KeyValuePair,
  ReportFilterOperation,
  ReportFilterValueType,
} from "../Types";

// Generates an initial report filter value in the same format of what is stored
// in redux if an M&M user is to enter Hebbia with their only filter being matterId=XYZ.
export const genInitialMatterFilter = (
  matterId: string
): ReportFilterValueType => ({
  matter_id: {
    key: "matter_id",
    operation: ReportFilterOperation.OR,
    values: {
      "matter_id|matter_id": {
        key: "matter_id",
        operation: ReportFilterOperation.IS,
        values: matterId,
      },
    },
  },
});

// Converts a BackendReportFilter into a list of key-value pairs of filters for
// easier identification of existence of a filter / number of applied filters
export const backendFilterToFiltersKeyValuePairs = (
  filter: BackendReportFilter | null
): KeyValuePair[] => {
  if (!filter || !filter.values) return [];

  // create a dict with keys -> sets of values
  // making the assumption that filters are nested 2 levels max
  // to avoid recursion
  const filtersDict: { [key: string]: Set<string> } = {};
  const groups = filter.values as BackendReportFilter[];
  groups.forEach((group) => {
    const rules = group.values as BackendReportFilter[];
    rules.forEach((rule) => {
      if (rule.key && rule.values) {
        if (rule.key in filtersDict) {
          filtersDict[rule.key]?.add(rule.values as string);
        } else {
          filtersDict[rule.key] = new Set([rule.values as string]);
        }
      }
    });
  });

  return Object.entries(filtersDict).flatMap(([key, values]) =>
    Array.from(values, (value) => ({
      key,
      value,
    }))
  );
};
