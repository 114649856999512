import React from "react";
import { StyleSheet } from "source/Types";
import { Layout } from "antd";
import { TopBar } from "source/components/shared/TopBar";
import { useScreenIsMobileOrTablet } from "source/hooks/responsive/useScreenIsMobile";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "../ErrorPages/ErrorPage";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import { TabBar } from "source/components/tab-bar/TabBar";
import dynamic from "next/dynamic";
import { ChatTopBar } from "source/components/chat/components/ChatTopBar";
import { ChatBottomBar } from "source/components/chat/components/ChatBottomBar";
import { Gigabar } from "source/components/gigabar";
import { DraggableSidebar } from "source/components/gigabar/DraggableSidebar";
import { FlaggedBanner } from "../Banners/FlaggedBanner";
import { ChatType } from "source/components/chat";
import { useSelector } from "react-redux";
import { getUser } from "source/redux/user";
import { ModalRenderer } from "source/components/modals/modalRenderer";
import { MobileAppLayout } from "source/components/shared/AppLayout/MobileAppLayout";
import { FooterBanner } from "../Banners/FooterBanner";
import { NotificationRegion } from "source/components/alerts/NotificationRegion";
import { AlertsSidebar } from "source/components/alerts/AlertsSidebar";

const DynamicDocViewerModal = dynamic(() =>
  import(
    "source/components/deprecated-doc-modal/DeprecatedDocViewerModal"
  ).then((mod) => mod.DeprecatedDocViewerModal)
);
const DynamicActiveBuildsModal = dynamic(() =>
  import("source/components/shared/Modals/ActiveBuildsModal").then(
    (mod) => mod.ActiveBuildsModal
  )
);
const DynamicDocStatusModal = dynamic(() =>
  import("source/components/shared/Modals/DocStatusModal").then(
    (mod) => mod.DocStatusModal
  )
);

type TopbarType = "search" | "chat" | "alerts" | "none";
type SidebarType = "files" | "chatDocs" | "alerts" | "none";

type Props = {
  children: any;
  topbarType: TopbarType;
  sidebarType: SidebarType;
  chatType?: ChatType;
  hasTabBar?: boolean;
  hasChatBottomBar?: boolean;
  isOnboarded?: boolean;
  ssrError?: boolean;
  outerContainerStyleOverrides?: StyleSheet;
  innerContainerStyleOverrides?: StyleSheet;
};

/**
 * @deprecated Legacy layout code
 */
const getTopbar = (
  topbarType: TopbarType,
  isOnboarded: boolean,
  chatType?: ChatType
) => {
  switch (topbarType) {
    case "search":
      return <TopBar isOnboarded={isOnboarded} />;
    case "chat":
      return <ChatTopBar chatType={chatType} />;
    case "alerts":
      // TODO
      // <AlertsTopBar />;
      return <></>;
    default:
      return null;
  }
};

/**
 * @deprecated Legacy layout code
 */
const getSidebar = (sidebarType: SidebarType) => {
  switch (sidebarType) {
    case "files":
      return (
        <DraggableSidebar>
          <Gigabar reposCanOpen={true} stickyHeader />
        </DraggableSidebar>
      );
    case "chatDocs":
      return (
        <DraggableSidebar>
          <Gigabar reposCanOpen={true} />
        </DraggableSidebar>
      );
    case "alerts":
      return <AlertsSidebar />;
    default:
      return <></>;
  }
};

/**
 * @deprecated Legacy app layout, use ./AppLayout for now
 */
export const AppLayout = ({
  children,
  topbarType = "search",
  sidebarType = "files",
  chatType,
  hasTabBar = true,
  hasChatBottomBar = false,
  isOnboarded = true,
  outerContainerStyleOverrides,
  innerContainerStyleOverrides,
}: Props) => {
  const isMobile = useScreenIsMobileOrTablet();

  // Some customers have requested to hide the sidebar
  const user = useSelector(getUser);

  const hideSidebar =
    useFeatureFlag("hideSidebar") ||
    user?.email?.toLocaleLowerCase().includes("forthepeople.com");

  if (isMobile) {
    return <MobileAppLayout />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Layout hasSider={hasTabBar}>
        {hasTabBar ? <TabBar /> : null}
        {!hideSidebar && getSidebar(sidebarType)}
        <Layout
          style={{
            ...(outerContainerStyleOverrides
              ? outerContainerStyleOverrides.layout
              : {}),
          }}
        >
          <FlaggedBanner />
          {getTopbar(topbarType, isOnboarded, chatType)}
          <Layout
            style={{
              ...styles.layout,
              ...(innerContainerStyleOverrides
                ? innerContainerStyleOverrides.layout
                : {}),
            }}
          >
            {children}
          </Layout>
          {hasChatBottomBar ? <ChatBottomBar /> : null}
        </Layout>
        <DynamicDocViewerModal />
        <DynamicActiveBuildsModal />
        <DynamicDocStatusModal />
        <NotificationRegion />
        <ModalRenderer />
        <FooterBanner />
      </Layout>
    </ErrorBoundary>
  );
};

const styles: StyleSheet = {
  layout: {
    boxShadow: "-7px 0 0px -7px rgba(0, 0, 0, 0.2)",
    background: "var(--background)",
    zIndex: 1,
    overflowX: "hidden",
  },
};
