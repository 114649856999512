import React from "react";
import Link from "next/link";
import { classNames } from "core";

type Props = {
  icon: React.ReactNode;
  page: string;
  isSelected?: boolean;
  location: string;
  onClick: (nextLocation: string) => void;
};

export const Tab = ({ icon, page, location, isSelected, onClick }: Props) => {
  return (
    <Link
      shallow
      href={location}
      className="group flex w-full cursor-pointer flex-col items-center gap-1.5 p-2"
      onClick={() => onClick(location)}
    >
      <div
        className={classNames(
          "flex h-9 w-9 items-center justify-center rounded-lg p-2",
          isSelected && "bg-brand-200 text-brand-500 group-hover:bg-brand-200",
          !isSelected && "text-gray-500 group-hover:text-brand-500"
        )}
      >
        {icon}
      </div>
      <div
        className={classNames(
          "text-[11px] font-medium leading-3",
          isSelected && "text-brand-500",
          !isSelected && "text-gray-500 group-hover:text-brand-500"
        )}
      >
        {page}
      </div>
    </Link>
  );
};
