import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import { useState } from "react";
import { SidebarInput, SidebarInputKeywordBoldingProps } from "./SidebarInput";
import { DOC_TAB_WIDTH } from "source/constants";
import React from "react";
import { Tooltip } from "source/components/shared/Tooltip";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import { MenuIcon } from "source/components/shared/Icons/MenuIcon";
import { pluralize } from "source/utils/common/strings";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { CircularProgress } from "@mui/material";
import { useGetRouter } from "source/hooks/useGetRouter";
import { getCurrentPage } from "source/utils/helpers";

interface SelectableRowProps {
  badge?: React.ReactNode;
  /**
   * whether to highlight the row and show checkbox as checked.
   */
  isActive: boolean;
  siblingActive: boolean;
  handleCheckboxSelect?: (e: React.MouseEvent) => void;
  handleRowClick?: (e: React.MouseEvent) => void;
  handleRowDoubleClick?: (e: React.MouseEvent) => void;
  Icon: React.ReactNode;
  EndIcon?: JSX.Element;
  id: string;
  title: string;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  isHovering: boolean;
  handleRename?:
    | ((newTitle: string) => Promise<void>)
    | ((newTitle: string) => void);
  isBuilding?: boolean;
  rowTooltipOverrideTitle?: string;
  checkboxTooltipOverrideTitle?: string;
  depth?: number;
  showGoArrow: boolean;
  showContextMenu: boolean;
  resultCount?: number;
  keywordBolding?: SidebarInputKeywordBoldingProps;
  hideAddIcon?: boolean;
  classNameOverride?: string;
  disabled?: boolean;
}

/**
 * SelectableRow is an abstraction around a sidebar row that can be selected, often edited, etc.
 * E.g. a document row or repo row.
 *
 * In contrast, SidebarRow is a "proper" React component that is highly flexible and is used to render
 * many types of things that show up in the sidebar -- from "Add folder" button to the repo title
 * on the repo page.
 */
export const SelectableRow = ({
  badge,
  disabled,
  isActive,
  siblingActive,
  handleCheckboxSelect,
  handleRowClick,
  handleRowDoubleClick,
  Icon,
  id,
  title,
  EndIcon,
  isHovering,
  isEditing,
  setIsEditing,
  keywordBolding,
  handleRename,
  rowTooltipOverrideTitle,
  checkboxTooltipOverrideTitle,
  depth,
  showGoArrow,
  showContextMenu,
  resultCount,
  hideAddIcon,
  classNameOverride,
}: SelectableRowProps) => {
  const [checkBoxHovering, setCheckBoxHovering] = useState(false);
  const [rowHovering, setRowHovering] = useState(false);
  const rowTooltipDelay = 400;

  // TMP
  const { router } = useGetRouter();
  const page = getCurrentPage(router.pathname);

  const titleTooltip = disabled
    ? "Source is uploading"
    : rowTooltipOverrideTitle
      ? rowTooltipOverrideTitle
      : page === "ChatDocs"
        ? "Add Filter"
        : handleRowClick
          ? "Go to source"
          : "";

  // adding in text underlines to indicate that it's a link that will
  // trigger navigation; we use the titleTooltip to determine whether that
  // is the case
  const shouldUnderlineRow =
    !disabled && rowHovering && titleTooltip === "Go to source";

  return (
    <div
      className={`flex h-full w-full items-center justify-between pr-1 ${classNameOverride} ${
        disabled ? "cursor-default" : ""
      }`}
      style={{
        paddingLeft: 12 + (depth || 0) * DOC_TAB_WIDTH,
      }}
      onClick={(e) => {
        if (disabled) {
          return;
        }
        if (handleRowClick) handleRowClick(e);
        else if (handleCheckboxSelect) handleCheckboxSelect(e);
      }}
    >
      <div className="flex flex-1 items-center">
        <Tooltip
          title={
            disabled
              ? "Source is uploading"
              : checkboxTooltipOverrideTitle
                ? checkboxTooltipOverrideTitle
                : isActive
                  ? "Remove filter"
                  : "Add filter"
          }
          enterDelay={rowTooltipDelay}
          enterNextDelay={rowTooltipDelay}
          styleOverride={{ color: "white" }}
        >
          <div
            className={`flex ${
              disabled
                ? "ml-[2px] w-5 cursor-default"
                : "mr-2 w-0 cursor-pointer"
            } items-center`}
          >
            {disabled ? (
              <CircularProgress className="text-checkIcon" size="1.25rem" />
            ) : isActive ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleCheckboxSelect && handleCheckboxSelect(e);
                }}
              >
                <CheckBoxSharpIcon
                  onMouseLeave={() => setCheckBoxHovering(false)}
                  className="text-checkIconActive"
                />
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleCheckboxSelect && handleCheckboxSelect(e);
                }}
                onMouseOver={() => setCheckBoxHovering(true)}
                onMouseLeave={() => setCheckBoxHovering(false)}
              >
                <div className="pointer-events-none">
                  {siblingActive && !hideAddIcon ? ( // sibling is active and row isn't
                    checkBoxHovering ? (
                      <AddSharpIcon className="text-bluePurple" />
                    ) : (
                      <AddSharpIcon className="text-checkIcon" />
                    )
                  ) : checkBoxHovering ? (
                    <CheckBoxSharpIcon className="text-checkIcon active:text-checkIconActive" />
                  ) : (
                    <CheckBoxOutlineBlankSharpIcon className="text-checkIcon active:text-checkIconActive" />
                  )}
                </div>
              </div>
            )}
          </div>
        </Tooltip>
        <div
          className="ml-6 flex flex-1"
          onMouseEnter={() => setRowHovering(true)}
          onMouseLeave={() => setRowHovering(false)}
        >
          <span
            className={`mr-1 ${disabled ? "ml-2" : "ml-0"} flex items-center`}
          >
            {Icon}
          </span>
          <Tooltip
            title={titleTooltip}
            enterDelay={rowTooltipDelay}
            enterNextDelay={rowTooltipDelay}
          >
            {resultCount && resultCount > 0 ? (
              <div className="flex h-6 w-0 grow">
                <div className="w-fit">
                  <SidebarInput
                    id={id}
                    title={title}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    handleDoneEditing={handleRename}
                    {...keywordBolding}
                    styleOverride={{
                      width: "100%",
                      textAlign: "start",
                    }}
                  />
                </div>
                <span className="flex items-center overflow-hidden overflow-ellipsis text-center text-tertiary">{`${resultCount} ${pluralize(
                  resultCount,
                  "Result"
                )}`}</span>
              </div>
            ) : (
              <div className="flex h-6 w-0 grow items-center justify-center">
                <SidebarInput
                  id={id}
                  title={title}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  handleDoneEditing={handleRename}
                  {...keywordBolding}
                  styleOverride={{
                    width: "100%",
                    textAlign: "start",
                    height: "fit-content",
                    textDecoration: shouldUnderlineRow ? "underline" : "none",
                  }}
                />
              </div>
            )}
          </Tooltip>
        </div>
      </div>
      <div className="flex items-center justify-end pr-[0.5px]">
        {isHovering && !checkBoxHovering ? (
          <span
            className="flex pr-1 text-checkIcon"
            onMouseOver={() => setRowHovering(false)}
          >
            {showContextMenu && (
              <Tooltip
                enterDelay={rowTooltipDelay}
                enterNextDelay={rowTooltipDelay}
                title="Delete, rename, and more..."
              >
                <span className="mr-0.5">
                  <MenuIcon isActive={isActive} />
                </span>
              </Tooltip>
            )}
          </span>
        ) : (
          (EndIcon ?? null)
        )}
        {(isHovering || isActive) && showGoArrow && (
          <Tooltip
            title="Go to source"
            enterDelay={rowTooltipDelay}
            enterNextDelay={rowTooltipDelay}
          >
            <div
              className={`mr-1 cursor-pointer select-none text-center ${
                rowHovering ? "text-bluePurple" : "text-checkIcon"
              }`}
              onClick={handleRowClick}
              onMouseOver={() => setRowHovering(true)}
              onMouseLeave={() => setRowHovering(false)}
            >
              <ArrowForwardSharpIcon sx={{ fontSize: "32px" }} />
            </div>
          </Tooltip>
        )}
        {badge ?? null}
      </div>
    </div>
  );
};
