import $ from "jquery";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { areEqual, removeStopwords } from "source/utils/helpers";

type Props = {
  id: string;
  title: string;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  handleDoneEditing?: (newTitle: string) => void;
  styleOverride?: React.CSSProperties;
};

export type SidebarInputKeywordBoldingProps = {
  filterTitle?: string;
  shouldKeywordBold?: boolean;
  keywordBoldStrictMatch?: boolean;
};

export const SidebarInput = React.memo(
  ({
    id,
    title,
    handleDoneEditing,
    styleOverride,
    shouldKeywordBold,
    keywordBoldStrictMatch,
    setIsEditing,
    filterTitle,
  }: Props & SidebarInputKeywordBoldingProps) => {
    const [currTitle, setCurrTitle] = useState<string>(title);
    const keywords = !filterTitle
      ? []
      : keywordBoldStrictMatch
        ? [filterTitle]
        : removeStopwords(filterTitle).split(" ");

    useEffect(() => {
      setCurrTitle(title);
    }, [title]);

    const handleEdit = () => {
      $(`#sidebar-${id}`).attr("disabled", true as any);
      if (handleDoneEditing) handleDoneEditing(currTitle);
      if (setIsEditing) setIsEditing(false);
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") (document.activeElement as any)?.blur();
    };

    const keywordBold = (
      text: string,
      keywords: string[]
    ): React.ReactElement => {
      if (!filterTitle) return <>{text}</>;
      const textLower = text.toLowerCase();

      // sort keywords by decreasing length to prioritize longer keywords
      const sortedKeywords = keywords.sort((a, b) => b.length - a.length);

      const keywordMatch: string | undefined = sortedKeywords.find(
        (keyword) => textLower.indexOf(keyword.toLowerCase()) > -1
      );
      const keywordMatchIdx: number = keywordMatch
        ? textLower.indexOf(keywordMatch.toLowerCase())
        : -1;
      return keywordMatch ? (
        <>
          {keywordBold(text.slice(0, keywordMatchIdx), sortedKeywords)}
          <b className="text-secondary">
            {text.slice(keywordMatchIdx, keywordMatchIdx + keywordMatch.length)}
          </b>
          {keywordBold(
            text.slice(keywordMatchIdx + keywordMatch.length, text.length),
            sortedKeywords
          )}
        </>
      ) : (
        <>{text}</>
      );
    };

    return shouldKeywordBold ? (
      <div
        className="mr-[10px] block overflow-hidden overflow-ellipsis whitespace-nowrap pt-[3px] text-secondary"
        style={{
          ...styleOverride,
        }}
      >
        {keywordBold(currTitle, keywords)}
      </div>
    ) : (
      <InputRow
        id={`sidebar-${id}`}
        value={currTitle}
        onChange={(e) => setCurrTitle(e.target.value)}
        onBlur={handleEdit}
        onKeyPress={handleKeyPress}
        cursor={"pointer"}
        style={{ ...styleOverride }}
        disabled
      />
    );
  },
  areEqual
);

const InputRow = styled("input")<{ cursor: string }>`
  all: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  cursor: ${(props) => props.cursor};
  user-select: none;
  -webkit-text-fill-color: var(--text-secondary);
  -webkit-user-select: none;
  -moz-user-select: none;
  margin-right: 10px;
  pointer-events: none;
`;
