export const getBannerHeader = (
  currentPage: string,
  bannerBuildSlow?: boolean
) => {
  if (bannerBuildSlow) {
    return "Expect longer upload times";
  }

  return `${currentPage} temporarily affected`;
};

export const getBannerMessage = (
  currentPage: string,
  bannerBuildSlow?: boolean
) => {
  if (bannerBuildSlow) {
    return "Delayed upload times due to high volume of docs in queue. Need assistance?";
  }

  return `${currentPage} impacted by vendor outages. Fix in progress. Expect delays & limited results. Need assistance?`;
};
