import React, { useEffect, useRef, KeyboardEvent } from "react";
import { Box } from "@mui/system";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import LinearProgress from "@mui/material/LinearProgress";
import SearchSharp from "@mui/icons-material/SearchSharp";
import { CommandText } from "source/components/shared/Buttons/CommandText";
import { TooltipHotKey } from "source/components/shared/Buttons/TooltipHotKey";
import { SearchAgain } from "source/components/shared/SearchPage/SearchAgain";
import { useAdminMode } from "source/hooks/useAdminMode";
import { useSelector } from "react-redux";
import { getPM } from "source/redux/ui";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

interface Props {
  handleKeyDown: (e: KeyboardEvent) => void;
  searchDisabled?: boolean;
  onSearchTagClick?: () => void;
  showSearchInput: boolean;
  inputPlaceholder: string;
  inputQuery: string;
  inputValueOnChange: (e) => void;
  inputId: string;
  onInputFocus: () => void;
  onInputBlur: () => void;
  shouldShowCommandText: boolean;
  tooltipOnClick: () => void;
  showProgress?: boolean;
  progressValue?: number;
  progressColor?: string;
  numRepoTargets?: number;
  shouldStartFocused: boolean;
  overrideStyles?: React.CSSProperties;
}

export const SearchInputBox = ({
  handleKeyDown,
  searchDisabled,
  onSearchTagClick,
  showSearchInput,
  inputPlaceholder,
  inputQuery,
  inputValueOnChange,
  inputId,
  onInputFocus,
  onInputBlur,
  shouldShowCommandText,
  tooltipOnClick,
  showProgress,
  progressValue,
  progressColor,
  numRepoTargets,
  shouldStartFocused,
  overrideStyles,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { hebbiaAdmin } = useAdminMode();
  const pm = useSelector(getPM);
  const canParty = useFeatureFlag("canParty");

  useEffect(() => {
    if (!shouldStartFocused) return;
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (!numRepoTargets) return;
    inputRef.current?.focus();
  }, [numRepoTargets]);

  const tooltipOnClickWrapper = () => {
    tooltipOnClick();
    inputRef.current?.focus();
  };

  return (
    <div
      className={`${
        hebbiaAdmin && pm && canParty ? "animated" : ""
      } mt-0 h-[53px] w-full overflow-hidden rounded-none bg-card text-primary`}
      style={{
        boxShadow:
          "0px 2px 4px 0px rgba(var(--shadow), 1), 0px 2px 21px -7px rgba(var(--shadow), 1)",
        ...overrideStyles,
      }}
    >
      <div
        className="flex h-full flex-row items-center justify-between"
        style={{
          backgroundColor: searchDisabled
            ? "rgba(198, 203, 222, 0.2)"
            : undefined,
        }}
      >
        <div className="flex h-full w-full flex-row items-center justify-start">
          <span
            className="ml-4 mr-2 cursor-default pl-0.5 text-bluePurple"
            onClick={onSearchTagClick}
          >
            <SearchSharp fontSize="medium" />
          </span>
          {showSearchInput && (
            <>
              <input
                className={`${
                  hebbiaAdmin && pm && canParty ? "animated" : ""
                } w-full border-none bg-card py-3.5 pl-2 font-['Inter'] text-sm font-normal outline-none placeholder:text-light`}
                ref={inputRef}
                placeholder={inputPlaceholder}
                value={inputQuery}
                onChange={inputValueOnChange}
                style={{
                  backgroundColor: searchDisabled ? "transparent" : undefined,
                }}
                type="search"
                id={inputId}
                data-testid={inputId}
                disabled={searchDisabled}
                autoComplete="off"
                onKeyDown={handleKeyDown}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
              />
              {shouldShowCommandText && (
                <div style={{ marginRight: "5px" }}>
                  <CommandText
                    commands={["/"]}
                    styleOverride={{ pointerEvents: "none" }}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {!searchDisabled && <SearchAgain />}
        {!!inputQuery && (
          <TooltipHotKey commands={["esc"]}>
            <span
              className="mr-2 cursor-pointer p-2 text-tertiary hover:bg-hoverIcon active:bg-hoverIconActive"
              onClick={tooltipOnClickWrapper}
            >
              <CloseSharpIcon fontSize="medium" />
            </span>
          </TooltipHotKey>
        )}
      </div>
      {showProgress && (
        <Box sx={{ color: progressColor }}>
          <LinearProgress
            variant={progressValue !== undefined ? "determinate" : undefined}
            value={progressValue ?? undefined}
            color={"inherit"}
            style={{ height: 3, marginTop: "-2px" }}
          />
        </Box>
      )}
    </div>
  );
};
