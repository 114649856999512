import {
  FilterType,
  SortKey,
  SynonymAppliedType,
  TaskType,
} from "source/Types";
import { SearchResponseType } from "source/redux/search";

// Search websocket events and payloads
export enum SearchWebsocketEvents {
  SEARCH_SYNONYMS = "search_synonyms",
  QUERY_EXPANSION = "query_expansion",
  SEC_FINANCIALS = "sec_financials",
  SEARCH_ERROR = "search_error",
  KEYWORD_SEARCH_ERROR = "keyword_search_error",
  SEARCH_RESPONSE = "search_response",
  KEYWORD_SEARCH_RESPONSE = "keyword_search_response",
  ANSWER_CITATIONS = "answer_citations",
  KEYWORD_ANSWER_CITATIONS = "keyword_answer_citations",
  SUMMARIES = "summaries",
  KEYWORD_SUMMARIES = "keyword_summaries",
  PLAIN_ANSWER = "plain_answer",
  ANSWER = "answer",
  KEYWORD_ANSWER = "keyword_answer",
  ANSWER_FINISH = "answer_finish",
  KEYWORD_ANSWER_FINISH = "keyword_answer_finish",
  CHECK_ANSWER = "check_answer",
  SUGGESTED_QUESTION = "suggested_question",
  TOGGLE_KEYWORD = "toggle_keyword",
  APPLIED_DATE = "applied_date",
  SORT_KEY = "sort_key",
  APPLIED_FILTERS = "applied_filters",
  SUGGESTED_FILTERS = "suggested_filters",
  COMPLETE = "complete",
  HYDE_OUTPUT = "hyde_output",
  TASK = "task",
}

type SearchSynonymsMessage = {
  event: SearchWebsocketEvents.SEARCH_SYNONYMS;
  payload: SynonymAppliedType;
};

type QueryExpansionMessage = {
  event: SearchWebsocketEvents.QUERY_EXPANSION;
  payload: string;
};

type SECFinancialsMessage = {
  event: SearchWebsocketEvents.SEC_FINANCIALS;
  payload: {
    financials: Record<string, string>;
    doc_id: string | null;
  };
};

type SearchErrorMessage = {
  event: SearchWebsocketEvents.SEARCH_ERROR;
  payload: string;
};

type KeywordSearchErrorMessage = {
  event: SearchWebsocketEvents.KEYWORD_SEARCH_ERROR;
  payload: string;
};

type SearchReponseMessage = {
  event: SearchWebsocketEvents.SEARCH_RESPONSE;
  payload: SearchResponseType;
};

type KeywordSearchReponseMessage = {
  event: SearchWebsocketEvents.KEYWORD_SEARCH_RESPONSE;
  payload: SearchResponseType;
};

type AnswerCitationsMessage = {
  event: SearchWebsocketEvents.ANSWER_CITATIONS;
  payload: string[];
};

type KeywordAnswerCitationsMessage = {
  event: SearchWebsocketEvents.KEYWORD_ANSWER_CITATIONS;
  payload: string[];
};

type SummariesMessages = {
  event: SearchWebsocketEvents.SUMMARIES;
  payload: Record<string, string>;
};

type KeywordSummariesMessage = {
  event: SearchWebsocketEvents.KEYWORD_SUMMARIES;
  payload: Record<string, string>;
};

type PlainAnswerMessage = {
  event: SearchWebsocketEvents.PLAIN_ANSWER;
  payload: string;
};

type AnswerMessage = {
  event: SearchWebsocketEvents.ANSWER;
  payload:
    | {
        content: string;
        degraded: boolean;
      }
    | string;
};

type KeywordAnswerMessage = {
  event: SearchWebsocketEvents.KEYWORD_ANSWER;
  payload:
    | {
        content: string;
        degraded: boolean;
      }
    | string;
};

type AnswerFinishMessage = {
  event: SearchWebsocketEvents.ANSWER_FINISH;
  payload: null;
};

type KeywordAnswerFinishMessage = {
  event: SearchWebsocketEvents.KEYWORD_ANSWER_FINISH;
  payload: null;
};

type CheckAnswerMessage = {
  event: SearchWebsocketEvents.CHECK_ANSWER;
  payload: boolean;
};

type SuggestedQuestionMessage = {
  event: SearchWebsocketEvents.SUGGESTED_QUESTION;
  payload: string;
};

type ToggleKeywordMessage = {
  event: SearchWebsocketEvents.TOGGLE_KEYWORD;
  payload: boolean;
};

type AppliedDateMessage = {
  event: SearchWebsocketEvents.APPLIED_DATE;
  payload: FilterType[];
};

type SortKeyMessage = {
  event: SearchWebsocketEvents.SORT_KEY;
  payload: SortKey;
};

type AppliedFiltersMessage = {
  event: SearchWebsocketEvents.APPLIED_FILTERS;
  payload: FilterType[];
};

type SuggestedFiltersMessage = {
  event: SearchWebsocketEvents.SUGGESTED_FILTERS;
  payload: FilterType[];
};

type CompleteMessage = {
  event: SearchWebsocketEvents.COMPLETE;
  payload: null;
};

type HydeOutputMessage = {
  event: SearchWebsocketEvents.HYDE_OUTPUT;
  payload: string;
};

type TaskMessage = {
  event: SearchWebsocketEvents.TASK;
  payload: TaskType;
};

// All possible message event & payload types
export type SearchWebsocketResponse =
  | SearchSynonymsMessage
  | QueryExpansionMessage
  | SECFinancialsMessage
  | SearchErrorMessage
  | KeywordSearchErrorMessage
  | SearchReponseMessage
  | KeywordSearchReponseMessage
  | AnswerCitationsMessage
  | KeywordAnswerCitationsMessage
  | SummariesMessages
  | KeywordSummariesMessage
  | PlainAnswerMessage
  | AnswerMessage
  | KeywordAnswerMessage
  | AnswerFinishMessage
  | KeywordAnswerFinishMessage
  | CheckAnswerMessage
  | SuggestedQuestionMessage
  | ToggleKeywordMessage
  | AppliedDateMessage
  | SortKeyMessage
  | AppliedFiltersMessage
  | SuggestedFiltersMessage
  | CompleteMessage
  | HydeOutputMessage
  | TaskMessage;
