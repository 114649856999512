import React from "react";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getKeywordSearchActive,
  setKeywordSearchActive,
} from "source/redux/search";
import { Tooltip } from "source/components/shared/Tooltip";

export const KeywordToggle = () => {
  const dispatch = useDispatch();
  const keywordResultsActive = useSelector(getKeywordSearchActive);
  const handleToggle = () =>
    dispatch(setKeywordSearchActive(!keywordResultsActive));
  return (
    <Tooltip
      title={"Limit your search to keyword matches only"}
      enterDelay={400}
      enterNextDelay={400}
    >
      <div className="mr-5 text-sm font-medium text-hebbiblue">
        <Switch
          checked={keywordResultsActive}
          onChange={handleToggle}
          size="small"
          style={{
            backgroundColor: keywordResultsActive
              ? "var(--text-blue)"
              : "var(--text-light)",
          }}
        />
        <span className="ml-2">Keywords only</span>
      </div>
    </Tooltip>
  );
};
