import { Message, SearchParams, TaskType } from "source/Types";
import { DocCountParams } from "source/api/filter/filters";

// Chat events
export enum ChatWebsocketEvents {
  MESSAGE_TOKEN = "message_token",
  MESSAGE_FINAL = "message_final",
  ERROR = "error",
}

type MessageTokenMessage = {
  event: ChatWebsocketEvents.MESSAGE_TOKEN;
  payload: Message[];
};

type MessageFinalMessage = {
  event: ChatWebsocketEvents.MESSAGE_FINAL;
  payload: Message[];
};

type ChatErrorMessage = {
  event: ChatWebsocketEvents.ERROR;
  payload: Message[];
};

// Chat plus events
export enum ChatPlusWebsocketEvents {
  CHAT_CITATION_RESULT_IDS = "chat_citation_result_ids",
  SESSION_ID = "session_id",
  CHAT_PLUS_CP_MESSAGE = "cp_message",
  TASK = "task",
  FILTER_TOOL_SEARCH_PARAMS = "filter_tool_search_params",
  DONE = "done",
}

type SessionIdMessage = {
  event: ChatPlusWebsocketEvents.SESSION_ID;
  payload: string;
};

type CitationReponseType = {
  resultIds: string[];
  messageId: string;
  taskId: string;
};

type CitationResultIdsMessage = {
  event: ChatPlusWebsocketEvents.CHAT_CITATION_RESULT_IDS;
  payload: CitationReponseType;
};

type ChatPlusCPMessage = {
  event: ChatPlusWebsocketEvents.CHAT_PLUS_CP_MESSAGE;
  payload: Message;
};

type ChatPlusTaskMessage = {
  event: ChatPlusWebsocketEvents.TASK;
  payload: TaskType;
};

type ChatPlusFilterToolSearchParamsMessage = {
  event: ChatPlusWebsocketEvents.FILTER_TOOL_SEARCH_PARAMS;
  payload: DocCountParams;
};

type DoneMessage = {
  event: ChatPlusWebsocketEvents.DONE;
  payload: null;
};

// All possible message event & payload types
export type ChatWebsocketResponse =
  | MessageTokenMessage
  | MessageFinalMessage
  | ChatErrorMessage
  | SessionIdMessage
  | CitationResultIdsMessage
  | ChatPlusCPMessage
  | ChatPlusTaskMessage
  | ChatPlusFilterToolSearchParamsMessage
  | DoneMessage;

export type ChatWebsocketInitializationPayload = {
  session_id: string | null;
  search_params: SearchParams;
};
export type ChatMessageNoId = Partial<Message>[];
export type ChatPlusMessage = {
  content: string;
  search_params: SearchParams;
};

export type ChatWebsocketPayload =
  | ChatMessageNoId
  | ChatWebsocketInitializationPayload
  | ChatPlusMessage;
