import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSidebarRowsOpen,
  upsertSidebarRowsOpen,
} from "source/redux/sidebar";

export type Props = {
  id: string;
  onToggle?: (toOpen: boolean) => void;
  defaultOpen?: boolean;
};

export const useSidebarRow = ({ id, defaultOpen, onToggle }: Props) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getSidebarRowsOpen)[id];

  useEffect(() => {
    if (defaultOpen || isOpen) setOpen(true);
  }, []);

  const toggle = () => {
    setOpen(!isOpen);
    if (onToggle) onToggle(!isOpen);
  };

  const setOpen = (open: boolean) => {
    const payload = {};
    payload[id] = open;
    dispatch(upsertSidebarRowsOpen(payload));
  };

  return { isOpen, toggle };
};
