import PostAddSharpIcon from "@mui/icons-material/PostAddSharp";
import { BottomAddButton } from "source/components/sidebar/components/BottomAddButton";
import { useSelector } from "react-redux";
import { useCreateRepoMutation } from "source/api/repos/useQueryRepos";
import React from "react";
import { DEFAULT_REPO_NAME, SILVER_LAKE_ORG_ID } from "source/constants";
import { usePublicRepo } from "source/hooks/permissions/useCanEditRepo";
import { useCurrentDoc } from "source/hooks/doc-viewer/useCurrentDoc";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useOrgRole } from "source/hooks/permissions/useOrgRole";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { getCurrentOrg } from "source/redux/organization";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { getCurrentPage } from "source/utils/helpers";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

export const BottomButton = () => {
  const { router } = useGetRouter();
  const org = useSelector(getCurrentOrg);
  const currentRepo = useCurrentRepo(true);
  const currentDoc = useCurrentDoc();
  const isPublicRepo = usePublicRepo(currentRepo);
  const { goToRepoAddDocs } = useGlobalNavigator();
  const createRepoMutation = useCreateRepoMutation();
  const page = getCurrentPage(router.pathname);
  const disablePersonalUploads = useFeatureFlag("disablePersonalUploads");

  const showButton =
    ["ChatDocs", "Search"].includes(page) && !disablePersonalUploads;

  const orgRole = useOrgRole();

  if (!showButton) return null;

  return (
    <div>
      {router.pathname.includes("/repos/") ? (
        isPublicRepo ||
        router.pathname.includes("/add") ||
        currentRepo?.integrations?.findox ||
        currentRepo?.role === "viewer" ? null : (
          <BottomAddButton
            text="New Doc"
            Icon={<PostAddSharpIcon style={{ height: 20, width: 20 }} />}
            handleClick={() => {
              if (currentRepo) goToRepoAddDocs(currentRepo.id, currentDoc?.id);
            }}
          />
        )
      ) : (
        (org?.id !== SILVER_LAKE_ORG_ID || orgRole === "admin") && (
          <BottomAddButton
            text="New folder"
            Icon={
              <CreateNewFolderOutlinedIcon style={{ height: 20, width: 20 }} />
            }
            handleClick={() => {
              const payload = {
                name: DEFAULT_REPO_NAME,
                is_private: true,
                organization_id: undefined,
              };
              createRepoMutation.mutate(payload);
            }}
          />
        )
      )}
    </div>
  );
};
