import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import MoreHorizIcon from "@mui/icons-material/MoreVert";
import { RepoIcon } from "source/components/shared/Icons/RepoIcon";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateRepoMutation } from "source/api/repos/useQueryRepos";
import { openContextMenu } from "source/utils/helpers";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { RepoContextMenu } from "../../RepoContextMenu";
import { SidebarInput } from "../../SidebarInput";
import {
  getTargetDocs,
  getTargetNonPredefinedFilters,
} from "source/redux/advanced";

export const AdvancedFiltersCard = () => {
  const [isHovering, setHovering] = useState(false);

  const filterTargets = useSelector(getTargetNonPredefinedFilters);
  const docTargets = useSelector(getTargetDocs);
  const totalTargetCount = docTargets.length + filterTargets.length;

  const updateRepoMutation = useUpdateRepoMutation();

  const currentRepo = useCurrentRepo(true);
  const { goToRepo, goToPage } = useGlobalNavigator();

  const onRepoTagClick = (e) => {
    e.stopPropagation();
    // Clears search state and goes to /repo
    if (currentRepo) goToRepo(currentRepo.id);
  };

  const getCurrentRepoTag = () => {
    if (!currentRepo) return <></>;

    return (
      <RepoContextMenu
        repo={currentRepo}
        styleOverride={{
          width: totalTargetCount === 0 ? "100%" : undefined,
          paddingRight: totalTargetCount === 0 ? 12 : undefined,
        }}
      >
        <div
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          className={`${
            totalTargetCount === 0 ? "w-full" : "w-[204px]"
          } text-md mb-2 ml-3 mr-2 mt-3 flex h-8 cursor-pointer flex-row items-center justify-between bg-lightPurple/20 px-1 font-normal text-secondary`}
          style={{
            maxWidth: totalTargetCount === 0 ? "calc(100% - 12px)" : "",
          }}
          onDoubleClick={onRepoTagClick}
        >
          <div
            className="flex grow flex-row items-center"
            style={{
              width: totalTargetCount > 0 ? "calc(100% - 52px)" : undefined,
            }}
          >
            <RepoIcon repo={currentRepo} fontSize="small" />
            <SidebarInput
              id={currentRepo.id}
              title={currentRepo?.name}
              handleDoneEditing={(newTitle) => {
                const payload = {
                  repoId: currentRepo.id,
                  body: {
                    name: newTitle,
                  },
                };
                updateRepoMutation.mutate(payload);
              }}
              styleOverride={{
                fontSize: 14,
                marginLeft: "8px",
                marginRight: 4,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "calc(100% - 40px)",
              }}
            />
            <span className="mr-2">
              {totalTargetCount > 0 ? `+${totalTargetCount}` : ""}
            </span>
          </div>
          <div className="flex flex-row">
            <div
              onClick={openContextMenu}
              id="repo-header-icon"
              className="my-1 ml-[-4px] mr-2 w-3 cursor-pointer hover:text-bluePurple"
            >
              {isHovering ? <MoreHorizIcon className="cursor-pointer" /> : null}
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                goToPage("/chat-docs");
              }}
              id="repo-header-icon"
              className="flex cursor-pointer items-center justify-center hover:text-bluePurple"
            >
              <CloseSharpIcon fontSize="small" />
            </div>
          </div>
        </div>
      </RepoContextMenu>
    );
  };

  return (
    <div className="mt-3 flex w-full flex-row flex-nowrap border-b">
      {getCurrentRepoTag()}
    </div>
  );
};
