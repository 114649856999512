import _ from "lodash";
import { SearchParams } from "source/Types";
import { QueryParams } from "./useGetRouter";

export const buildStringifiedQuery = (
  searchParams: SearchParams
): QueryParams => {
  const { protected_user_selected_repos, ...cleanedSearchParams } =
    searchParams;

  const query: QueryParams = {
    ...cleanedSearchParams,
    filters: JSON.stringify(searchParams.filters),
    repo_ids: JSON.stringify(searchParams.repo_ids),
    doc_ids: JSON.stringify(searchParams.doc_ids),
  } as QueryParams;

  if (searchParams.repo_ids?.length)
    query["repo_ids"] = JSON.stringify(searchParams.repo_ids);
  if (searchParams.doc_ids?.length)
    query["doc_ids"] = JSON.stringify(searchParams.doc_ids);

  const [repoFilters, nonRepoFilters] = _.partition<any>(
    searchParams.filters,
    (f) => f.key === "repo_id"
  );

  if (nonRepoFilters.length) {
    query["filters"] = JSON.stringify(nonRepoFilters);
  }
  return query;
};
