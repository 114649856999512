import React, { useState } from "react";

import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import BoltSharpIcon from "@mui/icons-material/BoltSharp";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { ChatPerformanceType } from "source/Types";
import { useSelector } from "react-redux";
import {
  getChatMessages,
  getChatPerformance,
  resetChatSession,
} from "source/redux/chat";
import { useDispatch } from "react-redux";
import { useSearchStateManager } from "source/hooks/useSetRouter";
import { Modal } from "source/components/shared/Modals/Modal";
import { Button } from "source/components/settings/components/ui";

export const TogglePerformance = () => {
  const dispatch = useDispatch();
  const performanceType = useSelector(getChatPerformance);
  const { clearUserTargetState } = useSearchStateManager();
  const messages = useSelector(getChatMessages);
  const [modalVisible, setModalVisible] = useState(false);
  const [stagedNewPerformanceType, setStagedNewPerformanceType] =
    useState<ChatPerformanceType>();

  const changePerformanceTypeAndClear = (
    newChatPerformanceType: ChatPerformanceType
  ) => {
    if (newChatPerformanceType === performanceType) return;

    dispatch(resetChatSession({ chatPerformanceType: newChatPerformanceType }));
    clearUserTargetState();
  };

  const onChangePerformanceType = (
    newChatPerformanceType: ChatPerformanceType
  ) => {
    if (newChatPerformanceType === performanceType) return;

    if (messages.length > 1) {
      setModalVisible(true);
      setStagedNewPerformanceType(newChatPerformanceType);
    } else {
      changePerformanceTypeAndClear(newChatPerformanceType);
    }
  };

  return (
    <div className="flex w-full flex-col content-center items-center justify-center bg-card text-center text-lightBlack">
      <div className="mb-3 flex flex-col content-center items-center text-center">
        Choose performance
      </div>
      <div className="flex w-full flex-row items-center justify-center bg-gray05 px-0.5 py-0.5 text-center text-lightBlack">
        {/* TODO: @shirley use MODEL_SPEEDS */}
        <ToggleButton
          onClick={() => onChangePerformanceType("faster")}
          label="Faster"
          active={performanceType === "faster"}
          icon={
            performanceType === "faster" ? (
              <BoltSharpIcon fontSize="small" />
            ) : (
              <BoltOutlinedIcon fontSize="small" />
            )
          }
        />
        <ToggleButton
          onClick={() => onChangePerformanceType("accurate")}
          label="More accurate"
          active={performanceType === "accurate"}
          icon={
            performanceType === "accurate" ? (
              <AutoAwesomeSharpIcon fontSize="small" />
            ) : (
              <AutoAwesomeOutlinedIcon fontSize="small" />
            )
          }
        />
      </div>
      <Modal
        visible={modalVisible}
        hideX
        overrideStyle={{
          borderRadius: 0,
          padding: 0,
          width: 372,
        }}
        overrideStyleModal={{
          overlay: {
            width: "100%",
            zIndex: 500,
            background: "rgba(0, 0, 0, .40)",
          },
        }}
      >
        <div className="p-6 font-normal text-black">
          <div className="mb-4 text-2xl">Clear chat history?</div>
          <div className="text-base">
            Your chat session will be erased to change performance settings.
          </div>
        </div>
        <div className="mb-2 mr-6 mt-4 flex flex-row items-center justify-end">
          <div
            className="mx-3 cursor-pointer px-2 text-secondary hover:text-primary"
            onClick={() => setModalVisible(false)}
          >
            Cancel
          </div>
          <Button
            onClick={() => {
              if (stagedNewPerformanceType)
                changePerformanceTypeAndClear(stagedNewPerformanceType);
              setModalVisible(false);
            }}
          >
            Continue
          </Button>
        </div>
      </Modal>
    </div>
  );
};

type ToggleButtonProps = {
  onClick: () => void;
  active: boolean;
  label: string;
  icon: JSX.Element;
};

const ToggleButton = ({ onClick, active, label, icon }: ToggleButtonProps) => (
  <div
    className={`flex w-1/2 cursor-pointer flex-nowrap items-center justify-center px-4 py-2 ${
      active ? "border bg-card font-medium text-hebbiaBlue shadow-sm" : ""
    }`}
    onClick={onClick}
  >
    {icon}
    <div className="ml-2">{label}</div>
  </div>
);
