import React, { ReactNode } from "react";
import { StyleSheet } from "source/Types";
import { Layout } from "antd";
import logoLight from "assets/logoLight.svg";
import { TriadBack } from "../TriadBack";
import { Logo, Title } from "pages/verify";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { useSelector } from "react-redux";
import { getUser } from "source/redux/user";
import { Button } from "packages/core";

type Props = {
  titleText: string;
  caption: ReactNode;
};
export const ErrorPageLayout = ({ titleText, caption }: Props) => {
  const { goToHomeReallyHard } = useGlobalNavigator();
  const user = useSelector(getUser);
  return (
    <Layout>
      <Layout style={styles.layout}>
        <TriadBack fullSize style={{ height: "100vh" }}>
          <div
            className="mx-24 mb-32 mt-24 flex grow flex-col items-center justify-center rounded-lg bg-white p-7"
            style={{
              boxShadow: "0px 7px 24px -3px rgba(var(--shadow), 0.7)",
            }}
          >
            <div className="flex flex-col items-center justify-center">
              <Logo
                src={logoLight}
                alt="logo"
                style={{ width: 120, height: "auto", cursor: "pointer" }}
              />
              <Title style={{ textAlign: "center" }}>{titleText}</Title>
              {caption}
            </div>
            <Button onClick={() => goToHomeReallyHard()} className="text-md">
              {user ? "Return Home" : "Return to Login"}
            </Button>
          </div>
        </TriadBack>
      </Layout>
    </Layout>
  );
};

const styles: StyleSheet = {
  layout: {
    boxShadow: "-7px 0 0px -7px rgba(0, 0, 0, 0.2)",
    background: "var(--background)",
    zIndex: 1,
    overflowX: "hidden",
  },
};
