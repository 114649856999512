import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

type Props = {
  header: string;
  subtext: string;
  handleClick: () => void;
};

export const StatusBanner = ({ header, subtext, handleClick }: Props) => (
  <div
    style={{ boxShadow: "0px 5px 10px 0px #2400002A" }}
    className="pointer-events-auto flex cursor-default items-start gap-4 bg-[#E5E6FF] p-4"
  >
    <span>
      <ErrorIcon fontSize="small" className="text-blue-700" />
    </span>
    <div className="-mt-1 flex flex-col">
      <span className="text-lg font-semibold text-black">{header}</span>
      <div className="text-sm text-black">
        <span>{subtext}</span>
        <span>
          {" "}
          Contact
          <a
            href="mailto:support@hebbia.ai"
            className="cursor-pointer px-1 font-semibold underline underline-offset-1"
          >
            support@hebbia.ai
          </a>
        </span>
      </div>
    </div>
    <span className="cursor-pointer place-self-center" onClick={handleClick}>
      <KeyboardDoubleArrowRightIcon
        fontSize="medium"
        className="text-lightBlack hover:text-lightBlack/70"
      />
    </span>
  </div>
);
