import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUser } from "source/redux/user";
import { useGetRouter } from "source/hooks/useGetRouter";
import { SLACK_WEBHOOK_URL } from "source/constants";
import {
  sendLogRocketSlackError,
  sendReportsSlackError,
} from "source/components/matrix/utils";
import logger from "source/utils/logger";
import { ErrorPageLayout } from "./ErrorPageLayout";
import { Text } from "pages/verify";

type Props = {
  error?: Error;
};
export const ErrorPage = ({ error }: Props) => {
  const user = useSelector(getUser);

  const { router } = useGetRouter();
  const activeMatrixId = router.query.matrix_id;
  const activeTabId = router.query.tab_id;

  useEffect(() => {
    logger.error("User shown Error Page", {
      user: user ?? undefined,
      error_message: error?.message,
      error_stack: error?.stack,
    });
    if (activeMatrixId) {
      sendReportsSlackError({
        msg: "User shown ErrorPage on 500",
        user: user ?? undefined,
        reportId: activeMatrixId,
        tabId: activeTabId,
        errorMsg: error?.message,
        stack: error?.stack,
      });
    } else {
      sendLogRocketSlackError({
        msg: "User shown ErrorPage on 500",
        user: user ?? undefined,
        webhook_url: SLACK_WEBHOOK_URL,
        errorMsg: error?.message,
        stack: error?.stack,
      });
    }
  }, []);

  return (
    <ErrorPageLayout
      titleText="Error finding results."
      caption={
        <Text>
          Please contact us at support@hebbia.ai if problems continue.
        </Text>
      }
    />
  );
};
