import { queryClient } from "pages/_app";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  reposKeys,
  useQuerySearchDisabled,
} from "source/api/repos/useQueryRepos";
import { getTargetDocs } from "source/redux/advanced";
import { RepoType } from "source/Types";

export const useSearchIsDisabled = (repo?: RepoType) => {
  const docTargets = useSelector(getTargetDocs);
  const disabled = useQuerySearchDisabled(docTargets, repo?.id);

  // Force re-query when a new build completes
  // Force re-query when no of searchabe docs changes
  // (e.g. new doc uploaded or doc deleted)
  useEffect(() => {
    if (repo?.id)
      queryClient.invalidateQueries(
        reposKeys.searchDisabled(docTargets, repo.id)
      );
  }, [docTargets.length, repo?.last_built]);
  return disabled;
};
