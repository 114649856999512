import { classNames } from "core";
import React from "react";

type Props = {
  width?: number;
  height?: number;
  className?: string;
};

export const HebbiaLogo = ({ width = 18, height = 18, className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames("text-brand-500", className)}
    >
      <path
        d="M0 0H2.66667V2.66667H0V0ZM4 2.66667H8V0H4V2.66667ZM9.33333 0V2.66667H16V0H9.33333ZM0 8H2.66667V4H0V8ZM4 8H8V4H4V8ZM9.33333 8H16V4H9.33333V8ZM0 16H2.66667V9.33333H0V16ZM4 16H8V9.33333H4V16ZM9.33333 16H16V9.33333H9.33333V16Z"
        fill="currentColor"
      />
    </svg>
  );
};
