import React from "react";
import { LinkSharp, PushPin } from "@mui/icons-material";
import notification from "antd/lib/notification";
import { encode } from "querystring";
import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { BASE_URL } from "source/constants";
import {
  removeActivitiesById,
  upsertActivities,
} from "source/redux/activityFeed";
import { getCurrentOrg } from "source/redux/organization";
import {
  deletePins,
  getDocPinsDict,
  getPinsDict,
  upsertPins,
} from "source/redux/pin";
import { removeSearchPin } from "source/redux/search";
import { getCurrentSelectionId, setCurrentSelectionId } from "source/redux/ui";
import { HighlightContextType, PinType, QueryResultType } from "source/Types";
import { useCurrentRepo } from "./repos/useCurrentRepo";
import { useGetRouter } from "./useGetRouter";

export const usePins = () => {
  const dispatch = useDispatch();

  const org = useSelector(getCurrentOrg);
  const repo = useCurrentRepo(true);
  const { router } = useGetRouter();

  const currentPinId = useSelector(getCurrentSelectionId);
  const allPins = useSelector(getPinsDict);
  // maps doc id to pin ids
  const docPinsDict = useSelector(getDocPinsDict);
  const currentPin: PinType | undefined = currentPinId
    ? allPins[currentPinId]
    : undefined;

  // Get id: pin mapping for given doc id
  const getDocPins = (docId: string) => {
    const pinMap: { [id: string]: PinType } = {};
    if (docPinsDict[docId])
      docPinsDict[docId]?.forEach((pinId) => {
        const pin = allPins[pinId];
        if (pin) pinMap[pinId] = pin;
      });
    return pinMap;
  };

  const getAllPins = (show_all: boolean) => {
    api.pins.getAllPins({ show_all }).then(({ data }) => {
      dispatch(upsertPins(data.pins || []));
    });
  };

  const fetchDocPins = async (docId: string) => {
    api.pins.getDocPins(docId).then(({ data }) => {
      dispatch(upsertPins(data.pins || []));
    });
  };

  const fetchRepoPins = (repoId: string) => {
    api.pins.getRepoPins(repoId).then(({ data }) => {
      dispatch(upsertPins(data.pins || []));
    });
  };

  // Calling this upon page load (when selection query param changes) will
  // also set the table selection as active for linked tables
  const fetchPin = (pinId: string) => {
    api.pins.get(pinId).then(({ data }) => {
      if (data.pin) dispatch(upsertPins([data.pin]));
    });
    dispatch(setCurrentSelectionId(pinId));
  };

  const handlePin = async (
    result: QueryResultType,
    e,
    highlightContext?: HighlightContextType,
    query?: string
  ) => {
    e.stopPropagation();

    if (!query && !router.query.query?.length) return;
    // Override router.query.query for table doc viewer
    const pinQuery = query || (router.query.query as string);

    if (!repo && !result?.repo_id) return;
    const repoId = result?.repo_id || repo?.id;

    if (!repoId) return;

    const { data } = await api.pins.create({
      query: pinQuery,
      result,
      repo_id: repoId,
      org_id: org?.id,
      highlight_context: highlightContext,
      doc_id: result.doc_id,
    });

    if (data.pin.id) {
      dispatch(upsertPins([data.pin]));
      const meta = {
        query: pinQuery,
        pin_id: data.pin.id,
        repo_id: repoId,
        doc_id: result.doc_id,
        passage_id: result.passage_id,
        auto_created: false,
      };
      const activityPinData = await api.activityFeed.addActivity(
        data.created_at,
        "create_pin",
        meta,
        repoId
      );
      const activityPin = activityPinData.data.activities;
      dispatch(upsertActivities(activityPin));
      return data.pin;
    }
  };

  const handleUnpin = async (pin: PinType, e) => {
    e.stopPropagation();
    await api.pins.delete(pin);
    dispatch(deletePins([pin]));
    // will remove if search pin
    dispatch(removeSearchPin(pin));
    if (currentPinId === pin.id) {
      dispatch(setCurrentSelectionId());
    }
    try {
      if (pin.repo_id) {
        const { data } = await api.activityFeed.deletePin(pin.repo_id, pin.id);
        dispatch(removeActivitiesById([data.activity_id]));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDocumentPin = async (
    docId: string,
    buildId: string | undefined,
    highlightContext: HighlightContextType,
    isLink = false
  ) => {
    const { doc } = await api.docs.getDocById(docId);
    if (!doc) {
      return;
    }
    const repoId = doc.repo_id;

    const { data } = await api.pins.create({
      repo_id: repoId,
      doc_id: docId,
      build_id: buildId,
      org_id: org?.id,
      highlight_context: highlightContext,
      is_link: isLink,
    });
    if (data.pin.id) {
      dispatch(upsertPins([data.pin]));
      dispatch(setCurrentSelectionId(data.pin.id));

      if (isLink) {
        // Copy link to users clipboard
        const url =
          `${BASE_URL}/repos/${repoId}?doc_ids=%5B%22${docId}%22%5D` +
          "&" +
          encode({ selection: data.pin.id });
        navigator.clipboard.writeText(url);
        notification.success({
          message: "Copied link to clipboard",
          placement: "bottomRight",
          duration: 2,
          icon: <LinkSharp className="text-bluePurple" />,
          closeIcon: <></>,
        });
        return;
      }

      const meta = {
        pin_id: data.pin.id,
        doc_id: docId,
        text: highlightContext.text,
      };

      // Post activity
      const activitySelectionData = await api.activityFeed.addActivity(
        data.created_at,
        "create_doc_pin",
        meta,
        repoId
      );
      const activitySelection = activitySelectionData.data.activities;
      dispatch(upsertActivities(activitySelection));
      notification.success({
        message: "Saved Pin",
        placement: "bottomRight",
        duration: 2,
        icon: <PushPin className="text-bluePurple" />,
        closeIcon: <></>,
      });
    }
  };

  return {
    getDocPins,
    getAllPins,
    handlePin,
    handleUnpin,
    handleDocumentPin,
    fetchDocPins,
    fetchRepoPins,
    fetchPin,
    currentPin,
  };
};
