import React from "react";
import styled from "styled-components";
import { DOC_TAB_WIDTH } from "source/constants";
import { useTableRow } from "source/hooks/useTableRow";
import { SidebarInput } from "./SidebarInput";

export type SidebarRowProps = {
  id: string;
  title: string;
  depth?: number;
  icon?: React.ReactNode;
  hover?: React.ReactNode;
  toggle?: React.ReactNode;
  badge?: React.ReactNode;
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onIconClick?: () => void;
  handleDoneEditing?: (newTitle: string) => void;
  hideHover?: boolean;
  style?: any;
  isDndTarget?: boolean;
  dndItem?: any;
  onDrop?: (item: any, monitor: any) => void;
  width?: number;
  classOverride?: string;
  titleStyleOverride?: React.CSSProperties;
};

/**
 * SidebarRow is a low-level, highly flexible component used to visually render almost
 * everything in the left hand sidebar.
 */
export const SidebarRow = ({
  id,
  title,
  depth,
  icon,
  hover,
  toggle,
  badge,
  children,
  subtitle,
  isActive,
  onClick,
  onIconClick,
  handleDoneEditing,
  hideHover,
  style,
  isDndTarget,
  dndItem,
  onDrop,
  width,
  classOverride,
  titleStyleOverride,
}: SidebarRowProps) => {
  const {
    isHovering,
    setHovering,
    currentRepo,
    _handleDoneEditing,
    drag,
    canDrop,
    isOver,
    drop,
  } = useTableRow({ isDndTarget, handleDoneEditing, dndItem, onDrop });

  const shouldHover = !!onClick && !hideHover;

  const getBody = () => (
    <div
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        // override default left padding if specified
        paddingLeft: classOverride?.includes("pl-")
          ? null
          : 24 + (depth || 0) * DOC_TAB_WIDTH,
        cursor: onClick ? "pointer" : "default",
        ...(width && {
          width: width,
        }),
        ...style,
      }}
      onClick={onClick}
      className={`my-0 flex h-8 w-full flex-row items-center px-3 py-0.5 text-secondary ${classOverride} ${
        isActive
          ? "bg-lightPurple/20 hover:bg-lightPurple/30 active:bg-lightPurple/40"
          : shouldHover
            ? "hover:bg-hoverRow active:bg-hoverRowActive"
            : ""
      }`}
    >
      {toggle}
      <div className="flex h-full w-full min-w-0 items-center justify-between">
        <div className="mr-1 flex flex-1 items-center overflow-hidden">
          <span
            style={{ marginRight: 4, display: "flex", alignItems: "center" }}
            onClick={onIconClick}
          >
            {icon}
          </span>
          <SidebarInput
            id={id}
            title={title}
            handleDoneEditing={_handleDoneEditing}
            styleOverride={{ width: "100%", ...titleStyleOverride }}
          />
          <span
            style={{
              color: "var(--text-light)",
              WebkitTextFillColor: "var(--text-light)",
            }}
          >
            {subtitle}
          </span>
        </div>
        {isHovering && hover}
        {badge}
      </div>
    </div>
  );

  const getHandleBody = () =>
    dndItem ? (
      // @ts-expect-error ref type doesn't match exactly
      <Handle role="Handle" ref={drag}>
        {getBody()}
      </Handle>
    ) : (
      getBody()
    );

  return (
    <>
      {isDndTarget ? (
        <div
          // @ts-expect-error ref type doesn't match exactly
          ref={drop}
          role="Dustbin"
          style={{
            backgroundColor: canDrop
              ? isOver
                ? "#dadada"
                : "#eee"
              : undefined,
          }}
        >
          {getHandleBody()}
        </div>
      ) : (
        getHandleBody()
      )}
      {children}
    </>
  );
};

const Handle = styled.div`
  display: flex;
  align-items: center;
`;
