import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSidebarVisible,
  setUserToggledSidebar,
  setSidebarVisible,
} from "source/redux/sidebar";
import { Button } from "core";
import { MaterialSymbolsIcon } from "core";
import { Tooltip } from "@mui/material";

export const ToggleSidebarButton = () => {
  const dispatch = useDispatch();
  const sidebarVisible = useSelector(getSidebarVisible);

  return (
    <Tooltip
      title={`${sidebarVisible ? "Close" : "Open"} sidebar`}
      enterNextDelay={500}
    >
      <Button
        variant="text"
        style={{
          fontVariationSettings: sidebarVisible
            ? undefined
            : `'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24`,
        }}
        onClick={() => {
          dispatch(setUserToggledSidebar(true));
          dispatch(setSidebarVisible(!sidebarVisible));
        }}
      >
        <MaterialSymbolsIcon size="xl" icon="dock_to_right" variant="rounded" />
      </Button>
    </Tooltip>
  );
};
