import React, { useEffect } from "react";
import { Tab } from "./Tab";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AutoAwesomeMosaicSharpIcon from "@mui/icons-material/AutoAwesomeMosaicSharp";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ForumIcon from "@mui/icons-material/Forum";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import { getCurrentPage } from "source/utils/helpers";
import { getCurrentOrg } from "source/redux/organization";
import { useSelector } from "react-redux";
import { useAppDispatch } from "source/redux";
import { resetChatSession } from "source/redux/chat";
import { setMatrixHomePanel } from "source/redux/matrix";
import { MatrixHomePanelType } from "source/components/matrix/home/types";
import { MaterialSymbolsIcon } from "core";
import { useGlobalNavigator } from "source/hooks/useSetRouter";

export type TabType =
  | "Search"
  | "Chat"
  | "ChatDocs"
  | "Matrix"
  | "Alerts"
  | "Browse";

export const HIDE_SEARCH_CHATDOCS: string[] = [];

export const Tabs = () => {
  const { router } = useGetRouter();
  const currentPage = getCurrentPage(router.pathname);

  const enableDocumentsLists = useFeatureFlag("enableDocumentsLists");
  const enableDocumentListBrowseApp = useFeatureFlag(
    "enableDocumentListBrowseApp"
  );
  const chatPlus = useFeatureFlag("chatPlus");
  const hebbiaChat = useFeatureFlag("hebbiaChat");
  const { goToPage } = useGlobalNavigator();

  const dispatch = useAppDispatch();

  const currentOrgId = useSelector(getCurrentOrg)?.id;

  const cleanupState = (location: string) => {
    if (
      (currentPage === "Chat" && location !== "/chat") ||
      (currentPage === "ChatDocs" && location !== "/chat-docs")
    ) {
      dispatch(resetChatSession({}));
    }

    if (currentPage === "Matrix") {
      dispatch(setMatrixHomePanel(MatrixHomePanelType.HOME));
    }
  };

  useEffect(() => {
    // If document list isn't enabled, re-direct.
    if (
      enableDocumentsLists &&
      !enableDocumentsLists &&
      router.pathname.includes("browse")
    ) {
      goToPage("/matrix");
    }
  }, [router, enableDocumentsLists]);

  return (
    <div className="flex w-full select-none flex-col">
      <Tab
        page="Matrix"
        icon={
          currentPage === "Matrix" ? (
            <AutoAwesomeMosaicSharpIcon
              style={{ fontSize: "1.25rem" }}
              className="text-bluePurple"
            />
          ) : (
            <MaterialSymbolsIcon
              size="xl"
              variant="sharp"
              icon="auto_awesome_mosaic"
              className="leading-none"
            />
          )
        }
        location="/matrix"
        isSelected={currentPage === "Matrix"}
        onClick={cleanupState}
      />
      {chatPlus &&
        currentOrgId &&
        !HIDE_SEARCH_CHATDOCS.includes(currentOrgId) && (
          <Tab
            page="ChatDocs"
            icon={
              currentPage === "ChatDocs" ? (
                <ForumIcon
                  style={{ fontSize: "1.25rem" }}
                  className="text-bluePurple"
                />
              ) : (
                <ForumOutlinedIcon style={{ fontSize: "1.25rem" }} />
              )
            }
            location="/chat-docs"
            isSelected={currentPage === "ChatDocs"}
            onClick={cleanupState}
          />
        )}
      {hebbiaChat && (
        <Tab
          page="Chat"
          icon={
            currentPage === "Chat" ? (
              <ChatBubbleIcon
                style={{ fontSize: "1.25rem" }}
                className="text-bluePurple"
              />
            ) : (
              <ChatBubbleOutlineIcon style={{ fontSize: "1.25rem" }} />
            )
          }
          location="/chat"
          isSelected={currentPage === "Chat"}
          onClick={cleanupState}
        />
      )}
      {enableDocumentListBrowseApp && (
        <Tab
          page="Browse"
          icon={
            <MaterialSymbolsIcon
              icon="folder_open"
              size="xl"
              filled={currentPage === "Browse"}
            />
          }
          location="/browse"
          isSelected={currentPage === "Browse"}
          onClick={cleanupState}
        />
      )}
    </div>
  );
};
