import React, { useState } from "react";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CloseIcon from "@mui/icons-material/Close";

/**
 * @deprecated Use FlaggedBanner over this footer
 */
export const FooterBanner = () => {
  // Maintenance banner is used to target specific orgs for when things go down
  const maintenance = useFeatureFlag("showMaintenanceBanner");
  // Hebbia banner is toggled on and message is set in LD for when global things break
  const hebbiaBannerMessage = useFeatureFlag("hebbiaBanner");
  const [bannerClosed, setBannerClosed] = useState(false);

  if (hebbiaBannerMessage?.message) {
    return (
      <div className="fixed bottom-0 z-50 flex h-12 w-full items-center justify-center bg-lightPink px-7 text-xs text-primary">
        <div className="mx-10 flex w-full items-center justify-center">
          <NotificationsNoneIcon />
          <div className="ml-1 whitespace-normal break-words">
            <span>{hebbiaBannerMessage.message}</span>
            <span className="px-1">
              Please reach out to
              <a
                href="mailto:eng@hebbia.ai"
                className="px-1 underline underline-offset-1"
              >
                eng@hebbia.ai
              </a>
              for further assistance.
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (maintenance && !bannerClosed) {
    return (
      <div
        className="fixed bottom-0 z-50 flex h-16 w-full items-center justify-center bg-lightPink px-7 text-xs text-primary"
        style={{
          zIndex: 500000,
          borderBottom: "0.5px solid #ffff",
        }}
      >
        <div className="mx-10 flex w-full items-center justify-center">
          <NotificationsNoneIcon />
          <div className="ml-1 whitespace-normal break-words">
            <span className="">
              Please access Hebbia from your company laptop or VDI. For
              questions,
            </span>
            <span className="px-1">
              please reach out to
              <a
                href="mailto:support@hebbia.ai"
                className="px-1 underline underline-offset-1"
              >
                support@hebbia.ai
              </a>
              for further assistance.
            </span>
          </div>
        </div>
        <span
          className="cursor-pointer hover:bg-darkGray/20 active:bg-darkGray/30"
          onClick={() => setBannerClosed(true)}
        >
          <CloseIcon />
        </span>
      </div>
    );
  }

  return null;
};
