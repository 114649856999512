import React from "react";

type Props = {
  text: string;
  Icon?: React.ReactNode;
  handleClick: () => void;
};

export const BottomAddButton = ({ text, Icon, handleClick }: Props) => {
  return (
    <div
      className="group flex w-full cursor-pointer flex-col items-center gap-1 p-2"
      onClick={handleClick}
    >
      {!!Icon && (
        <div className="flex h-9 w-9 items-center justify-center rounded-lg p-2 text-gray-500 group-hover:bg-gray-200 group-active:bg-gray-300">
          {Icon}
        </div>
      )}
      <span className="truncate text-[11px] font-semibold leading-3 text-gray-500">
        {text}
      </span>
    </div>
  );
};
