import React from "react";
import { useSelector } from "react-redux";
import Image from "next/image";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { getCurrentOrg } from "source/redux/organization";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import { HebbiaLogo } from "source/components/library/Logo/HebbiaLogo";
import Link from "next/link";

export const HomeButton = () => {
  const { homepage } = useGlobalNavigator();
  const org = useSelector(getCurrentOrg);
  const useCustomLogo = useFeatureFlag("useCustomLogo");

  return (
    <Link
      href={homepage}
      className="group flex w-full cursor-pointer justify-center py-2"
    >
      {org && useCustomLogo ? (
        <Image
          src={`https://custom-org-logos.s3.us-east-1.amazonaws.com/${org.id}.png`}
          alt={`${org?.name}`}
          height={20}
          width={20}
        />
      ) : (
        <HebbiaLogo
          width={20}
          height={20}
          className="group-hover:text-brand-600"
        />
      )}
    </Link>
  );
};
