import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchSearchHistory,
  getSearchHistory,
} from "source/redux/searchHistory";

export const useSearchHistory = () => {
  const dispatch = useDispatch();
  const searchHistory = useSelector(getSearchHistory);
  const [history, setHistory] = useState<string[]>([]);

  const handleFetchSearchHistory = async () => {
    try {
      await fetchSearchHistory(dispatch);
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    // get unique search history in chronological order
    const historyDict = {};
    searchHistory.forEach((search) => {
      const date = new Date(search.updated_at);
      if (!(search.query in historyDict)) historyDict[search.query] = date;
      else {
        // update to newest
        if (historyDict[search.query] < date) historyDict[search.query] = date;
      }
    });

    // Convert dict to array
    const historyMapping = Object.keys(historyDict).map((key) => [
      key,
      historyDict[key],
    ]);

    // Sort array based on date
    historyMapping.sort((first, second) => second[1] - first[1]);

    // Convert back to 1d array
    const historyArray = historyMapping.map((item) => item[0]);

    setHistory(historyArray);
  }, [searchHistory]);

  const searchSearchHistory = (
    query: string,
    history: string[],
    limit: number
  ) =>
    history
      .filter((hist) => hist.toLowerCase().indexOf(query.toLowerCase()) >= 0)
      .slice(0, limit);

  return { handleFetchSearchHistory, history, searchSearchHistory };
};
