import { message } from "antd";
import { FileDndProvider } from "source/components/shared/FileDragAndDrop/FileDndProvider";
import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { useCanEditRepo } from "source/hooks/permissions/useCanEditRepo";
import { useFetchPaginatedParentDocs } from "source/hooks/repos/useFetchPaginatedParentDocs";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { RepoType } from "source/Types";
import { AddFolderSidebarRow } from "./AddFolderSidebarRow";
import { DocRowAndChildren } from "./DocRowAndChildren";
import { LoadMoreRow } from "./LoadMoreRow";
import { useMoveDocMutation } from "source/api/docs/useMutateDocs";
import { useUploadFiles } from "source/hooks/useUploadFile";

type Props = {
  repo: RepoType;
};

export const DocumentSection = ({ repo }: Props) => {
  const { goToDoc } = useGlobalNavigator();
  const canEdit = useCanEditRepo(repo);
  const { mutate: moveDocMutation } = useMoveDocMutation();
  const { uploadFiles } = useUploadFiles({ repo });

  const [isDragging, setIsDragging] = useState(false);

  const {
    parentDocs,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage: isFetchingMoreDocs,
  } = useFetchPaginatedParentDocs({
    repo,
  });

  useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: "SidebarDoc",
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: async (item: any, monitor) => {
      if (!item.docId) return;
      // if its already at the root
      if (!item.parentId) return;
      // item is the dragged item
      message.info("Moving doc...");
      moveDocMutation({
        docId: item.docId,
      });
      goToDoc(item.docId);
    },
  }));

  const renderParentDocs = () =>
    parentDocs.map((doc) => (
      <DocRowAndChildren
        repo={repo}
        doc={doc}
        key={doc.id}
        depth={0}
        defaultOpen={parentDocs.length <= 5}
        showSeeMore={true}
        idPrefix={"d"}
      />
    ));

  return (
    <>
      <FileDndProvider
        setIsDragging={setIsDragging}
        uploadFiles={uploadFiles}
        style={"h-fit pb-4"}
      >
        <div
          className={
            "flex h-full w-full flex-col whitespace-nowrap " +
            ` ${
              isDragging
                ? "border-2 border-darkBlue"
                : "border-0 border-transparent"
            }`
          }
        >
          {renderParentDocs()}
          {!isFetchingMoreDocs && hasNextPage && (
            <LoadMoreRow onClick={fetchNextPage} />
          )}
          {canEdit && repo.private && !repo.integrations?.findox && (
            <AddFolderSidebarRow repo={repo} />
          )}
        </div>
      </FileDndProvider>
    </>
  );
};

// type DragNDropProps = {
//   canDrop: boolean;
//   isOver: boolean;
//   drop: any;
// };

/**
 * Unused for now, can be used to provide a gray area beneath the files in the sidebar,
 * e.g. for dragndrop delete.
 */
// const DragNDropDustbin = ({ canDrop, isOver, drop }: DragNDropProps) => {
//   return (
//     <div
//       ref={drop}
//       role="Dustbin"
//       className="grow p-1"
//       style={{
//         backgroundColor: canDrop ? (isOver ? "#dadada" : "#eee") : undefined,
//       }}
//     />
//   );
// };
