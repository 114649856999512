import { useMemo } from "react";
import { useSelector } from "react-redux";
import { DebugFlagReduxType, getSearchDebugFlags } from "source/redux/debug";
import {
  DATE_KEY_NAME,
  getActiveSynonyms,
  getSearchDisableDateAutoApplied,
  isDateRange,
} from "source/redux/search";
import { getSearchQuery } from "source/redux/ui";
import { FilterType, SearchParams } from "source/Types";
import { useCurrentRepo } from "../repos/useCurrentRepo";
import { useSidebarRepos } from "../useSidebarRepos";
import { defaultDateRanges } from "source/api/dates/useQueryDates";
import {
  getFilterSearchKey,
  getTargetAppliedFilters,
  getTargetCompanies,
  getTargetDisabledFilters,
  getTargetDocs,
  getTargetNonPredefinedFilters,
  getTargetRepos,
} from "source/redux/advanced";
import { useQueryDocsByIds } from "source/api/docs/useQueryDocs";
import { getCurrentOrg } from "source/redux/organization";
import { FiltersConfig } from "source/constants/filters";
import { useGetRouter } from "../useGetRouter";

export const buildDateFilter = (filters: FilterType[]) => {
  const dateFilter = filters.find((filter) => filter.key === DATE_KEY_NAME);
  if (!dateFilter) return null;
  const value = dateFilter.value;
  if (isDateRange(value)) {
    const { startDate } = defaultDateRanges[value];

    return {
      start_date: startDate
        ? Math.floor(startDate.getTime() / 1000).toString()
        : undefined,
      end_date: undefined,
    };
  }
  return null;
};

const buildManualOverrides = (debugFlags: DebugFlagReduxType) => {
  const queryParams = {};
  if (debugFlags.useHyde) queryParams["use_hyde"] = true;
  if (debugFlags.useTitles) queryParams["use_titles"] = true;
  if (debugFlags.hitStagingReranker) queryParams["hit_staging_reranker"] = true;
  if (debugFlags.shouldRerank) queryParams["should_rerank"] = true;
  if (debugFlags.hydePrefix) queryParams["hyde_prefix"] = debugFlags.hydePrefix;
  return queryParams;
};

/**
 * Hook to get necessary search params to build search request
 *
 * NOTICE: redux is our source of truth NOT router.query
 */
export const useSearchParams = () => {
  const {
    repo_ids,
    doc_ids,
    strFilters,
    is_global_search,
    protected_user_selected_repos,
  } = _useSearchTargets();
  const { router } = useGetRouter();
  const org = useSelector(getCurrentOrg);
  const disabledFilters = useSelector(getTargetDisabledFilters);
  const dates = buildDateFilter(strFilters);
  const query = useSelector(getSearchQuery);
  const disableDateSelection = useSelector(getSearchDisableDateAutoApplied);
  const debugFlags = useSelector(getSearchDebugFlags);
  const filteredFlags = useMemo(
    () => buildManualOverrides(debugFlags),
    [debugFlags]
  );
  const synonymsModified = useSelector(getActiveSynonyms);
  const params: SearchParams = useMemo(
    () => ({
      query,
      ...dates,
      repo_ids,
      protected_user_selected_repos,
      doc_ids,
      filters: strFilters.filter((filter) => filter.key !== DATE_KEY_NAME),
      disabled_filters: disabledFilters,
      disable_date_selection: disableDateSelection,
      is_global_search: is_global_search,
      synonyms_modified: synonymsModified.synonyms_applied || [],
      ...filteredFlags,
      org_id: router.pathname.includes("/repos") ? "" : org?.id,
    }),
    [
      repo_ids,
      doc_ids,
      strFilters,
      dates,
      disabledFilters,
      filteredFlags,
      disableDateSelection,
      query,
      protected_user_selected_repos,
    ]
  );

  return params;
};

/**
 * INTERNAL HOOK ONLY FOR useSearchParams -- this hook manages the logic to figure out what to search
 * over based on what is currently selected/open
 *
 * NOTICE: redux is our source of truth NOT router.query
 */
const _useSearchTargets = () => {
  const currentRepo = useCurrentRepo(true);
  const sidebarRepos = useSidebarRepos();
  const sidebarRepoIds = useMemo(
    () => sidebarRepos.map((repo) => repo.id),
    [sidebarRepos]
  );
  const doc_ids = useSelector(getTargetDocs);

  const docs = useQueryDocsByIds(doc_ids);
  const docRepoIds = Array.from(
    new Set(
      docs
        .map((doc) => doc.data?.doc.repo_id)
        .filter((repoId) => repoId) as string[]
    )
  );

  // These are the repos that the user has checked on the frontend
  const explicitlySelectedRepos = useSelector(getTargetRepos);

  // These are the applied filters from the backend.
  const appliedFilters = useSelector(getTargetAppliedFilters);

  const appliedRepoFilters = appliedFilters
    .filter(
      (appliedFilter) =>
        appliedFilter.key === FiltersConfig.CUSTOM_FILTER_KEY_REPO
    )
    .map((filter) => filter.value);

  // These are the target repo_ids to pass to search params
  // It is a union of explicitly selected repo_ids and the repo_ids of the docs
  const targetRepoIds = useMemo(
    () => [...explicitlySelectedRepos, ...docRepoIds, ...appliedRepoFilters],
    [explicitlySelectedRepos, docRepoIds, appliedRepoFilters]
  );

  // repoIds is target repos, or current repo, otherwise all sidebar repos
  const repo_ids: string[] = useMemo(() => {
    if (targetRepoIds.length) return targetRepoIds;
    if (currentRepo?.id) return [currentRepo?.id];
    return sidebarRepoIds;
  }, [targetRepoIds, currentRepo?.id, sidebarRepoIds]);

  const protected_user_selected_repos = useMemo(() => {
    if (!doc_ids.length && !explicitlySelectedRepos.length) {
      // If no docs and no repos are explicitly selected, we are in a case where a user has
      // implicitly selected a repo

      // If current repo, the user has implicitly selected the currentRepo
      if (currentRepo?.id) return [currentRepo?.id];
      // If on global search page, then we pass undefined as user selected repos, the backend will revert to AND docs and repos flow
      return undefined;
    }

    // If something is selected, then we don't have an explicit selection, we just return the explicitly
    // Selected repo ids
    return explicitlySelectedRepos;
  }, [currentRepo?.id, sidebarRepoIds, explicitlySelectedRepos, doc_ids]);

  const companyIds = useSelector(getTargetCompanies);
  const searchKey = getFilterSearchKey("company");
  const companyFilters = useMemo(() => {
    const filtersArr: FilterType[] = [];
    companyIds.forEach((id) => {
      filtersArr.push({ key: searchKey, value: id });
    });
    return filtersArr;
  }, [companyIds]);

  const filterTargets = useSelector(getTargetNonPredefinedFilters);

  const filters: FilterType[] = useMemo(() => {
    const concatFilters = [...filterTargets, ...companyFilters];
    if (appliedFilters?.length) {
      concatFilters.push(
        ...appliedFilters.filter(
          (appliedFilter) =>
            appliedFilter.key !== FiltersConfig.CUSTOM_FILTER_KEY_REPO
        )
      );
    }
    return concatFilters;
  }, [filterTargets, appliedFilters, companyFilters]);

  const is_global_search: boolean = useMemo(
    () => !currentRepo && doc_ids.length === 0 && targetRepoIds.length === 0,
    [currentRepo, targetRepoIds, doc_ids]
  );

  return {
    doc_ids,
    repo_ids,
    filters,
    strFilters: filters,
    is_global_search,
    protected_user_selected_repos,
  };
};
