import _ from "lodash";
import { useDispatch } from "react-redux";
import { setSelectedDocId, setSelectedRepoId } from "source/redux/search";
import { upsertSidebarRowsOpen } from "source/redux/sidebar";
import { useRepoDocsDict } from "source/hooks/repos/useRepoDocsDict";
import { docFetcher } from "source/api/docs/useQueryDocs";
import { queryClient } from "pages/_app";
import { useCurrentRepo } from "./useCurrentRepo";
import { docsKeys } from "source/api/docs/docsKeyFactory";

export const useAutoOpenFolders = () => {
  const dispatch = useDispatch();
  const repo = useCurrentRepo();
  const repoDocsDict = useRepoDocsDict(repo?.id);

  const handleFetch = async (docId: string, repoId?: string) => {
    // This will imperatively fetch the query either from the cache, if it is there, otherwise from the fetcher
    // and then will place the data in the cache (notice this matches the single docFetcher)
    const data = await queryClient.fetchQuery({
      queryKey: docsKeys.doc(docId),
      queryFn: docFetcher,
    });

    if (data?.doc) {
      const payload = {};
      payload[`d${data.doc.id}`] = true;
      dispatch(upsertSidebarRowsOpen(payload));
    }

    if (data?.ancestors.length) {
      const payload = {};
      data.ancestors.forEach((ancestor) => {
        payload[`d${ancestor.id}`] = true;
      });
      dispatch(upsertSidebarRowsOpen(payload));
    }
  };

  const handleAutoOpening = (docId?: string, repoId?: string) => {
    // Set selected ids to highlight doc and repo in sidebar
    if (repoId) {
      dispatch(setSelectedRepoId(repoId));
      // If no docId is repo so scroll to it
      const sidebarRepo = document.getElementById(`${repoId}`);
      if (sidebarRepo) {
        sidebarRepo.scrollIntoView();
      }
    }
    if (!docId) return;
    dispatch(setSelectedDocId(docId));
    if (docId in repoDocsDict) {
      const docPath = repoDocsDict[docId]?.path
        ? repoDocsDict[docId]?.path
        : [];
      // add all parent doc ids so they are auto-opened
      const payload = _.reduce(
        docPath,
        (obj, parentId) => {
          obj[`d${parentId}`] = true;
          return obj;
        },
        {}
      );
      dispatch(upsertSidebarRowsOpen(payload));

      const sidebarDoc = document.getElementById(`d${docId}`);
      if (sidebarDoc) {
        sidebarDoc.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "start",
        });
      }
    } else {
      // fetch doc, upsert it, and upsert parents
      handleFetch(docId, repoId).then(() => {
        const sidebarDoc = document.getElementById(`d${docId}`);
        if (sidebarDoc) {
          sidebarDoc.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "start",
          });
        }
      });
    }
  };

  return { handleAutoOpening };
};
