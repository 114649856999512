import auth0 from "source/utils/auth0";
import logoLight from "assets/logoLight.svg";
import { AppLayout } from "source/components/shared/AppLayout/LegacyAppLayout";
import Image from "next/image";
import React from "react";
import styled from "styled-components";
import { DEFAULT_REDIRECT, TOP_BAR_HEIGHT } from "source/constants";
import { withServerSideVerify } from "source/ssr/verify";
import { UserDropdown } from "source/components/shared/TopBar/components/UserDropdown";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import Link from "next/link";

const VerifyPage: NextPage = () => {
  return (
    <AppLayout hasTabBar={false} topbarType="none" sidebarType="none">
      <div className="flex justify-end px-6 py-6">
        <UserDropdown isOnboarded={false} />
      </div>
      <div
        className="mb-0 flex h-screen flex-col items-center"
        style={{ marginTop: `${TOP_BAR_HEIGHT + 80}px` }}
      >
        <Logo
          src={logoLight}
          alt="logo"
          style={{ width: 120, height: "auto", cursor: "pointer" }}
        />
        <Title>Check your email for confirmation</Title>
        <Text>
          We've sent a confirmation link to your email. The link expires
          shortly, so please click it soon.
        </Text>
        <Text style={{ fontSize: "14px", color: "var(--text-tertiary)" }}>
          Can't find your link? Check your spam folder!
        </Text>
        <br />
        <p>
          <Link href="/matrix">
            Once you've verified your email click here.
          </Link>
        </p>
      </div>
    </AppLayout>
  );
};

// fast/cached SSR page
// use this for public pages so we can cache ssr
export default (props) => {
  const { asPath } = useRouter();
  return withPageAuthRequired(VerifyPage, { returnTo: asPath })(props);
};

export const Logo = styled(Image)`
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 18px;
  text-align: center;
  color: var(--text-secondary);
  max-width: 700px;
  margin-bottom: 60px;
`;
