import $ from "jquery";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentDoc } from "source/hooks/doc-viewer/useCurrentDoc";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { setSearchActive } from "source/redux/search";
import {
  getGridReportCellModalOpen,
  getHotkeysEnabled,
  getOverrideEscHotkey,
  getTableExtractionModeEnabled,
  setOverrideEscHotkey,
} from "source/redux/ui";
import { DocumentType } from "source/Types";
import { useGetRouter } from "../useGetRouter";
import { useGlobalNavigator } from "../useSetRouter";
import {
  clearAllReportFilterSectionsValues,
  clearRelevantResults,
  getTargetDocs,
  getTargetRepos,
} from "source/redux/advanced";
import { useModal } from "source/components/modals/useModal";
import { ModalTypes } from "source/components/modals/modals.types";

export const useSearchHotkeys = (currentDoc: DocumentType | undefined) => {
  const dispatch = useDispatch();
  const repo = useCurrentRepo();
  const doc = useCurrentDoc();
  const gridReportCellModalOpen = useSelector(getGridReportCellModalOpen);
  const numRepoTargets = useSelector(getTargetRepos).length;
  const numDocTargets = useSelector(getTargetDocs).length;
  const { goToRepo, goToPage } = useGlobalNavigator();
  const { router } = useGetRouter();
  const tableExtractionModeEnabled = useSelector(getTableExtractionModeEnabled);
  const overrideEscHotkey = useSelector(getOverrideEscHotkey);
  const settingsModal = useModal(ModalTypes.SettingsModal);
  const shareModal = useModal(ModalTypes.ShareModal);
  const hotkeysEnabled = useSelector(getHotkeysEnabled);

  useHotkeys(
    "/",
    (event: KeyboardEvent) => {
      event.preventDefault();
      document.getElementById("search-input")?.focus();
    },
    { enabled: hotkeysEnabled },
    []
  );

  // Open Settings
  useHotkeys(
    "meta+shift+s",
    () => {
      settingsModal.isOpen ? settingsModal.close() : settingsModal.open({});
    },
    { enabled: hotkeysEnabled },
    [settingsModal]
  );

  // Open Share
  useHotkeys(
    "F",
    () => {
      if (repo) shareModal.open({ repo: repo });
      else if (doc) shareModal.open({ doc: doc });
    },
    { enabled: hotkeysEnabled },
    [repo, doc]
  );

  useHotkeys(
    "esc",
    () => {
      const isFocused = $("#search-input").is(":focus");
      const inputHasQuery = !!$("#search-input").val()?.toString()?.length;

      // Hacky way to override this behavior in a lower-level component (e.g. sidebar section search).
      // TODO: make less hacky e.g. by using react-hotkeys-hook "scopes".
      if (overrideEscHotkey) {
        dispatch(setOverrideEscHotkey(false));
        return;
      }

      // Do nothing if docviewer table extraction mode enabled
      if (tableExtractionModeEnabled) return;

      // If current report clear it
      if (!inputHasQuery && router.query.report) {
        if (gridReportCellModalOpen) {
          return;
        }
        goToPage(router.pathname);
      }
      // If current report template clear it
      else if (!inputHasQuery && router.query.template) {
        if (gridReportCellModalOpen) {
          return;
        }
        goToPage(router.pathname);
      } else if (isFocused) {
        // If search input is focused without query, blur the element
        if (!inputHasQuery) $("#search-input").blur();
      }
      // Search input is not focused and query, so focus
      else if (inputHasQuery) {
        $("#search-input").trigger("focus");
        dispatch(setSearchActive(null));
      }
      // If current doc and no search input, navigate to repo home
      else if (currentDoc && !inputHasQuery && repo) {
        goToRepo(repo.id);
      }
      // If search tag shown, back to repo, or home screen
      else if (numRepoTargets || numDocTargets) {
        if (repo) {
          goToRepo(repo.id);
        } else {
          // if no query, need to manually clear targets
          if (!router.query.query) {
            dispatch(clearAllReportFilterSectionsValues());
            dispatch(clearRelevantResults());
          } // if query, handler will clear targets
          else {
            goToPage("/chat-docs");
          }
        }
      } else {
        // back to home screen of search
        goToPage("/chat-docs");
      }
    },
    {
      enabled: hotkeysEnabled,
      enableOnFormTags: ["INPUT"],
      enableOnContentEditable: true,
    },
    [
      overrideEscHotkey, // TODO: we should move to react-hotkeys-hook "scopes" to handle things like this
      tableExtractionModeEnabled,
      router.query,
      numRepoTargets,
      numDocTargets,
      gridReportCellModalOpen,
      currentDoc?.id,
      repo?.id,
    ]
  );
};
