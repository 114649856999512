import { AnyAction } from "@reduxjs/toolkit";
import $ from "jquery";
import { Dispatch, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { getAuthToken, setAuthToken } from "source/auth/localStorage";
import fetchAccessToken from "source/auth/fetchAccessToken";
import { MORGAN_MORGAN_ORG_ID, SLACK_WEBHOOK_URL } from "source/constants";
import { WEBSOCKET_URL } from "source/envConstants";
import {
  setGTDAnswer,
  setGTDAnswerDegraded,
  setGTDAnswerFinished,
  setGTDAnswerLoading,
  setGTDCheckAnswer,
  setGTDKeywordAnswerDegraded,
  setGTDPlainAnswer,
  setGTDResultIds,
  setGTDSuggestedQuestion,
  setGTDSummariesLoading,
  setKeywordGTDAnswer,
  setKeywordGTDAnswerFinished,
  setKeywordGTDAnswerLoading,
  setKeywordGTDResultIds,
  upsertGTDAnswerAnswer,
  upsertGTDKeywordAnswer,
  upsertGTDSummaries,
} from "source/redux/gtdAnswer";
import { getCurrentOrg } from "source/redux/organization";
import { upsertPins } from "source/redux/pin";
import {
  addTaskToDisplay,
  getSearchWebsockets,
  removeWebsocket,
  SearchResponseType,
  setActiveSynonyms,
  setKeywordResponseLoading,
  setKeywordSearchActive,
  setKeywordSearchError,
  setKeywordSearchPageNumber,
  setOriginalQuery,
  setRanExactMatchSearch,
  setSearchAppliedDateFilter,
  setSearchAutoToggled,
  setSearchDebugHydeOutput,
  setSearchDebugQueryExpansion,
  setSearchError,
  setSearchLoadingController,
  setSearchPageNumber,
  setSearchResponseLoading,
  setSearchRunning,
  setSearchSortKey,
  setSearchSuggestedFilters,
  setSearchXBRLDocId,
  setSearchXBRLFinancials,
  setSpellCheckQuery,
  setTasks,
  setTasksToDisplay,
  upsertKeywordResults,
  upsertKeywordSearchResponse,
  upsertSearchAppliedFilters,
  upsertSearchResponse,
  upsertSearchResults,
  upsertTasks,
  upsertWebsocket,
} from "source/redux/search";
import { addSearchHistory } from "source/redux/searchHistory";
import { resetSingleDocSearchResults } from "source/redux/singleDocSearch";
import {
  setCurrentSelectionId,
  setOpenSidebarSection,
  setSearchBarFocused,
  setSearchNoDocsVisible,
} from "source/redux/ui";
import { getUser } from "source/redux/user";
import { SegmentEvents } from "source/SegmentEvents";
import { GOALIE_USERGROUP, sendSlackAlert } from "source/utils/slack";
import {
  BackendReportFilter,
  FilterType,
  ResultType,
  SearchHistoryType,
  SearchParams,
  SortKey,
  TaskType,
  UserType,
} from "source/Types";
import { v4 as uuidv4 } from "uuid";
import {
  PosthogAction,
  PosthogObject,
  useLogPosthog,
} from "../tracking/usePosthogTracking";
import { useCurrentDoc } from "../doc-viewer/useCurrentDoc";
import { useCurrentRepo } from "../repos/useCurrentRepo";
import { useGetRouter } from "../useGetRouter";
import { buildStringifiedQuery } from "../useModifySearchTargets";
import { useSearchParams } from "./useSearchParams";
import { useSearchResults } from "./useSearchResults";
import { useSidebarRepos } from "../useSidebarRepos";
import { useAutoOpenFolders } from "../repos/useAutoOpenFolders";
import { FiltersConfig } from "source/constants/filters";
import { COMPANY_SECTION_TITLE } from "source/components/gigabar/components/CompanySection";
import {
  getReportFilter,
  getTargetRepos,
  setNoDocsFilters,
  upsertTargetAppliedFilters,
  upsertTargetSuggestedFilters,
} from "source/redux/advanced";
import { convertToBackendReportFilter } from "source/components/matrix/utils";
import LogRocket from "logrocket";
import {
  SearchWebsocketEvents,
  SearchWebsocketResponse,
} from "source/types/useSearch.types";
import { backendFilterToFiltersKeyValuePairs } from "source/utils/filters";
import { useDeepCompareCallback } from "use-deep-compare";

export const handleSearchResponse = async (
  data: SearchResponseType,
  dispatch: Dispatch<AnyAction>,
  keyword_only?: boolean
) => {
  // Set previous search to have ran exact match search if there was actually
  // an exact match search that was run, and it returned results
  dispatch(
    setRanExactMatchSearch(
      data.ran_exact_match_search && data.results.length > 0
    )
  );

  // Assign id and page to each result
  data.results = data.results.map((r) => ({ ...r, page: 0 }));

  dispatch(setSearchPageNumber(1));
  if (keyword_only) dispatch(upsertKeywordSearchResponse(data));
  else dispatch(upsertSearchResponse(data));

  // Upsert pins
  if (data.pins?.length) {
    dispatch(upsertPins(data.pins));
  }
};

type SlackMessageType =
  | "no_results"
  | "closed_early"
  | "search_error"
  | "unexpected_error";

const getSlackMessage = (
  messageType: SlackMessageType,
  user: UserType | null
) => {
  const href = window.location.href;
  let logRocketURL = "";
  LogRocket.getSessionURL((sessionURL) => {
    logRocketURL = sessionURL;
  });
  switch (messageType) {
    case "no_results":
      return `No results for search query ${href} by user ${user?.email}${
        logRocketURL ? `\n\nLogRocket: ${logRocketURL}` : ""
      }\n\n${GOALIE_USERGROUP}`;
    case "closed_early":
      return `Search was closed before results were returned for query ${href} by user ${user?.email}`;
    case "search_error":
      return `Search error for query ${href} by user ${user?.email}`;
    default:
      return `Unexpected websocket error for query ${href} by user ${user?.email}`;
  }
};

// Sends a slack alert if we got no search results. Does not fire if the query
// contains an exact match phrase (indicated by double quotes).
const slackOnNoResults = async (
  results: ResultType[],
  messageType: SlackMessageType,
  currentUser: UserType | null,
  query?: string,
  onlyMatterId?: boolean
) => {
  if (results.length > 0 || onlyMatterId) return;
  else if (
    // Don't message for no results on exact keyword searches that might not actually
    // have results
    messageType === "no_results" &&
    !!query &&
    query.includes(`"`)
  )
    return;

  const message = getSlackMessage(messageType, currentUser);
  sendSlackAlert(message, SLACK_WEBHOOK_URL);
};

export const useSearch = () => {
  const dispatch = useDispatch();
  const { logPosthog } = useLogPosthog();
  const currentDoc = useCurrentDoc();
  const currentRepo = useCurrentRepo();
  const params = useSearchParams();
  const { results, pageNumber } = useSearchResults();
  const currentUser = useSelector(getUser);
  const currentOrg = useSelector(getCurrentOrg);
  const sidebarRepos = useSidebarRepos();
  const repoTargets = useSelector(getTargetRepos);
  const targetFilters = useSelector(getReportFilter);

  const resultsReceivedRef = useRef<{
    websocketId: null | string;
    resultsReceived: boolean;
  }>({ websocketId: null, resultsReceived: false });
  const { handleAutoOpening } = useAutoOpenFolders();

  const otherOrgReposIds = useMemo(
    () =>
      sidebarRepos
        .filter(
          (repo) =>
            repo.organization_id && repo.organization_id !== currentOrg?.id
        )
        .map((r) => r.id),
    [sidebarRepos, currentOrg?.id]
  );
  const orgReposIds = useMemo(
    () =>
      sidebarRepos
        .filter((repo) => repo.organization_id === currentOrg?.id)
        .map((r) => r.id),
    [sidebarRepos, currentOrg?.id]
  );
  const personalReposIds = useMemo(
    () =>
      sidebarRepos
        .filter((repo) => !repo.organization_id && repo.private)
        .map((r) => r.id),
    [sidebarRepos]
  );
  const { router } = useGetRouter();

  const websockets = useSelector(getSearchWebsockets);

  const handleSearch = useDeepCompareCallback(
    async (paramsOverride?: Partial<SearchParams>) => {
      logPosthog(PosthogObject.SEARCH, PosthogAction.QUERY_RECEIEVED);
      dispatch(setNoDocsFilters(null));
      dispatch(setSearchNoDocsVisible(false));
      // Override search params with passsed in params
      const searchParams = {
        ...params,
        ...paramsOverride,
      };
      const filters = (searchParams.filters as FilterType[]) || undefined;
      if (
        filters?.length ||
        searchParams.repo_ids.length < sidebarRepos.length
      ) {
        logPosthog(
          PosthogObject.SEARCH,
          PosthogAction.FILTERED_SEARCH,
          searchParams
        );
      } else {
        logPosthog(
          PosthogObject.SEARCH,
          PosthogAction.GLOBAL_SEARCH,
          searchParams
        );
      }

      const pathname = currentRepo ? `/repos/${currentRepo.id}` : "/search";
      const queryObj = buildStringifiedQuery({
        ...searchParams,
        repo_ids: repoTargets,
      });

      const objUrl = {
        pathname,
        query: queryObj,
      };
      router.push(objUrl, undefined, { shallow: true });

      if (
        !searchParams.query?.length ||
        (!searchParams.repo_ids?.length && !searchParams.doc_ids?.length) ||
        !searchParams
      )
        return;

      const websocketId = uuidv4();
      console.info("Opened search websocket with id", websocketId);
      // set websocket as current loading controller
      dispatch(setSearchLoadingController(websocketId));
      // reset the cache for single doc searches, only used by the doc viewer modal.
      dispatch(resetSingleDocSearchResults());

      dispatch(setGTDAnswer({}));
      dispatch(setKeywordGTDAnswer({}));
      dispatch(setSearchXBRLFinancials({}));
      dispatch(setSearchXBRLDocId(null));
      dispatch(setGTDAnswerLoading(true));
      dispatch(setKeywordGTDAnswerLoading(true));
      dispatch(setGTDAnswerFinished(false));
      dispatch(setKeywordGTDAnswerFinished(false));
      dispatch(setGTDSummariesLoading(true));
      dispatch(setSearchResponseLoading(true));
      dispatch(setKeywordResponseLoading(true));
      dispatch(setSpellCheckQuery(null));
      dispatch(setOriginalQuery(searchParams.query ?? null));
      dispatch(setSearchError(null));
      dispatch(setKeywordSearchError(null));
      dispatch(setRanExactMatchSearch(false));
      dispatch(setTasks([]));
      dispatch(setTasksToDisplay([]));
      dispatch(setSearchRunning(true));
      // Always switch back to semantic results on search
      dispatch(setKeywordSearchActive(false));
      dispatch(setSearchAutoToggled(false));

      dispatch(setSearchBarFocused(false));

      const backendFilter = convertToBackendReportFilter(targetFilters);
      dispatch(setNoDocsFilters(backendFilter));

      // If only filter is a MatterId (for M&M), then no search results found means
      // the matter id is not present in Hebbia. We use this info to determine if we
      // should post to slack for no results and to log to posthog.
      const filterKeyValues =
        backendFilterToFiltersKeyValuePairs(backendFilter);
      const onlyMatterId =
        filterKeyValues.length === 1 && filterKeyValues[0]?.key === "matter_id";

      if (
        currentUser?.platform_role !== "admin" &&
        (!currentOrg ||
          (currentOrg && currentOrg.id !== MORGAN_MORGAN_ORG_ID)) &&
        (backendFilter.values as BackendReportFilter[]).length > 0
      ) {
        try {
          const counts = await api.advancedfilters.getTripletDocCount(
            backendFilter,
            currentOrg?.id
          );
          if (counts.doc_count === 0) {
            dispatch(setSearchNoDocsVisible(true));
            dispatch(setSearchResponseLoading(false));
            dispatch(setGTDAnswerLoading(false));
            dispatch(setKeywordGTDAnswerLoading(false));
            dispatch(setGTDAnswerFinished(true));
            dispatch(setKeywordGTDAnswerFinished(true));
            dispatch(setGTDSummariesLoading(false));
            dispatch(setKeywordResponseLoading(false));
            dispatch(setSearchRunning(false));
            return;
          }
        } catch (e) {
          console.error("Error getting triplet doc count: ", e);
        }
      }

      try {
        if (!currentDoc) {
          // Scroll back to top upon new search
          window.scrollTo(0, 0);
        }

        // Clear current selection so single doc search can scroll docviewer
        dispatch(setCurrentSelectionId());
        $("#search-input").trigger("blur");

        const is_single_doc_search = searchParams.doc_ids?.length === 1;

        let accessToken = getAuthToken();
        if (!accessToken) {
          const response = await fetchAccessToken();
          accessToken = response.accessToken;
          setAuthToken(accessToken);
        }

        const ws = new WebSocket(`${WEBSOCKET_URL}/search/ws`);

        // upsert websocket
        dispatch(upsertWebsocket({ ws, id: websocketId }));
        resultsReceivedRef.current = {
          websocketId,
          resultsReceived: false,
        };

        const searchMessage = {
          ...searchParams,
          page: 0,
          is_single_doc_search: is_single_doc_search,
        };

        ws.onopen = () => {
          ws.send(
            JSON.stringify({
              access_token: accessToken,
            })
          );
          ws.send(JSON.stringify(searchMessage));
        };

        ws.onerror = (event) => {
          const error = "Error opening websocket connection";
          dispatch(setSearchError({ error, websocketId }));
          dispatch(setKeywordSearchError({ error, websocketId }));
          dispatch(setSearchResponseLoading(false));
          dispatch(setSearchRunning(false));
          dispatch(setKeywordResponseLoading(false));
          console.error("Websocket error, ws id", websocketId, error);
        };

        ws.onclose = (event) => {
          dispatch(removeWebsocket(websocketId));
          dispatch(setGTDAnswerFinished(true));
          dispatch(setSearchRunning(false));
          dispatch(setKeywordGTDAnswerFinished(true));
          if (resultsReceivedRef.current.websocketId === websocketId)
            dispatch(setSearchResponseLoading(false));
          console.info("Closed search websocket with id", websocketId);
        };

        ws.onmessage = (event) => {
          const res = JSON.parse(event.data) as SearchWebsocketResponse;

          // listen to synonyms
          if (res.event === SearchWebsocketEvents.SEARCH_SYNONYMS) {
            const payload = res.payload;
            dispatch(setActiveSynonyms(payload));
          }

          if (res.event === "query_expansion") {
            const payload = res.payload;

            dispatch(
              setSearchDebugQueryExpansion(JSON.stringify(payload, null, 2))
            );
          }
          if (res.event === SearchWebsocketEvents.SEC_FINANCIALS) {
            const payload = res.payload;
            dispatch(setSearchXBRLFinancials(payload.financials));
            dispatch(setSearchXBRLDocId(payload.doc_id));
          }
          if (res.event === SearchWebsocketEvents.SEARCH_ERROR) {
            const error = "search error";
            dispatch(setSearchError({ error, websocketId }));
            dispatch(setSearchResponseLoading(false));
            logPosthog(PosthogObject.SEARCH, PosthogAction.ERROR);
            slackOnNoResults(
              [],
              "search_error",
              currentUser ?? null,
              searchParams.query
            );
          }
          if (res.event === SearchWebsocketEvents.KEYWORD_SEARCH_ERROR) {
            const error = "keyword search error";
            dispatch(setKeywordSearchError({ error, websocketId }));
            dispatch(setKeywordResponseLoading(false));
          }
          if (res.event === SearchWebsocketEvents.SEARCH_RESPONSE) {
            // This is where we get search results back
            const reducedPayload = {
              ...res.payload,
              results: res.payload.results.filter(
                (r) => !otherOrgReposIds.includes(r.repo_id)
              ),
            };

            handleSearchResponse(reducedPayload, dispatch);
            dispatch(setSearchResponseLoading(false));
            if (resultsReceivedRef.current.websocketId === websocketId)
              resultsReceivedRef.current.resultsReceived = true;

            logPosthog(PosthogObject.SEARCH, PosthogAction.SEARCH_COMPLETE);
            if (onlyMatterId && results.length === 0) {
              logPosthog(PosthogObject.SEARCH, PosthogAction.INVALID_MATTER_ID);
            }

            if (results.length === 0) {
              slackOnNoResults(
                reducedPayload.results,
                "no_results",
                currentUser ?? null,
                searchParams.query,
                onlyMatterId
              );
            }
          }
          if (res.event === SearchWebsocketEvents.KEYWORD_SEARCH_RESPONSE) {
            handleSearchResponse(res.payload, dispatch, true);
            dispatch(setKeywordResponseLoading(false));
          }
          if (res.event === SearchWebsocketEvents.ANSWER_CITATIONS) {
            if (res.payload) dispatch(setGTDResultIds(res.payload));
          }
          if (res.event === SearchWebsocketEvents.KEYWORD_ANSWER_CITATIONS) {
            if (res.payload) dispatch(setKeywordGTDResultIds(res.payload));
          }
          if (res.event === SearchWebsocketEvents.SUMMARIES) {
            dispatch(upsertGTDSummaries(res.payload));
            dispatch(setGTDSummariesLoading(false));
          }
          if (res.event === SearchWebsocketEvents.KEYWORD_SUMMARIES) {
            dispatch(upsertGTDSummaries(res.payload));
          }
          if (res.event === SearchWebsocketEvents.PLAIN_ANSWER) {
            dispatch(setGTDPlainAnswer(res.payload));
          }
          if (res.event === SearchWebsocketEvents.ANSWER) {
            if (res.payload) {
              if (!(typeof res.payload === "string")) {
                dispatch(upsertGTDAnswerAnswer(res.payload.content));
                dispatch(setGTDAnswerDegraded(res.payload.degraded));
              } else {
                dispatch(upsertGTDAnswerAnswer(res.payload));
              }
            }
            dispatch(setGTDAnswerLoading(false));
          }
          if (res.event === SearchWebsocketEvents.KEYWORD_ANSWER) {
            if (res.payload) {
              if (!(typeof res.payload === "string")) {
                dispatch(upsertGTDKeywordAnswer(res.payload.content));
                dispatch(setGTDKeywordAnswerDegraded(res.payload.degraded));
              } else {
                dispatch(upsertGTDAnswerAnswer(res.payload));
              }
            }
            dispatch(setKeywordGTDAnswerLoading(false));
          }
          if (res.event === SearchWebsocketEvents.ANSWER_FINISH) {
            dispatch(setGTDAnswerFinished(true));
          }
          if (res.event === SearchWebsocketEvents.KEYWORD_ANSWER_FINISH) {
            dispatch(setKeywordGTDAnswerFinished(true));
          }
          if (res.event === SearchWebsocketEvents.CHECK_ANSWER) {
            dispatch(setGTDCheckAnswer(res.payload));
          }
          if (res.event === SearchWebsocketEvents.SUGGESTED_QUESTION) {
            dispatch(setGTDSuggestedQuestion(res.payload));
          }
          if (res.event === SearchWebsocketEvents.TOGGLE_KEYWORD) {
            if (res.payload) {
              dispatch(setSearchAutoToggled(true));
              dispatch(setKeywordSearchActive(res.payload));
            }
          }

          if (res.event === SearchWebsocketEvents.APPLIED_DATE) {
            const dateFilters: FilterType[] = res.payload;

            const startDateFilter: FilterType | undefined = dateFilters.find(
              (filter) => filter.key === "start_date"
            );

            const endDateFilter: FilterType | undefined = dateFilters.find(
              (filter) => filter.key === "end_date"
            );

            // Set applied date
            const startDate = startDateFilter
              ? new Date(startDateFilter.value)
              : null;
            const endDate = endDateFilter
              ? new Date(endDateFilter.value)
              : null;

            const dateFilter = { startDate, endDate };

            // Set applied date
            dispatch(setSearchAppliedDateFilter(dateFilter));
          }
          if (res.event === SearchWebsocketEvents.SORT_KEY) {
            const sort_key: SortKey = res.payload;
            if (!searchParams.sort_by || searchParams.sort_by == sort_key)
              dispatch(setSearchSortKey(sort_key));
          }
          if (res.event === SearchWebsocketEvents.APPLIED_FILTERS) {
            const filters: FilterType[] = res.payload ?? [];

            dispatch(upsertSearchAppliedFilters(filters));
            // new application method 😎
            dispatch(upsertTargetAppliedFilters(filters));
            // Auto-open sidebar section
            dispatch(setOpenSidebarSection(COMPANY_SECTION_TITLE));
            const docFilters =
              filters
                ?.filter(
                  (filter) => filter.key === FiltersConfig.CUSTOM_FILTER_KEY_DOC
                )
                .map((filter) => filter.value) ?? [];

            docFilters.forEach((docId) => {
              handleAutoOpening(docId);
            });
          }
          if (res.event === SearchWebsocketEvents.SUGGESTED_FILTERS) {
            const filters: FilterType[] = res.payload ?? [];
            dispatch(setSearchSuggestedFilters(filters));
            dispatch(upsertTargetSuggestedFilters(filters));
          }
          if (res.event === SearchWebsocketEvents.COMPLETE) {
            ws.close();
            dispatch(setSearchLoadingController(null));
          }
          if (res.event === SearchWebsocketEvents.HYDE_OUTPUT) {
            const hydeOutput = res.payload;
            dispatch(setSearchDebugHydeOutput(hydeOutput));
          }
          if (res.event === SearchWebsocketEvents.TASK) {
            // Expect full tool object that we update;
            const task = res.payload as TaskType;
            dispatch(upsertTasks([task]));
            if (task.tool_type === "search") {
              dispatch(addTaskToDisplay(task.id));
            }
          }
        };

        api.search
          .log({
            query: searchParams.query,
            page: 0,
            repo_ids: searchParams.repo_ids,
            doc_ids: searchParams.doc_ids,
          })
          .then(({ data }) => {
            const log: SearchHistoryType = data.search;
            if (log && log.query) {
              // Add search to history
              dispatch(
                addSearchHistory({
                  id: log.id,
                  query: log.query,
                  repo_ids: log.repo_ids,
                  created_at: log.created_at,
                  updated_at: log.updated_at,
                })
              );
            }
          });
      } catch (e: any) {
        if (e.name === "CanceledError") {
          // TODO: Better reversion on dispatches
        } else if (e.name) {
          const error = e?.response?.status;
          dispatch(setSearchError({ error, websocketId }));
          dispatch(setKeywordSearchError({ error, websocketId }));
          logPosthog(PosthogObject.SEARCH, PosthogAction.ERROR);
          slackOnNoResults(
            [],
            "unexpected_error",
            currentUser ?? null,
            searchParams.query
          );
        }
      }
    },
    [
      params,
      websockets.length,
      orgReposIds.length,
      personalReposIds.length,
      currentRepo?.id,
    ]
  );

  const handleLoadMore = async (
    callback?: (newResultCount: number, newDocCount: number) => void,
    keyword?: boolean
  ) => {
    try {
      if (!params.query?.length || !params) return;
      const { data } = await api.search.search({
        ...params,
        page: pageNumber + 1,
        ignore_ids: results.map((r) =>
          [r.doc_id, r.build_id ? r.build_id : "", r.passage_id].join(",")
        ), // form a unique passage id
        keywords_only: keyword,
      });
      api.search.log({
        query: params.query,
        page: pageNumber + 1,
        repo_ids: params.repo_ids,
        doc_ids: params.doc_ids,
      });
      // Assign id and page number to each result
      data.results = data.results
        .filter((r) => !otherOrgReposIds.includes(r.repo_id))
        .map((r) => ({
          ...r,
          page: pageNumber + 1,
          id: uuidv4(),
        }));

      if (keyword) {
        dispatch(upsertKeywordResults(data.results));
        dispatch(setKeywordSearchPageNumber(pageNumber + 1));
      } else {
        dispatch(upsertSearchResults(data.results));
        dispatch(setSearchPageNumber(pageNumber + 1));
      }

      if (callback) {
        const newResultCount = data.results.length;
        const existingDocIds = results.map((r) => r.doc_id);
        const newDocCount = data.results.filter(
          (r) => !existingDocIds.includes(r.doc_id)
        ).length;
        callback(newResultCount, newDocCount);
      }
      if (data.pins?.length) dispatch(upsertPins([data.pins]));
    } catch (e: any) {
      if (keyword) dispatch(setKeywordSearchError(e?.response?.status));
      else dispatch(setSearchError(e?.response?.status));
    }
  };

  const top5Results: ResultType[] = results.slice(0, 5);
  const top5ResultIds: string[] = top5Results.map((r) => r.id);

  const handleReloadGTDAnswer = useDeepCompareCallback(async () => {
    if (params.query) {
      const top5Results: ResultType[] = results.slice(0, 5);
      dispatch(setGTDAnswerLoading(true));
      setGTDAnswer({});
      api.search
        .gtd({
          query: params.query,
          results: top5Results,
          use_rephrase: router.query.use_rephrase === true,
        })
        .then(({ data }) => {
          const {
            answer,
            answer_type: answerType,
            answer_check: checkAnswer,
            query_action: queryAction,
            suggested_question: suggestedQuestion,
            summaries,
            plain_answer: plainAnswer,
          } = data;
          dispatch(
            setGTDAnswer({
              answer,
              answerType,
              checkAnswer,
              queryAction,
              suggestedQuestion,
              resultIds: top5Results.map((r) => r.id),
              summaries,
              plainAnswer,
            })
          );
          dispatch(setGTDAnswerLoading(false));
        })
        .catch((err) => {
          dispatch(setGTDAnswerLoading(false));
          dispatch(setGTDAnswer({}));
        });
    }
  }, [params.query, top5ResultIds]);

  return {
    handleSearch,
    handleLoadMore,
    handleReloadGTDAnswer,
  };
};

// Sometimes we need to call handle search after a redux update in the same function.
// Instead of directly calling the useSearch hook, we actually need to create a reference,
// and call the current value of the reference.
export const useHandleSearchRef = () => {
  // Grab the handle search function
  const { handleSearch: handleSearchExport } = useSearch();
  // Throw in a ref
  const handleSearchRef = useRef<typeof handleSearchExport>(handleSearchExport);

  useEffect(() => {
    // Set value to current for redundancy
    handleSearchRef.current = handleSearchExport;
  }, [handleSearchExport]);

  // mock a handle search function that access the most current ref
  const handleSearch = async (paramsOverride?: Partial<SearchParams>) => {
    // I know set Timeouts are bad, but my thoughts are that redux doesn't
    // update the ref in time. Until we have a better way of handling this,
    // BIG TODO: Ammar & Swetha
    await new Promise((resolve) => setTimeout(resolve, 200));
    await handleSearchRef.current(paramsOverride);
  };

  return { handleSearch };
};
