import { useMemo } from "react";
import { useCachedRepos } from "source/api/repos/useQueryRepos";
import { RepoType } from "source/Types";
import { useSidebarRepos } from "../useSidebarRepos";

/** Hook that returns all currently fetched repos. Returns the concatenation
 * of sidebar repos with individual repos stored in the react query cache */
export const useAllRepos = () => {
  const sidebarRepos = useSidebarRepos();
  const cachedRepos = useCachedRepos();

  const allRepos = useMemo(
    () => [...sidebarRepos, ...cachedRepos],
    [sidebarRepos, cachedRepos]
  );

  return allRepos;
};

export const useReposDict = () => {
  const repos = useAllRepos();

  const reposDict: Record<string, RepoType> = useMemo(() => {
    const dict = {};
    repos.forEach((repo) => (dict[repo.id] = repo));
    return dict;
  }, [repos]);

  return reposDict;
};
