import React from "react";
import { TooltipHotKey } from "source/components/shared/Buttons/TooltipHotKey";
import { CmdCtrlButton } from "source/components/shared/Buttons/CmdCtrlButton";
import { KeyboardDoubleArrowLeftSharp } from "@mui/icons-material";

type Props = {
  handleClick: () => void;
  visible: boolean;
};

export const ToggleSidebarClosedButton = ({ handleClick, visible }: Props) => (
  <div
    className={`${
      visible ? "visible" : "invisible"
    } mr-1.5 mt-5 cursor-pointer text-secondary hover:text-darkBlue`}
    onClick={handleClick}
  >
    <TooltipHotKey
      icon={<CmdCtrlButton classOverride="text-background" />}
      prefix="Close sidebar"
      commands={["\\"]}
      enterDelay={250}
    >
      <span>
        <KeyboardDoubleArrowLeftSharp />
      </span>
    </TooltipHotKey>
  </div>
);
