import { Switch } from "antd";
import { getRepoColor } from "source/components/repo/lib";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { areEqual } from "source/utils/helpers";
import { ReduxState } from "source/redux";
import {
  DebugFlagType,
  setDebugFlagFalse,
  setDebugFlagTrue,
} from "source/redux/debug";
import { getSearchDebug } from "source/redux/search";
import { getUser } from "source/redux/user";
import { RepoType, StyleSheet } from "source/Types";

interface Props {
  repo?: RepoType;
}

export const SearchDebug = React.memo(({ repo }: Props) => {
  const user = useSelector(getUser);
  const isDebug = useSelector(getSearchDebug);
  const color = getRepoColor(repo);
  const dispatch = useDispatch();

  const {
    shouldRerank,
    hitStagingReranker,
    useTitles,
    rawColbertResults,
    useRephrase,
    useHyde,
  } = useSelector((state: ReduxState) => state.debugFlag);

  if (
    !isDebug ||
    (user?.platform_role !== "admin" && user?.platform_role !== "labeler")
  )
    return null;

  const handleChange = (debugFlag: DebugFlagType, value: boolean) => {
    if (value) dispatch(setDebugFlagTrue(debugFlag));
    else dispatch(setDebugFlagFalse(debugFlag));
  };

  return (
    <div style={styles.container}>
      <Switch
        checked={shouldRerank}
        onChange={() => handleChange("shouldRerank", !shouldRerank)}
        size="small"
        style={shouldRerank ? { background: color } : {}}
      />
      <ToggleLabel>Reranker</ToggleLabel>
      <Switch
        checked={hitStagingReranker}
        onChange={() => handleChange("hitStagingReranker", !hitStagingReranker)}
        size="small"
        style={hitStagingReranker ? { background: color } : {}}
      />
      <ToggleLabel>Staging Reranker</ToggleLabel>
      <Switch
        checked={useTitles}
        onChange={() => handleChange("useTitles", !useTitles)}
        size="small"
        style={useTitles ? { background: color } : {}}
      />
      <ToggleLabel>Allow Titles</ToggleLabel>
      <Switch
        checked={rawColbertResults}
        onChange={() => handleChange("rawColbertResults", !rawColbertResults)}
        size="small"
        style={rawColbertResults ? { background: color } : {}}
      />
      <ToggleLabel>Raw ColBERT Results</ToggleLabel>
      <Switch
        checked={useRephrase}
        onChange={() => handleChange("useRephrase", !useRephrase)}
        size="small"
        style={useRephrase ? { background: color } : {}}
      />
      <ToggleLabel>Use Rephrase</ToggleLabel>
      <Switch
        checked={useHyde}
        onChange={() => handleChange("useHyde", !useHyde)}
        size="small"
        style={useHyde ? { background: color } : {}}
      />
      <ToggleLabel>Use Hyde</ToggleLabel>
    </div>
  );
}, areEqual);

export const ToggleLabel = styled.p`
  color: var(--text-secondary);
  margin: 0px 12px 0px 4px;
`;

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    padding: 12,
  },
};
