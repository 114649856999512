import React from "react";
import { useSelector } from "react-redux";
import { getSectionSearchStates } from "source/redux/sidebar";
import { DocumentType, RepoType } from "source/Types";
import { LoadingRow } from "../components/LoadingRow";
import { DocRowAndChildren } from "../components/DocRowAndChildren";

type Props = {
  repos: RepoType[];
  docs: DocumentType[];
  companyRepos: RepoType[];
  publicRepos: RepoType[];
  pitchbookRepos: RepoType[];
  isLoading: boolean;
  showHeaders?: boolean;
  noResultMessage?: string;
};

export const EntityTitleSearchSection = ({
  repos,
  docs,
  companyRepos,
  publicRepos,
  pitchbookRepos,
  isLoading,
  showHeaders = true,
  noResultMessage = "No sources or document titles found.",
}: Props) => {
  const sectionSearchStates = useSelector(getSectionSearchStates);
  const documentsSearch = sectionSearchStates["Documents"];

  if (
    !docs?.length &&
    !repos?.length &&
    !companyRepos?.length &&
    !pitchbookRepos?.length &&
    !publicRepos?.length &&
    !isLoading
  )
    return (
      <div className="truncate pb-2 pl-5 pt-1 text-left text-sm">
        {noResultMessage}
      </div>
    );

  return (
    <>
      <div>
        {!!docs?.length && showHeaders && (
          <div className="space-between flex items-center p-3 text-xs font-medium uppercase text-tertiary">
            Documents
          </div>
        )}
      </div>
      {docs?.map((doc) => (
        <DocRowAndChildren
          doc={doc}
          key={doc.id}
          idPrefix={"d"}
          keywordBolding={{
            shouldKeywordBold: true,
            keywordBoldStrictMatch: true,
            filterTitle: documentsSearch?.value,
          }}
          depth={0}
        />
      ))}
      {isLoading ? <LoadingRow depth={0} /> : null}
    </>
  );
};
