import React, { useEffect } from "react";
import RModal from "react-modal";
import { areEqual } from "source/utils/helpers";
import { StyleSheet } from "source/Types";
import $ from "jquery";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  visible: boolean;
  hide?: (e?) => void;
  hideX?: boolean;
  children?: React.ReactNode;
  overrideStyle?: any;
  overrideStyleModal?: any;
  enableScrollLock?: boolean;
}

export const Modal = React.memo(
  ({
    visible,
    hide,
    children,
    hideX,
    overrideStyle,
    overrideStyleModal,
    enableScrollLock = true,
  }: Props) => {
    // prevent page scrolling when modal is open
    useEffect(() => {
      if (enableScrollLock)
        if (visible) $("body").css("position", "fixed");
        else $("body").css("position", "relative");
    }, [visible, enableScrollLock]);

    return (
      <RModal
        isOpen={visible}
        onRequestClose={hide}
        style={{
          ...styles,
          ...overrideStyleModal,
          content: {
            ...styles.content,
            ...overrideStyle,
          },
        }}
        ariaHideApp={false}
      >
        {hideX ? null : (
          <div className="absolute right-0 top-0 z-10 m-4 cursor-pointer text-darkGray hover:bg-hoverIcon">
            <CloseIcon onClick={hide} fontSize="medium" />
          </div>
        )}
        {children}
      </RModal>
    );
  },
  areEqual
);

const styles: StyleSheet = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 0,
    border: "none",
    maxWidth: "600px",
    maxHeight: "800px",
    width: "100%",
    padding: "20px",
    background: "var(--card)",
    overflow: "scroll",
  },
  overlay: {
    background: "rgba(0, 0, 0, .17)",
    backdropFilter: "blur(4px)",
    width: "100%",
    zIndex: 1001,
  },
};
