import React from "react";
import { BottomButton } from "./BottomButton";
import { HomeButton } from "./HomeButton";
import { Tabs } from "./tabs/Tabs";
import { TAB_BAR_WIDTH } from "./config";

export const TabBar = () => (
  <div
    className="sticky left-0 top-0 z-30 h-screen flex-shrink-0 overflow-y-auto overscroll-none border-r border-gray-200 bg-brand-50"
    style={{
      width: TAB_BAR_WIDTH,
    }}
  >
    <div className="flex h-full w-full flex-col items-center gap-3 p-2 pt-[13px]">
      <HomeButton />
      <Tabs />
      <div className="flex-grow" />
      <BottomButton />
    </div>
  </div>
);
