import { useSelector } from "react-redux";
import {
  getKeywordPageNumber,
  getKeywordResponseLoading,
  getKeywordSearchActive,
  getKeywordSearchResults,
  getSearchPageNumber,
  getSearchResponseLoading,
  getSearchResults,
} from "source/redux/search";

export const useSearchResults = () => {
  const semanticResults = useSelector(getSearchResults);
  const keywordResults = useSelector(getKeywordSearchResults);
  const semanticLoading = useSelector(getSearchResponseLoading);
  const keywordLoading = useSelector(getKeywordResponseLoading);
  const keywordSearchActive = useSelector(getKeywordSearchActive);
  const semanticPageNumber = useSelector(getSearchPageNumber);
  const keywordPageNumber = useSelector(getKeywordPageNumber);

  const results = keywordSearchActive ? keywordResults : semanticResults;
  const loading = keywordSearchActive ? keywordLoading : semanticLoading;
  const pageNumber = keywordSearchActive
    ? keywordPageNumber
    : semanticPageNumber;
  return {
    semanticResults,
    keywordResults,
    keywordSearchActive,
    results,
    loading,
    pageNumber,
  };
};
