import React from "react";
import { DOC_TAB_WIDTH } from "source/constants";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";

type Props = {
  depth: number;
  onClick: (e: React.MouseEvent) => void;
  styleOverride?: React.CSSProperties;
};

export const SeeMore = ({ depth, onClick, styleOverride }: Props) => (
  <div
    className="flex h-8 cursor-pointer items-center pb-4 pt-5 text-quaternary hover:bg-hoverRow active:bg-hoverRowActive"
    style={{
      paddingLeft: 20 + depth * DOC_TAB_WIDTH,
      ...styleOverride,
    }}
    onClick={onClick}
  >
    <div className="-ml-1 w-fit p-1">
      <ExpandMoreSharpIcon fontSize="small" />
      <span className="ml-2 overflow-hidden text-ellipsis whitespace-nowrap">
        Show more
      </span>
    </div>
  </div>
);
