import { useEffect, useMemo, useState } from "react";

/** Hook that given an HTML element will track when it is on or off screen */
export const useIsOnScreen = (scrollRefElem: HTMLElement | undefined) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry?.isIntersecting ?? false),
        // Root margin offsets height of bottom bar
        { rootMargin: "0px 0px -160px 0px" }
      ),
    [scrollRefElem]
  );

  useEffect(() => {
    if (scrollRefElem) observer.observe(scrollRefElem);
    return () => observer.disconnect();
  }, [scrollRefElem]);

  return isIntersecting;
};
