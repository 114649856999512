import React from "react";
import { pluralize } from "source/utils/common/strings";
import { RepoType } from "source/Types";
import { useDispatch } from "react-redux";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
import { setShowUnsupportedDocs } from "source/redux/ui";
import ActivityIndicator from "react-loader-spinner";
import { useQueryDocCounts } from "source/api/docs/useQueryDocs";
import { useBuildStatus } from "source/hooks/build/useBuildStatus";
import { useSelector } from "react-redux";
import {
  getTargetDocs,
  getTargetNonPredefinedFilters,
} from "source/redux/advanced";
import { getCurrentOrg } from "source/redux/organization";
import { MORGAN_MORGAN_ORG_ID } from "source/constants";

type Props = {
  repo?: RepoType;
};

export const SearchResultCount = ({ repo }: Props) => {
  const docTargets = useSelector(getTargetDocs);
  const filterTargets = useSelector(getTargetNonPredefinedFilters);
  const { firstBuild } = useBuildStatus(repo);
  const currentOrg = useSelector(getCurrentOrg);
  const countsForDocTargets = useQueryDocCounts(
    docTargets,
    filterTargets,
    repo?.id
  );
  const dispatch = useDispatch();

  const showUnsupportedDocsModal = () => {
    dispatch(setShowUnsupportedDocs(true));
  };

  if (!repo || firstBuild) return null;

  // If loading doc counts
  if (!countsForDocTargets)
    return (
      <div className="ml-1 mr-4 flex cursor-default items-center justify-center whitespace-nowrap">
        <ActivityIndicator
          type="TailSpin"
          color="var(--text-bluePurple)"
          height={20}
          width={20}
        />
      </div>
    );

  return (
    <>
      {!isNaN(countsForDocTargets.success) && (
        <div className="ml-1 mr-4 flex cursor-default items-center justify-center whitespace-nowrap font-medium text-bluePurple">
          <span className="flex align-middle text-lg">
            <CheckCircleOutlineSharpIcon fontSize="inherit" />
          </span>
          <span className="ml-1">
            Searching {countsForDocTargets.success}{" "}
            {pluralize(countsForDocTargets.success, "doc")}
          </span>
        </div>
      )}
      {!!countsForDocTargets.building && (
        <div className="ml-1 mr-4 flex items-center justify-center whitespace-nowrap font-normal text-secondary">
          <span className="flex align-middle text-lg">
            <ActivityIndicator
              type="TailSpin"
              color="var(--text-bluePurple)"
              height={20}
              width={20}
            />
          </span>
          <span className="ml-1">
            Still indexing: {countsForDocTargets.building}{" "}
            {pluralize(countsForDocTargets.building, "doc")}
          </span>
        </div>
      )}
      {!!countsForDocTargets.failed &&
        (!currentOrg ||
          (currentOrg && currentOrg.id !== MORGAN_MORGAN_ORG_ID)) && (
          <div
            className="ml-1 mr-4 flex cursor-pointer items-center justify-center whitespace-nowrap font-normal text-secondary hover:text-bluePurple"
            onClick={showUnsupportedDocsModal}
          >
            <span className="flex align-middle text-lg">
              <ErrorOutlineSharpIcon fontSize="inherit" />
            </span>
            <span className="ml-1">
              Unable to search {countsForDocTargets.failed}{" "}
              {pluralize(countsForDocTargets.failed, "doc")}
            </span>
          </div>
        )}
    </>
  );
};
