import { Layout } from "antd";
import { TriadBack } from "source/components/shared/TriadBack";
import { Logo } from "pages/verify";
import React from "react";
import logoLight from "assets/logoLight.svg";

export const MobileAppLayout = () => (
  <Layout>
    <Layout
      style={{
        boxShadow: "-7px 0 0px -7px rgba(0, 0, 0, 0.2)",
        background: "var(--background)",
        zIndex: 1,
        overflowX: "hidden",
      }}
    >
      <TriadBack fullSize style={{ height: "100vh" }}>
        <div
          className="mx-6 mb-32 mt-24 flex grow flex-col items-center justify-center rounded-md bg-white p-7"
          style={{
            boxShadow: "0px 7px 24px -3px rgba(var(--shadow), 0.7)",
          }}
        >
          <div className="flex grow flex-col items-center justify-center">
            <Logo
              src={logoLight}
              alt="logo"
              style={{ width: 120, height: "auto", cursor: "pointer" }}
            />
            <span className="mt-4 text-center text-xl font-medium">
              Hebbia is better on desktop
            </span>
            <span className="text-center text-base font-light">
              Head over to our site on a larger device, we will be on mobile
              soon!
            </span>
          </div>

          <span className="mt-16 text-center text-sm font-light">
            Using Hebbia, you can make decisions 10X more confidently and
            quickly with AI-powered insights.
          </span>
        </div>
      </TriadBack>
    </Layout>
  </Layout>
);
