import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "source/components/shared/Tooltip";
import { getTargetNonPredefinedFilters } from "source/redux/advanced";
import FilterListIcon from "@mui/icons-material/FilterList";

export const MatterIdCard = () => {
  const filterTargets = useSelector(getTargetNonPredefinedFilters);
  const matterId = filterTargets.find((f) => f.key === "matter_id");

  if (!matterId?.value) return null;

  return (
    <Tooltip
      title={"Searching using Matter ID"}
      enterDelay={400}
      enterNextDelay={400}
    >
      <div className="ml-1 mr-5 flex flex-nowrap items-center border border-border2 px-2.5 py-1 text-xs font-medium text-hebbiblue">
        <FilterListIcon fontSize="small" />
        <span className="ml-2 text-black">{matterId.value}</span>
      </div>
    </Tooltip>
  );
};
