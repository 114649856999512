import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

interface Props {
  searchFn: () => void;
  placeholderText: string;
  searchDisabled?: boolean;
  inputQuery: string;
  showSearchInput: boolean;
  onChange: (e) => void;
  onFocus?: () => void;
  inputId: string;
  overrideStyles?: React.CSSProperties;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  multiline?: boolean;
}

export const CommonChatInputBox = ({
  searchFn,
  placeholderText,
  onChange,
  inputQuery,
  searchDisabled,
  showSearchInput,
  inputId,
  overrideStyles,
  onFocus,
  inputRef,
  multiline = false,
}: Props) => {
  const [searchFocused, setSearchFocused] = useState(false);

  const fallbackRef = useRef(null);
  const actualInputRef = inputRef || fallbackRef;

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter" && (!multiline || !e.shiftKey)) {
      e.preventDefault();
      searchFn();
    }
  };

  useEffect(() => {
    if (actualInputRef?.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      actualInputRef.current.style.height = "0px";
      const scrollHeight = actualInputRef.current.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      actualInputRef.current.style.height = scrollHeight + "px";
    }
  }, [actualInputRef, inputQuery]);

  return (
    <div
      className="mt-0 w-full overflow-hidden rounded-none bg-white0 text-primary"
      style={{
        boxShadow:
          "0px 2px 4px 0px rgba(var(--shadow), 1), 0px 2px 21px -7px rgba(var(--shadow), 1)",
        ...overrideStyles,
      }}
    >
      <div
        className="flex h-full flex-row items-center justify-between"
        style={{
          backgroundColor: searchDisabled
            ? "rgba(198, 203, 222, 0.2)"
            : undefined,
        }}
      >
        <div className="flex h-full w-full flex-row items-center justify-start">
          {showSearchInput && (
            <>
              <textarea
                className="w-full resize-none border-none bg-white0 py-4 pl-4 font-['Inter'] text-sm font-normal placeholder-darkGray outline-none"
                ref={actualInputRef ? actualInputRef : undefined}
                placeholder={placeholderText}
                value={inputQuery}
                onChange={onChange}
                style={{
                  backgroundColor: searchDisabled ? "transparent" : undefined,
                }}
                id={inputId}
                data-testid={inputId}
                disabled={searchDisabled}
                autoComplete="off"
                onKeyDown={handleKeyDown}
                onFocus={() => {
                  setSearchFocused(true);
                  if (onFocus) onFocus();
                }}
                onBlur={() => setSearchFocused(false)}
              />
            </>
          )}
          <span
            className={`mx-3 mb-3 mt-auto ${
              inputQuery ? "cursor-pointer bg-hebbiaBlueBg" : "bg-lightBlueBg"
            } px-[9px] py-1.5 text-white`}
            onClick={searchFn}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
            >
              <path
                d="M0.258333 15.5L17.75 8L0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
      </div>
      <div
        className={`relative z-40 -mt-0.5 h-0.5 w-full ${
          searchFocused ? "bg-hebbiaBlueBg" : "bg-lightBlueBg"
        }`}
      />
    </div>
  );
};
