import { FileDndProvider } from "source/components/shared/FileDragAndDrop/FileDndProvider";
import React, { LegacyRef, useState } from "react";
import { ConnectDragSource, ConnectDropTarget } from "react-dnd";
import { useSelector } from "react-redux";
import { DOC_TAB_WIDTH } from "source/constants";
import { isFolder } from "source/utils/documents";
import { useTableRow } from "source/hooks/useTableRow";
import { getSelectedDocId } from "source/redux/search";
import { DocumentType, RepoType } from "source/Types";
import { DocContextMenu } from "./DocContextMenu";
import { SelectableRow } from "./SelectableRow";
import { SidebarInputKeywordBoldingProps } from "./SidebarInput";
import { getTargetDocs } from "source/redux/advanced";
import { useUploadFiles } from "source/hooks/useUploadFile";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";

export type SingleDocRowProps = {
  id: string;
  title: string;
  depth?: number;
  icon?: React.ReactNode | JSX.Element;
  /**
   * badges are icons that always show on the RHS of the sidebar row
   */
  badge?: React.ReactNode;
  children?: React.ReactNode;
  isActive?: boolean;
  doc: DocumentType;
  keywordBolding?: SidebarInputKeywordBoldingProps;
  repo?: RepoType;

  /**
   * Fires when doc row is clicked. Does not fire when checkbox is clicked.
   */
  onDocRowClick?: (e: React.MouseEvent) => void;

  /**
   * Fires when doc row checkbox is clicked. Does not fire when elsewhere on the row is clicked.
   */
  onDocCheckboxClick?: (e: React.MouseEvent) => void;

  onIconClick?: () => void;
  handleDoneEditing?: (newTitle: string) => void;
  style?: any;
  isDndTarget?: boolean;
  dndItem?: any;
  onDrop?: (item: any, monitor: any) => void;
};

export type SingleDocRowWithDragNDropProps = Partial<SingleDocRowProps> & {
  doc: DocumentType;
  setHovering: (val: boolean) => void;
  refObj?: ConnectDragSource | ConnectDropTarget;
  role?: string;
  canDrop?: boolean;
  currentRepo?: RepoType;
  drag: ConnectDragSource;
  drop: ConnectDropTarget;
  isOver: boolean;
};

export const SingleDocRowWithDragNDropBody = ({
  setHovering,
  onDocRowClick,
  children,
  isActive,
  refObj,
  role,
  canDrop,
  isOver,
  doc,
}: SingleDocRowWithDragNDropProps) => (
  <div
    className={`h-8 w-full cursor-pointer items-center hover:bg-hoverRow active:bg-hoverRowActive ${
      isActive &&
      "bg-lightPurple/20 hover:bg-lightPurple/30 active:bg-lightPurple/40"
    }`}
    onMouseOver={() => setHovering(true)}
    onMouseLeave={() => setHovering(false)}
    onClick={(e) => onDocRowClick?.(e)}
    ref={refObj as unknown as LegacyRef<HTMLDivElement>}
    role={role}
    id={`d${doc.id}`}
    style={{
      backgroundColor: canDrop ? (isOver ? "#dadada" : "#eee") : undefined,
      textAlign: "center",
    }}
  >
    {children}
  </div>
);

export const SingleDocRowWithDragNDrop = (
  props: SingleDocRowWithDragNDropProps
) => {
  const { children, doc, canDrop, drop, drag, isOver, isDndTarget, dndItem } =
    props;
  const repo = useCurrentRepo(true);
  const [isDragging, setIsDragging] = useState(false);
  const { uploadFiles } = useUploadFiles({ repo, parentDoc: doc });

  if (isFolder(doc.mime)) {
    return (
      <FileDndProvider
        setIsDragging={setIsDragging}
        uploadFiles={uploadFiles}
        style={
          isDragging || (canDrop && isOver)
            ? "border-2 border-darkBlue"
            : "border-0 border-transparent"
        }
      >
        <SingleDocRowWithDragNDropBody
          refObj={isDndTarget ? drop : dndItem ? drag : undefined}
          role={isDndTarget ? "Dustbin" : dndItem ? "Handle" : undefined}
          {...props}
        >
          {children}
        </SingleDocRowWithDragNDropBody>
      </FileDndProvider>
    );
  }
  return (
    <SingleDocRowWithDragNDropBody
      refObj={isDndTarget ? drop : dndItem ? drag : undefined}
      role={isDndTarget ? "Dustbin" : dndItem ? "Handle" : undefined}
      {...props}
    >
      {children}
    </SingleDocRowWithDragNDropBody>
  );
};

/**
 * SingleDocRow corresponds to exactly one visual row on the sidebar associated with
 * a given document. It's used across both global and intra-repo views.
 *
 * In contrast, DocRowWithChildren is an abstraction around a document and any of its children.
 */
export const SingleDocRow = ({
  id,
  title,
  depth,
  badge,
  children,
  icon,
  isActive,
  doc,
  keywordBolding,
  repo,
  onDocRowClick,
  onDocCheckboxClick,
  handleDoneEditing,
  isDndTarget,
  dndItem,
  onDrop,
}: SingleDocRowProps) => {
  const [isHovering, setHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const targetDocs = useSelector(getTargetDocs);

  const siblingDocActive = !!targetDocs.length;

  const { _handleDoneEditing, canDrop, drag, drop, isOver } = useTableRow({
    isDndTarget,
    handleDoneEditing,
    dndItem,
    onDrop,
  });

  // this is mostly to enable auto-show
  const selectedDocId = useSelector(getSelectedDocId);
  const searchSelected = selectedDocId && selectedDocId === doc?.id;

  return (
    <>
      <SingleDocRowWithDragNDrop
        doc={doc}
        isActive={isActive ?? false}
        onDocRowClick={onDocRowClick}
        dndItem={dndItem}
        setHovering={setHovering}
        canDrop={canDrop}
        isDndTarget={isDndTarget}
        drag={drag}
        drop={drop}
        isOver={isOver}
      >
        <div
          className={
            "flex h-8 w-full cursor-pointer py-1 text-start text-secondary" +
            ` hover:bg-${isActive ? "hoverRowActive" : ""} `
          }
          style={{
            paddingLeft: (depth || 0) * DOC_TAB_WIDTH,
            ...(searchSelected && {
              backgroundColor: "var(--text-bluePurpleLight)",
            }),
          }}
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <div className="flex w-full flex-row justify-between whitespace-nowrap">
            <div className="w-full max-w-[700px]">
              <DocContextMenu doc={doc} repo={repo} setIsEditing={setIsEditing}>
                <SelectableRow
                  badge={badge}
                  isActive={isActive ?? false}
                  siblingActive={siblingDocActive}
                  Icon={icon}
                  handleRowClick={onDocRowClick}
                  handleCheckboxSelect={onDocCheckboxClick}
                  handleRename={_handleDoneEditing}
                  id={id}
                  title={title}
                  rowTooltipOverrideTitle={
                    doc.failure_reason ? "Unsupported Document" : undefined
                  }
                  isHovering={isHovering}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  keywordBolding={keywordBolding}
                  depth={depth}
                  showGoArrow={false}
                  showContextMenu={true}
                />
              </DocContextMenu>
            </div>
          </div>
        </div>
      </SingleDocRowWithDragNDrop>
      {children}
    </>
  );
};
