import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useCurrentRepo } from "./repos/useCurrentRepo";

type Props = {
  isDndTarget?: boolean;
  handleDoneEditing?: (newTitle: string) => void;
  dndItem?: any;
  onDrop?: (item: any, monitor: any) => void;
};

export const useTableRow = ({
  isDndTarget,
  handleDoneEditing,
  dndItem,
  onDrop,
}: Props) => {
  const [isHovering, setHovering] = useState(false);
  const currentRepo = useCurrentRepo(true);

  const _handleDoneEditing = (newTitle: string) => {
    if (handleDoneEditing) handleDoneEditing(newTitle);
  };

  // TODO lukas: dnd stuff should probably be moved up to DocRow
  const [{ isDragging }, drag] = useDrag(
    () => ({
      // "type" is required. It is used by the "accept" specification of drop targets.
      type: "SidebarDoc",
      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      item: dndItem,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [dndItem]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      // The type (or types) to accept - strings or symbols
      accept: "SidebarDoc",
      // Props to collect
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && isDndTarget,
      }),
      drop: onDrop,
    }),
    [isDndTarget]
  );

  return {
    isHovering,
    setHovering,
    currentRepo,
    _handleDoneEditing,
    drag,
    canDrop,
    isOver,
    drop,
  };
};
