import { getRepoColor } from "source/components/repo/lib";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MAIN_SEARCH_INPUT_ID } from "source/constants";
import { areEqual } from "source/utils/helpers";
import { useSearchHotkeys } from "source/hooks/hot-keys/useSearchHotkeys";
import { useRepoDocsDict } from "source/hooks/repos/useRepoDocsDict";
import { useReposDict } from "source/hooks/repos/useAllRepos";
import { useBuildStatus } from "source/hooks/build/useBuildStatus";
import { useCurrentDoc } from "source/hooks/doc-viewer/useCurrentDoc";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useHandleSearchRef } from "source/hooks/search/useSearch";
import { useSearchResults } from "source/hooks/search/useSearchResults";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import {
  getNumDocsCrawling,
  getNumDocsRemaining,
  getNumDocsTotal,
} from "source/redux/build";
import {
  getSearchDebug,
  setSearchAppliedFilters,
  setSearchDebug,
  setSearchDisabledFilters,
  setSearchSuggestedFilters,
} from "source/redux/search";
import { getSelected } from "source/redux/searchDropdown";
import {
  getLinkedResult,
  getSearchBarCopy,
  getSearchBarsFocused,
  getSearchQuery,
  setLinkedResult,
  setSearchBarFocused,
  setSearchQuery,
  setSourcesBarFocused,
} from "source/redux/ui";
import { getUser } from "source/redux/user";
import { pluralize } from "source/utils/common/strings";
import { DocumentType, RepoType } from "source/Types";
import { useCachedRepoDocs } from "source/api/docs/useQueryDocs";
import {
  getTargetDocs,
  getTargetRepos,
  setTargetAppliedFilters,
  setTargetDisabledFilters,
  setTargetSuggestedFilters,
} from "source/redux/advanced";
import {
  PosthogAction,
  PosthogObject,
  useLogPosthog,
} from "source/hooks/tracking/usePosthogTracking";
import { SearchInputBox } from "./components/SearchInputBox";

interface Props {
  repo?: RepoType;
  filteredHistory: string[];
  onSearchChange: (value: string) => void;
  overrideStyles?: React.CSSProperties;
  searchDisabled?: boolean;
}

export const SearchInput = React.memo(
  ({
    repo,
    filteredHistory,
    onSearchChange,
    overrideStyles,
    searchDisabled = false,
  }: Props) => {
    const dispatch = useDispatch();
    const { loading } = useSearchResults();
    const { logPosthog } = useLogPosthog();
    const color = getRepoColor(repo);
    const query: string = useSelector(getSearchQuery);
    const [searchProgress, setSearchProgress] = useState<number | undefined>(
      undefined
    );
    const isDebug = useSelector(getSearchDebug);
    const user = useSelector(getUser);
    const { allFailedDocs, firstBuild, isBuilding } = useBuildStatus(repo);
    const { handleSearch } = useHandleSearchRef();
    const currentDoc = useCurrentDoc();
    const searchBarsFocused = useSelector(getSearchBarsFocused);
    const linkedResult = useSelector(getLinkedResult);

    const { clearSearchState } = useGlobalNavigator();
    const { router } = useGetRouter();

    const repoTargets = useSelector(getTargetRepos);
    const docTargets = useSelector(getTargetDocs);

    const repos = useReposDict();

    // Right now can only select a doc inside a repo, but this will need to be changed when we change this behavior with new sidebar
    const repoDocsDict = useRepoDocsDict(repo?.id);
    const selected = useSelector(getSelected);

    const numDocsCrawling = useSelector(getNumDocsCrawling);
    const numDocsRemaining = useSelector(getNumDocsRemaining);
    const numDocsTotal = useSelector(getNumDocsTotal);

    const repoDocs = useCachedRepoDocs(repo?.id);
    const [allDocs, failedDocs] = _.partition(
      repoDocs,
      (doc: DocumentType) => !doc.failure_reason
    );
    const allEmptyFolders =
      allDocs.length &&
      allDocs.every(
        (cdoc) =>
          (cdoc.type === "hebbia_folder" || cdoc.type === "earnings") &&
          !cdoc.num_children
      );
    const allFailedSidebarDocs = allDocs.length === 0 && failedDocs.length > 0;

    useSearchHotkeys(currentDoc);

    const searchBarCopy = useSelector(getSearchBarCopy);

    const barrelRoll = () => {
      const x = document.body.style;
      const transform =
        x.transform === "rotate(360deg)" ? "rotate(0deg)" : "rotate(360deg)";
      x.setProperty("transform", transform);
      x.setProperty("transition-duration", "1.5s");
      x.setProperty("transition-property", "all");
    };

    // Determines loading progress under search input bar.
    useEffect(() => {
      const interval = 400;
      const totalTime = 7200;

      const timeouts: NodeJS.Timeout[] = [];
      if (loading) {
        setSearchProgress(1);
        for (let i = interval; i <= totalTime; i += interval) {
          timeouts.push(
            setTimeout(() => {
              setSearchProgress((i / totalTime) * 100);
            }, i)
          );
        }
      } else {
        setSearchProgress(0);
      }
      return () => {
        timeouts.forEach((t) => clearTimeout(t));
        if (router.query.query === "do a barrel roll" && !loading) barrelRoll();
      };
    }, [loading]);

    useEffect(() => {
      if (repoTargets.length) dispatch(setSearchBarFocused(true));
      else dispatch(setSearchBarFocused(false));
    }, [repoTargets.length]);

    const handleKeyDown = (e) => {
      // When user backspaces on an empty search input
      // cancel search, clear relevant stuff, delete router.query
      if ((e.key === "Backspace" || e.key === "Delete") && !query) {
        // Clear search state in redux and remove from router
        clearSearchState();
        return;
      }
      if (e.key !== "Enter" || !query.trim().length) return;
      setSearchProgress(0);
      // If currently set result, remove it
      if (linkedResult) dispatch(setLinkedResult(null));
      if (router.query.query !== query) {
        dispatch(setSearchAppliedFilters([]));
        dispatch(setSearchDisabledFilters([]));
        dispatch(setSearchSuggestedFilters([]));
        dispatch(setTargetAppliedFilters([]));
        dispatch(setTargetSuggestedFilters([]));
        dispatch(setTargetDisabledFilters([]));
      }

      // Search selection if there is one
      if (selected >= 0 && selected < history.length) {
        dispatch(
          setSearchQuery(
            filteredHistory.length
              ? filteredHistory[selected]
              : history[selected]
          )
        );
      }
      logPosthog(PosthogObject.SEARCH, PosthogAction.MANUAL_SEARCH);
      handleSearch({ query });
    };

    const onIconClick = () => {
      if (user?.platform_role === "admin" || user?.platform_role === "labeler")
        dispatch(setSearchDebug(!isDebug));
    };

    const getSearchInputCopy = () => {
      if (repo && (allFailedDocs || allFailedSidebarDocs))
        return "Please upload supported documents";

      if (currentDoc?.failure_reason) return "Document not supported";

      if (searchBarCopy) return searchBarCopy; // for section-only search

      if (!repo && repoTargets[0]) {
        // global search page with repo selections
        if (repoTargets.length === 1) {
          return `Ask ${repos[repoTargets[0]]?.name ?? "project"}`;
        } else if (repoTargets.length === 2 && repoTargets[1]) {
          return `Ask ${repos[repoTargets[0]]?.name} and ${
            repos[repoTargets[1]]?.name
          }`;
        }
        return `Ask ${repos[repoTargets[0]]?.name} and ${
          repoTargets.length - 1
        } other selected ${pluralize(repoTargets.length - 1, "project")}`;
      }

      // If in a repo and there are no searchable docs
      if (repo && allEmptyFolders) return "No searchable documents";

      if (!repo || (repo && !firstBuild)) {
        if (docTargets[0] && docTargets[0] in repoDocsDict) {
          if (docTargets.length === 1) {
            return `Ask ${repoDocsDict[docTargets[0]]?.title}`;
          } else if (
            docTargets.length === 2 &&
            docTargets[1] &&
            docTargets[1] in repoDocsDict
          ) {
            return `Ask ${repoDocsDict[docTargets[0]]?.title} and ${
              repoDocsDict[docTargets[1]]?.title
            }`;
          }
          return `Ask ${repoDocsDict[docTargets[0]]?.title} and ${
            docTargets.length - 1
          } other selected ${pluralize(docTargets.length - 1, "document")}`;
        }
        return `Ask ${currentDoc?.title || repo?.name || "your documents"}`;
      }

      if ((repo?.status_percentage ?? null) === null)
        return "Fetching status..."; // on page reload

      let percentage = Math.round(repo?.status_percentage ?? 0);
      percentage = percentage === 100 ? 99 : percentage; // never show 100

      if (repo?.status_percentage === 0) return "Documents queued...";

      if (numDocsCrawling > 0)
        return `Securely ingested ${numDocsTotal} ${pluralize(
          numDocsTotal,
          "document"
        )} so far...`;

      if (percentage <= 50) {
        return `Adding ${numDocsCrawling + numDocsRemaining} more ${pluralize(
          numDocsCrawling + numDocsRemaining,
          "document"
        )} to AI index...`;
      } else {
        return `Encrypting index...`;
      }
    };

    const showProgress = loading || (isBuilding && firstBuild);

    const buildProgress =
      (repo?.status_percentage ?? 0) <= 1 ? undefined : repo?.status_percentage;

    const progressValue = loading ? searchProgress : buildProgress;

    const shouldShowCommandText =
      !query && !searchBarsFocused.sourcesBar && !searchBarsFocused.searchBar;

    const tooltipOnClick = () => {
      if (router.query.query) {
        // Clear all search state
        clearSearchState();
      } else dispatch(setSearchQuery(""));
    };

    return (
      <SearchInputBox
        handleKeyDown={handleKeyDown}
        searchDisabled={searchDisabled}
        onSearchTagClick={onIconClick}
        showSearchInput={true}
        inputPlaceholder={getSearchInputCopy()}
        inputQuery={query}
        inputValueOnChange={(e) => {
          dispatch(setSearchQuery(e.target.value));
          if (e.target.value) onSearchChange(e.target.value);
        }}
        inputId={MAIN_SEARCH_INPUT_ID}
        onInputFocus={() => {
          dispatch(setSearchBarFocused(true));
          dispatch(setSourcesBarFocused(false));
        }}
        onInputBlur={() => dispatch(setSearchBarFocused(false))}
        shouldShowCommandText={shouldShowCommandText}
        tooltipOnClick={tooltipOnClick}
        showProgress={showProgress}
        progressValue={progressValue}
        progressColor={color}
        numRepoTargets={repoTargets.length}
        shouldStartFocused={true}
        overrideStyles={overrideStyles}
      />
    );
  },
  areEqual
);
