import React from "react";
import { useSelector } from "react-redux";
import { useGetRouter } from "source/hooks/useGetRouter";
import { SearchResultCount } from "./components/SearchResultCount";
import { getRanExactMatchSearch } from "source/redux/search";
import { DownloadExactMatchesCard } from "./components/DownloadExactMatchesCard";
import { useCurrentRepo } from "source/hooks/repos/useCurrentRepo";
import { KeywordToggle } from "./components/KeywordToggle";
import { getTargetRepos } from "source/redux/advanced";
import { SynonymsApplied } from "../SynonymsApplied";
import { MatterIdCard } from "./components/MatterIdCard";
import { getCurrentOrg } from "source/redux/organization";
import { MORGAN_MORGAN_ORG_ID } from "source/constants";
import { RepoType } from "source/Types";

type Props = {
  repo?: RepoType;
};

export const SearchMenu = ({ repo }: Props) => {
  const currentRepo: RepoType | undefined = useCurrentRepo();
  const repoTargets = useSelector(getTargetRepos);
  const { router } = useGetRouter();
  const ranExactMatchSearch = useSelector(getRanExactMatchSearch);
  const isExactMatchEnabled = true;
  const currentOrg = useSelector(getCurrentOrg);

  const targetedSearch = !!currentRepo || repoTargets.length > 0;
  return (
    <div className="flex w-full">
      {router.query.query ? (
        <div className="ml-5 flex w-full items-center pb-5">
          <KeywordToggle />
          {/* {isAdmin && <DemoModeCard />} */}
          {/* {isAdmin && <NoKeywordsCard repo={repo} />} */}
          {/* Only allow this functionality in targeted search for auth'd users */}
          {targetedSearch && ranExactMatchSearch && isExactMatchEnabled && (
            <DownloadExactMatchesCard />
          )}
          {currentOrg && currentOrg.id === MORGAN_MORGAN_ORG_ID ? null : (
            <SearchResultCount repo={repo} />
          )}
          <SynonymsApplied location="search" />
          <MatterIdCard />
        </div>
      ) : (
        <div className="ml-5 flex w-full pb-5">
          {currentOrg && currentOrg.id === MORGAN_MORGAN_ORG_ID ? null : (
            <SearchResultCount repo={repo} />
          )}
        </div>
      )}
    </div>
  );
};
