import React from "react";
import { isMacintosh } from "source/utils/helpers";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import { FontSizeMui } from "source/Types";

type Props = {
  fontSize?: FontSizeMui;
  classOverride?: string;
};

export const CmdCtrlButton = React.memo(
  ({ fontSize = "small", classOverride }: Props) => (
    <>
      {isMacintosh() ? (
        <KeyboardCommandKeyIcon fontSize={fontSize} className={classOverride} />
      ) : (
        <KeyboardControlKeyIcon fontSize={fontSize} className={classOverride} />
      )}
    </>
  )
);
