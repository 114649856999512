import React from "react";
import { Severity } from "../types";
import { classNames } from "core";
import { MaterialSymbolsIcon } from "core";

type Props = {
  title?: string;
  description?: string;
  action?: React.ReactNode;
  severity?: Severity;
};

const SEVERITY_COLOR_MAPPING: Record<
  Severity,
  {
    color: string;
    bgColor: string;
    icon: string;
  }
> = {
  error: {
    color: "text-red-500",
    bgColor: "bg-red-50",
    icon: "report",
  },
  warning: {
    color: "text-amber-500",
    bgColor: "bg-amber-50",
    icon: "warning",
  },
  info: {
    color: "text-indigo-500",
    bgColor: "bg-indigo-50",
    icon: "info",
  },
  success: {
    color: "text-green-500",
    bgColor: "bg-green-50",
    icon: "check_circle",
  },
};

export const Alert = ({
  title,
  description,
  action,
  severity = "info",
}: Props) => {
  const { color, bgColor, icon } = SEVERITY_COLOR_MAPPING[severity];
  return (
    <div
      className={classNames(
        "pointer-events-auto relative flex w-[40vw] max-w-[50vw] cursor-default flex-row items-start gap-3 rounded-xl border border-gray-200 p-4 shadow-md",
        bgColor
      )}
    >
      <div className="h-full items-center">
        <MaterialSymbolsIcon
          size="base"
          className={color}
          icon={icon}
          variant="rounded"
          filled
        />
      </div>
      <div className="flex flex-col gap-2 text-sm leading-none">
        {title && <span className="font-medium text-black">{title}</span>}
        <div className="flex flex-col gap-2 font-normal">
          {description && (
            <span className="font-normal leading-4 text-gray-600">
              {description}
            </span>
          )}
          {action}
        </div>
      </div>
    </div>
  );
};
